import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { LuMapPin } from "react-icons/lu";
import { FaShower, FaBed } from "react-icons/fa";
import PopularProperty from './PopularProperty';
import MessageUs from './MessageUs';
import { Player, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';
import Custom_React_Player from '../video_player/Custom_React_Player';
const FrontProperty = () => {
    const { listingId } = useParams();
    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isVideoExpanded, setIsVideoExpanded] = useState(false);

    useEffect(() => {
        const fetchProperty = async () => {
            try {
                const frontPropertyRef = doc(db, 'listings', listingId);
                const frontPropertySnap = await getDoc(frontPropertyRef);

                if (frontPropertySnap.exists()) {
                    setProperty(frontPropertySnap.data());
                } else {
                    setError('Property not found');
                }
            } catch (err) {
                setError('Error fetching property. Please try again.');
                console.error('Error fetching property:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchProperty();
    }, [listingId]);

    // Navigate to the previous image
    const prevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? property.imageUrls.length - 1 : prevIndex - 1
        );
    };

    // Navigate to the next image
    const nextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === property.imageUrls.length - 1 ? 0 : prevIndex + 1
        );
    };

    // Toggle video expansion
    const toggleVideoSize = () => {
        setIsVideoExpanded(!isVideoExpanded);
    };

    // Show loading spinner while fetching data
    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <p>Loading property details...</p>
            </div>
        );
    }

    // Show error message if an error occurred
    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <p>{error}</p>
            </div>
        );
    }

    const getRemainingImageCount = () => {
        return property.imageUrls.length - 5;
    };

    return (
        <div className="min-h-screen w-full p-6 flex flex-col items-center">
            <div className="mt-2 w-full md:w-[80%]">
                {property ? (
                    <>
                        {property.imageUrls && property.imageUrls.length > 0 && (
                            <div className="relative w-full md:w-full h-auto">
                                {/* Main Image with Left and Right Arrows */}
                                <div className="w-full h-80 mb-4 relative md:h-[60vh]">
                                    <img
                                        src={property.imageUrls[currentImageIndex].downloadURL}
                                        alt={property.title}
                                        className="object-cover w-full h-full rounded-lg"
                                    />

                                    {/* Left Arrow */}
                                    <button
                                        onClick={prevImage}
                                        className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full"
                                    >
                                        ←
                                    </button>

                                    {/* Right Arrow */}
                                    <button
                                        onClick={nextImage}
                                        className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full"
                                    >
                                        →
                                    </button>
                                </div>

                                {/* Remaining Images */}
                                <div className="grid grid-cols-4 gap-4">
                                    {property.imageUrls.slice(1, 5).map((imageUrl, index) => (
                                        <div key={index} className="relative">
                                            <img
                                                src={imageUrl.downloadURL}
                                                alt={`${property.title} ${index + 2}`}
                                                className="object-cover w-full h-32 rounded-lg"
                                            />
                                        </div>
                                    ))}

                                    {/* If more than 5 images, show number of remaining images on the last one */}
                                    {property.imageUrls.length > 5 && (
                                        <div className="relative">
                                            <img
                                                src={property.imageUrls[4].downloadURL}
                                                alt="More images"
                                                className="object-cover w-full h-32 rounded-lg opacity-50"
                                            />
                                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg">
                                                <p className="text-white text-lg font-bold">
                                                    +{getRemainingImageCount()} more
                                                </p>
                                            </div>
                                        </div>
                                    )}

                                    
                                    {/* {property.videoUrl && property.videoUrl.length > 0 && (
                                        <div className="relative w-full mt-4">
                                            <div
                                                className={`${isVideoExpanded ? 'w-full h-[70vh]' : 'w-full h-64'} rounded-lg cursor-pointer`}
                                                onClick={toggleVideoSize}
                                            >
                                                <Player
                                                    fluid={false}
                                                    width="100%"  
                                                    height="100%" 
                                                    playsInline
                                                    style={{ objectFit: 'cover', borderRadius: '0.5rem' }}
                                                >
                                                   
                                                    {property.videoUrl.map((video, index) => (
                                                        <source key={index} src={video.downloadURL} type={`video/${video.type}`} />
                                                    ))}
                                                    <BigPlayButton position="center" />
                                                </Player>
                                            </div>
                                        </div>
                                    )} */}
                                </div>
                                {/* Rendering a property Virtual Tour Video If it is Available */}
                                    {
                                        <div>
                                           <Custom_React_Player />
                                        </div>
                                    }
                            </div>
                        )}
                    </>
                ) : (
                    <p>Property details are not available.</p>
                )}
            </div>

            <div className="md:flex gap-6 my-8 md:mx-12 w-full md:w-[80%]">
                <div className="flex flex-col gap-3 w-full md:w-[60%]">
                    <div className="flex flex-col gap-2">
                        <p className="text-base font-semibold">{property.title}</p>
                        <div className="flex items-center gap-2">
                            <strong>Ksh:</strong>
                            {property.nature === 'rent' ? (
                            property.regularPrice - property.discountedPrice > 0 ? (
                                <p className='text-gray-800 font-semibold text-sm'>
                                    {Intl.NumberFormat('en-US').format(property.regularPrice - property.discountedPrice)} Per Month
                                </p>
                            ) : (
                                <p className='text-gray-800 font-semibold text-sm'>
                                    {Intl.NumberFormat('en-US').format(property.regularPrice)}
                                </p>
                            )
                            ) : (
                                property.discountedPrice > 0 ? (
                                    <p className='text-gray-800 font-semibold text-sm'>
                                        {Intl.NumberFormat('en-US').format(property.regularPrice)}
                                        <span className='text-[#DD0606] ml-1'>
                                            {((property.regularPrice - property.discountedPrice) / property.regularPrice * 100).toFixed(0)}% OFF
                                        </span>
                                    </p>
                                ) : (
                                    <p className='text-gray-800 font-semibold text-sm'>
                                        {Intl.NumberFormat('en-US').format(property.regularPrice)}
                                    </p>
                                )
                            )}
                        </div>
                        
                        <p><strong>Description:</strong> <span className='text-sm font-normal'>
                            {property.description}</span>
                        </p>
                    </div>
                    <div className='flex flex-wrap gap-4 text-base md:text-sm mt-2 border border-red-200 p-3 rounded-md'>
                        <span className='flex items-center gap-1 text-[#DD0606] font-semibold'>
                            For {property.nature && property.nature.charAt(0).toUpperCase() + property.nature.slice(1)}
                        </span>
                        {property.squareFootage > 0 && (
                            <span className='flex items-center gap-1 text-[#DD0606] font-semibold'>
                                SqFt. {property.squareFootage}
                            </span>
                        )}

                        <span className='flex items-center gap-1 text-[#DD0606] font-semibold'>
                            {property.type && property.type.charAt(0).toUpperCase() + property.type.slice(1)}
                        </span>
                        <span className='flex items-center gap-1 text-[#DD0606] font-semibold'>
                            <LuMapPin className='text-[#DD0606]' />
                            {property.address}
                        </span>

                        {property.bedrooms > 0 && (
                            <span className='flex items-center gap-1 text-[#DD0606] font-semibold'>
                                {property.bedrooms}
                                <FaBed className='text-[#DD0606]' />
                            </span>
                        )}

                        {property.bathrooms > 0 && (
                            <span className='flex items-center gap-1 text-[#DD0606] font-semibold'>
                                {property.bathrooms}
                                <FaShower className='text-[#DD0606]' />
                            </span>
                        )}

                    </div>
                <div>
                    <p className='text-base font-semibold'>Other Key Features:</p>
                    <div>
                        {/* Render only if the feature exist exists */}
                        {property.neighborhood && <p>Neighborhood: <span className='italic'>{property.neighborhood}</span></p>}
                        {property.parking === true && <p>Parking: <span className='italic'>Available</span></p>}
                        {property.furnished === true && <p>Furnished: <span className='italic'>Yes</span></p>}
                        {property.petsAllowed === true && <p>Pets Allowed: <span className='italic'>Yes</span></p>}
                        {property.availableFrom && <p>Available From: <span className='italic'>{new Date(property.availableFrom).toLocaleDateString()}</span></p>}
                        {property.propertyAge && <p>Property Age: <span className='italic'>{property.propertyAge} years</span></p>}
                    </div>
                    </div>

                    <button className='border rounded-full p-3 flex absolute top-[-12] right-6 text-xs text-white bg-[#DD0606] md:hidden'>Contact Sales Team</button>
                </div>
                <div className='absolute right-3 w-full md:w-[40%] md:p-8 rounded-md md:flex md:flex-col md:items-center md:justify-center hidden'>
                    <MessageUs />
                </div>
            </div>
            <div className='flex flex-col w-full md:w-[80%] mt-36'>
                <PopularProperty currentPropertyType={property.type} currentPropertyId={listingId} />
            </div>

        </div>
    );
};

export default FrontProperty;
