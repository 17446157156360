import React, { useEffect, useState } from "react";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore"; // Import updateDoc and doc
import { db } from '../../../firebase/firebase';
import { AiOutlineClose, AiOutlineSend } from 'react-icons/ai'; // Importing the icons

const Notification = () => {
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);  // State to hold selected contact
    const [isModalOpen, setIsModalOpen] = useState(false);  // State to track modal visibility
    const [replyMessage, setReplyMessage] = useState('');  // State for reply message

    // Fetch data from contacts collection
    useEffect(() => {
        const fetchContacts = async () => {
            const contactsCollection = collection(db, "contacts");
            const contactsSnapshot = await getDocs(contactsCollection);
            const contactsList = contactsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setContacts(contactsList);
        };

        fetchContacts();
    }, []);

    // Function to handle opening the modal and setting the selected contact
    const handleReplyClick = async (contact) => {
        setSelectedContact(contact);  // Set the clicked contact data
        setIsModalOpen(true);  // Open the modal

        // Mark the contact as read
        await updateDoc(doc(db, "contacts", contact.id), { read: true });
    };

    // Function to handle modal close
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedContact(null);
        setReplyMessage('');
    };

    return (
        <div className=''>
            <div className='w-full bg-white p-4 rounded-md'>
                <p className="text-sm font-semibold mb-2">Customer Requests and Inquiries</p>
                <hr className='mb-2' />
                <p className='text-xs text-gray-400'>Request from contact us page are displayed here</p>
            </div>

            {/* Display fetched contacts */}
            <div className='p-4 rounded-md bg-white mt-4'>
                {contacts.map((contact) => (
                    <div key={contact.id} className={`flex flex-col md:flex-row items-center w-full gap-6 p-4 rounded-md mb-1 space-y-2 ${contact.read ? 'bg-gray-100' : 'bg-white'}`}>
                        <div className='flex flex-col md:w-[20%]'>
                            <p className='text-sm'>{`${contact.firstName} ${contact.secondName}`}</p>
                            <p className='text-xs text-gray-300'>Customer Name: </p>
                        </div>
                        <div className='flex flex-col md:w-[10%] mr-8'>
                            <p className='text-sm'>{contact.email}</p>
                            <p className='text-xs text-gray-300'>Email:</p>
                        </div>
                        <div className='flex flex-col md:w-[10%]'>
                            <p className='text-sm'> {contact.phone || 'N/A'}</p>
                            <p className='text-xs text-gray-300'>Phone: </p>
                        </div>
                        <div className='flex flex-col md:w-[50%]'>
                            <p className='text-sm'> {contact.issue}</p>
                            <p className='text-xs text-gray-300'>Issue:</p>
                        </div>
                        <div className='flex flex-col md:w-[10%]'>
                            <button
                                onClick={() => handleReplyClick(contact)}
                                className='text-xs border p-2 rounded-md text-[#F06C2C] border-[#F06C2C] hover:text-orange-300 hover:border-orange-300'
                            >
                                Reply to the customer
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {/* Modal for reply */}
            {isModalOpen && selectedContact && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-md w-[50%] relative">
                        {/* Close Icon */}
                        <AiOutlineClose
                            className="absolute top-4 right-4 cursor-pointer text-gray-600"
                            size={24}
                            onClick={closeModal}
                        />

                        <h2 className="text-sm mb-4"><span className='font-semibold'>Reply to</span> {`${selectedContact.firstName} ${selectedContact.secondName}`}</h2>
                        <p className='text-sm'><strong>Email:</strong> {selectedContact.email}</p>
                        <p className='text-sm'><strong>Phone:</strong> {selectedContact.phone || 'N/A'}</p>
                        <p className='text-sm'><strong>Issue:</strong> {selectedContact.issue}</p>

                        {/* Reply input */}
                        <textarea
                            className="w-full mt-4 p-2 border rounded-md resize-none focus:border-orange-400"
                            rows="4"
                            value={replyMessage}
                            onChange={(e) => setReplyMessage(e.target.value)}
                            placeholder="Type your reply here..."
                        />

                        {/* Modal actions */}
                        <div className="mt-4 flex justify-end space-x-2">
                            {/* Send Reply Button with Icon */}
                            <button
                                className="px-3 py-2 bg-[#F06C2C] text-sm text-white rounded-md flex items-center"
                                onClick={() => { /* Add send reply logic here */ }}
                            >
                                <AiOutlineSend className="mr-2" /> Send Reply
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Notification;
