import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import { LuMapPin } from "react-icons/lu";
import { FaShower, FaBed } from "react-icons/fa";
import { FaRegMoneyBillAlt } from "react-icons/fa";

const RecentListing = () => {
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);  // Add loading state
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/properties");
    };

    useEffect(() => {
        const fetchListings = async () => {
            try {
                const listingsCollection = collection(db, 'listings');
                const q = query(
                    listingsCollection,
                    orderBy('listedDate', 'desc'),
                    limit(4)
                );
                const listingsSnapshot = await getDocs(q);
                const listingsList = listingsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setListings(listingsList);
                setLoading(false);  // Set loading to false after fetching
            } catch (error) {
                console.error('Error fetching listings: ', error);
                setLoading(false);  // Set loading to false if there's an error
            }
        };

        fetchListings();
    }, []);

    return (
        <div className='flex flex-col h-auto items-center justify-center font-sans'>
            <div className='flex flex-col w-full md:w-[80%] items-center justify-center p-4 md:p-6'>
                <h2 className='text-[18px] md:text-3xl font-semibold mb-2 mt-0 md:mt-8'>Recent Listings</h2>
                <p className='text-center text-[14px] md:text-xl text-gray-500 md:leading-relaxed'>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Numquam obcaecati provident adipisci inventore voluptas dolore
                    eveniet rem ipsa expedita sequi? Saepe autem consequuntur fugiat laborum,
                    doloremque sunt consequatur quidem dolores quo labore, omnis ex debitis.</p>
            </div>

            {/* Wrap the loading spinner inside the main container to center it properly */}
            {loading ? (
                <div className='w-full h-[400px] flex flex-col justify-center items-center'>
                    <div className="spinner-border animate-spin border-4 border-t-4 border-orange-500 rounded-full w-8 h-8"></div>
                    <p className='mt-4 text-base md:text-xl text-gray-700'>Loading recent properties...</p>
                </div>
            ) : (
                <div className='flex gap-4 w-full md:p-8'>
                    <div className='flex w-full md:px-24'>
                        <div className='grid grid-cols-1 sm:grid-cols-2 w-full gap-2 md:gap-6'>
                            {listings.length > 0 ? (
                                listings.map(listing => (
                                    <div key={listing.id} className='flex flex-col bg-gray-50 rounded-lg w-full p-3 md:p-0 hover:cursor-pointer' onClick={() => handleClick()}>
                                        <div>
                                            {listing.imageUrls && listing.imageUrls.length > 0 && (
                                                <img
                                                    src={listing.imageUrls[0].downloadURL}
                                                    alt={listing.title}
                                                    className='w-full h-64 object-cover rounded-t-md'
                                                />
                                            )}
                                        </div>
                                        <div className='flex flex-col w-full justify-between mt-2 md:pr-2 md:ml-2'>
                                            <h3 className='text-[13px] md:text-lg font-semibold mb-2'>{listing.title}</h3>
                                            <p className='text-[12px] md:text-[16px] text-gray-900 line-clamp-3 mb-2'>{listing.description}</p>
                                            <div className='flex w-full gap-4 md:my-2 flex-wrap'>
                                                <span className='flex gap-2 items-center'>
                                                    <FaRegMoneyBillAlt className='text-xl text-[#DD0606]' />
                                                    <p className='text-black text-[12px] md:text-sm font-bold'>
                                                        {listing.nature === 'rent' ? (
                                                            listing.regularPrice - listing.discountedPrice > 0 ? (
                                                                <p className='text-gray-800 font-semibold text-[12px] md:text-sm'>
                                                                    {Intl.NumberFormat('en-US').format(listing.regularPrice - listing.discountedPrice)} Per Month
                                                                </p>
                                                            ) : (
                                                                <p className='text-gray-800 font-semibold text-[12px] md:text-sm'>
                                                                    {Intl.NumberFormat('en-US').format(listing.regularPrice)}
                                                                </p>
                                                            )
                                                        ) : (
                                                            listing.discountedPrice > 0 ? (
                                                                <p className='text-gray-800 font-semibold text-[12px] md:text-sm'>
                                                                    {Intl.NumberFormat('en-US').format(listing.regularPrice)}
                                                                    <span className='text-[#DD0606] ml-1'>
                                                                        {((listing.regularPrice - listing.discountedPrice) / listing.regularPrice * 100).toFixed(0)}% OFF
                                                                    </span>
                                                                </p>
                                                            ) : (
                                                                <p className='text-gray-800 font-semibold text-[12px] md:text-sm'>
                                                                    {Intl.NumberFormat('en-US').format(listing.regularPrice)}
                                                                </p>
                                                            )
                                                        )}
                                                    </p>
                                                </span>
                                                <span className='flex gap-1 items-center'>
                                                    <LuMapPin className='text-[#DD0606] text-[12px] md:text-xl' />
                                                    <p className='text-black text-[12px] md:text-sm font-semibold'>{listing.address}</p>
                                                </span>

                                                <span className='flex gap-1 items-center'>
                                                    <p className='text-black text-[12px] md:text-sm font-semibold'>
                                                        {listing.type && listing.type.charAt(0).toUpperCase() + listing.type.slice(1)}
                                                    </p>
                                                </span>
                                                <span className='flex gap-1 items-center'>
                                                    <p className='text-black text-[12px] md:text-sm font-semibold'>
                                                        For {listing.nature && listing.nature.charAt(0).toUpperCase() + listing.nature.slice(1)}
                                                    </p>
                                                </span>

                                                {/* Only render bedrooms if greater than 0 */}
                                                {listing.bedrooms > 0 && (
                                                    <span className='flex gap-2 items-center'>
                                                        <p className='text-black text-[12px] md:text-md font-semibold'>{listing.bedrooms}</p>
                                                        <FaBed className='text-[12px] md:text-xl text-[#DD0606]' />
                                                    </span>
                                                )}

                                                {/* Only render bathrooms if greater than 0 */}
                                                {listing.bathrooms > 0 && (
                                                    <span className='flex gap-2 items-center'>
                                                        <p className='text-black text-[12px] md:text-md font-semibold'>{listing.bathrooms}</p>
                                                        <FaShower className='text-[12px] md:text-xl text-[#DD0606]' />
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No recent listings available.</p>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className='w-full flex flex-col items-center justify-center my-6'>
                <button className='border border-[#DD0606] py-2 px-3 md:py-3 md:px-5 hover:bg-gray-100 rounded-full w-[80%] md:w-[40%] text-base' onClick={() => handleClick()}>
                    See More Recent Properties Listing
                </button>
            </div>
        </div>
    );
};

export default RecentListing;
