import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom'

const FrontBlogPosts = () => {
    const [posts, setPosts] = useState([]);
    const [visiblePosts, setVisiblePosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            const snapshot = await getDocs(collection(db, 'blogposts'));
            const blogData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                tags: Array.isArray(doc.data().tags) ? doc.data().tags : []
            }));
            setPosts(blogData);
            setVisiblePosts(blogData.slice(0, 3));
        };
        fetchPosts();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setVisiblePosts(prevVisiblePosts => {
                const currentIndex = posts.indexOf(prevVisiblePosts[0]);
                const nextIndex = (currentIndex + 1) % posts.length;
                return posts.slice(nextIndex, nextIndex + 3).concat(posts.slice(0, Math.max(0, nextIndex + 3 - posts.length)));
            });
        }, 6000);

        return () => clearInterval(interval);
    }, [posts]);

    return (
        <div className="bg-orange-300 flex flex-col items-center justify-center w-full p-2 md:p-6">
            <div className='flex flex-col w-full md:w-[80%] items-center justify-center p-4 md:p-6'>
                <h2 className='text-[18px] md:text-3xl font-semibold mb-2 mt-0 md:mt-8'>Meet Our Blogs About Real Estate</h2>
                <p className='text-center text-[16px] md:text-xl text-gray-700 leading-relaxed'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                    Praesentium saepe assumenda cumque voluptatum quia quisquam ab nesciunt nemo
                    </p>
            </div>
            {/* {adding a link for each post to their respective location using id referencing} */}
            <Link to = "/blog-posts/:id">
                <div className="grid grid-cols-1 sd:grid-cols-2 lg:grid-cols-3 w-full items-center gap-4 justify-center overflow-hidden p-4">
                    {visiblePosts.map(post => (
                        <div
                            key={post.id}
                            className="flex-shrink-0 w-full h-80 border rounded-md shadow-lg bg-white transform transition-transform duration-700 ease-in-out"
                            style={{ boxSizing: 'border-box' }}
                        >
                            {post.imageURL && (
                                <div className="w-full h-32 overflow-hidden rounded-t-md">
                                    <img
                                        src={post.imageURL}
                                        alt={`Blog post ${post.title}`}
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                            )}
                            <div className="px-2 my-2 space-y-2">
                                <h2 className="text-[15px] font-semibold mb-1">{post.title}</h2>
                                <p className="text-[12px]">by {post.author}</p>
                                <div
                                    className="text-gray-700 text-sm overflow-hidden"
                                    style={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden'
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: post.content || 'No content available',
                                    }}
                                ></div>
                            </div>

                        </div>
                    ))}
                </div>
            </Link>
        </div>
    );
};

export default FrontBlogPosts;
