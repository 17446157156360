

const Careers = () => {
    return (
            <div className='flex flex-col items-center justify-center mt-16'>
                <div className='flex flex-col items-center justify-center h-auto'>
                        <div className='border border-gray-100 p-6 rounded-md flex flex-col items-center'>
                        <p className='text-lg font-normal p-6'>There are currently no openings. Keep checking this page for future openings</p>
                        <p>You can also email the HR Team - <span className='text-[#F06C2C]'>hr@appaestates.com</span></p>
                        </div>
                </div>
            </div>
    )
}

export default Careers;