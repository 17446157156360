import React, { useState, useEffect, useContext } from 'react';
import { db, storage } from '../../../firebase/firebase';
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AuthContext } from '../../AppContext/AppContext';
import { BiEditAlt } from "react-icons/bi";

const BlogPosts = () => {
    const { user } = useContext(AuthContext);
    const userRole = user?.role;

    const [posts, setPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editContent, setEditContent] = useState('');
    const [editTitle, setEditTitle] = useState('');
    const [editTags, setEditTags] = useState('');
    const [editImage, setEditImage] = useState('');
    const [currentPostId, setCurrentPostId] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchPosts = async () => {
            const snapshot = await getDocs(collection(db, 'blogposts'));
            const blogData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                tags: Array.isArray(doc.data().tags) ? doc.data().tags : []  // Ensure tags is an array
            }));
            setPosts(blogData);
            setFilteredPosts(blogData);
        };
        fetchPosts();
    }, []);

    const handleEditClick = (post) => {
        setCurrentPostId(post.id);
        setEditContent(post.content || '');
        setEditTitle(post.title || '');
        setEditTags(post.tags.join(', ') || '');
        setEditImage(post.image || '');
        setIsEditing(true);
    };

    const handleDelete = async (postId) => {
        if (userRole === 'super admin') {
            await deleteDoc(doc(db, 'blogposts', postId));
            setPosts(posts.filter(post => post.id !== postId));
        } else {
            alert("Only super admin can delete posts.");
        }
    };

    const handleImageUpload = async () => {
        if (imageFile) {
            if (editImage) {
                const oldImageRef = ref(storage, editImage);
                try {
                    await deleteObject(oldImageRef);
                } catch (error) {
                    console.error("Failed to delete existing image:", error);
                }
            }

            const imageRef = ref(storage, `blogImages/${imageFile.name}`);
            await uploadBytes(imageRef, imageFile);
            const url = await getDownloadURL(imageRef);
            setEditImage(url);
        }
    };

    const handleSaveEdit = async () => {
        if (imageFile) {
            await handleImageUpload();
        }

        const postRef = doc(db, 'blogposts', currentPostId);
        await updateDoc(postRef, {
            title: editTitle,
            tags: editTags.split(',').map(tag => tag.trim()),
            image: editImage,
            content: editContent,
            updatedAt: new Date()
        });

        setIsEditing(false);
        setCurrentPostId(null);
        setPosts(posts.map(post => post.id === currentPostId
            ? { ...post, title: editTitle, tags: editTags.split(',').map(tag => tag.trim()), image: editImage, content: editContent }
            : post
        ));
        setImageFile(null);
    };

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        const filtered = posts.filter(post =>
            post.title.toLowerCase().includes(query) ||
            post.author.toLowerCase().includes(query)
        );
        setFilteredPosts(filtered);
    };

    return (
        <div className="bg-white rounded-md p-6">
            <div className="flex justify-between items-center">
                <h1 className="text-xl font-bold">List of Blog Posts</h1>
                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search by title or author"
                    className="p-2 border rounded-full w-[40%]"
                />
            </div>

            <ul className="mt-4 space-y-4">
                {filteredPosts.map(post => (
                    <li key={post.id} className="flex border p-4 rounded-md justify-between ">
                        <div className='flex flex-col'>
                            <h2 className="text-[15px] font-semibold">{post.title}</h2>
                            <span className='flex space-x-2 items-center'>
                                <p className="text-gray-600 text-[12px]">{post.createdAt?.toDate().toLocaleString()}</p>
                                <span className='text-gray-200'>|</span>
                                <p className="text-gray-600 text-[12px]">by {post.author}</p>
                            </span>
                            <div
                                className="mt-2 text-sm"
                                dangerouslySetInnerHTML={{ __html: post.content ? post.content.slice(0, 100) : 'No content available' }}>
                            </div>
                            <div className="mt-2 text-sm">
                                {(Array.isArray(post.tags) ? post.tags : []).map(tag => (
                                    <span key={tag} className="text-sm text-gray-500">{tag}</span>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center space-y-4 mt-4">
                            {post.active ? (
                                <div className='flex items-center gap-2 border border-green-500 px-3 py-1 rounded-full'>
                                    <span
                                        className="rounded-full w-2 h-2 bg-green-500"
                                        style={{
                                            animation: 'spiral-glow 2s infinite ease-out', // Adjust duration as desired
                                            boxShadow: '0 0 8px rgba(34, 197, 94, 0.6)', // Add a glowing effect
                                        }}
                                    ></span>
                                    <span className='text-green-500 text-[12px]'>Live</span>
                                </div>
                            ) : (
                                <div className='flex items-center gap-2 border border-red-500 px-3 py-1 rounded-full'>
                                    <span className='rounded-full w-2 h-2 bg-red-500'></span>
                                    <span className='text-red-500 text-[12px]'>Off-line</span>
                                </div>
                            )}
                            {(userRole === 'super admin' || userRole === 'admin' || userRole === 'content creator') && (
                                <div className='flex space-x-2 hover:cursor-pointer text-sm hover:text-gray-700' onClick={() => handleEditClick(post)}>
                                    <span><BiEditAlt /></span>
                                    <span>Edit Post</span>
                                </div>
                            )}
                            {userRole === 'super admin' && (
                                <button
                                    className="bg-red-500 text-white px-6 py-1 rounded-full text-sm shadow-md"
                                    onClick={() => handleDelete(post.id)}>
                                    Delete
                                </button>
                            )}
                        </div>
                    </li>
                ))}
            </ul>

            {isEditing && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 p-4 h-screen overflow-y-auto">
                    <div className="bg-white p-6 rounded shadow-md w-full max-w-5xl mx-auto overflow-y-auto h-auto max-h-[95vh]">
                        <h2 className="text-2xl font-bold mb-4">Edit Blog Post</h2>
                        <input
                            type="text"
                            value={editTitle}
                            onChange={(e) => setEditTitle(e.target.value)}
                            placeholder="Title"
                            className="mb-4 w-full p-2 border rounded"
                        />
                        <input
                            type="text"
                            value={editTags}
                            onChange={(e) => setEditTags(e.target.value)}
                            placeholder="Tags (comma-separated)"
                            className="mb-4 w-full p-2 border rounded"
                        />
                        <input
                            type="text"
                            value={editImage}
                            onChange={(e) => setEditImage(e.target.value)}
                            placeholder="Image URL"
                            className="mb-4 w-full p-2 border rounded"
                        />
                        <input
                            type="file"
                            onChange={(e) => setImageFile(e.target.files[0])}
                            className="mb-4 w-full p-2 border rounded"
                        />
                        <div className="border rounded mb-4 overflow-hidden">
                            <ReactQuill
                                value={editContent}
                                onChange={setEditContent}
                                className="h-96"
                            />
                        </div>
                        <div className="flex space-x-2 mt-4 justify-end">
                            <button
                                className="bg-orange-500 text-white px-4 py-2 rounded-full shadow-md"
                                onClick={handleSaveEdit}>
                                Save
                            </button>
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded-full shadow-md"
                                onClick={() => setIsEditing(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BlogPosts;
