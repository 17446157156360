import React, { useState } from 'react';
import { db, storage } from '../../../firebase/firebase';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { v4 as uuidv4 } from 'uuid';

function BlogPostEditor() {
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [content, setContent] = useState('');
    const [tags, setTags] = useState('');
    const [image, setImage] = useState(null);
    const [imageURL, setImageURL] = useState('');
    const [loading, setLoading] = useState(false);

    const handleImageUpload = async (file) => {
        console.log("Starting image upload...");
        const storageRef = ref(storage, `blog-images/${uuidv4()}`);
        const snapshot = await uploadBytes(storageRef, file);
        console.log("Image uploaded, generating download URL...");
        return await getDownloadURL(snapshot.ref);
    };


    const handleTagsChange = (e) => {
        setTags(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title || !author || !content) {
            alert('Please fill in all fields');
            return;
        }

        setLoading(true);

        try {
            let uploadedImageURL = imageURL; // Use provided image URL if available
            if (image && !imageURL) {  // Only upload if there's a file but no external URL
                console.log("Uploading image to Firebase Storage...");
                uploadedImageURL = await handleImageUpload(image);
            }


            // Store only metadata and URLs in Firestore
            const newPost = {
                title,
                author,
                content,
                active: false,
                createdAt: Timestamp.now(),
                imageURL: uploadedImageURL || '', // Store empty string if no image
                tags: tags.split(',').map(tag => tag.trim()), // Convert comma-separated tags to array
            };

            const docRef = await addDoc(collection(db, 'blogposts'), newPost);
            console.log("Blog post created with ID: ", docRef.id);

            // Clear the form after submission
            setTitle('');
            setAuthor('');
            setContent('');
            setTags('');
            setImage(null);
            setImageURL('');
        } catch (error) {
            console.error("Error adding document: ", error);
            alert('There was an error while saving the post.');
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md min-h-screen">
            <h2 className="text-xl font-semibold text-center text-gray-700 mb-6">Create New Blog Post</h2>
            <form onSubmit={handleSubmit}>
                {/* Title input */}
                <div className="mb-4">
                    <label htmlFor="title" className="block text-sm font-medium text-gray-600 mb-2">Title</label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Enter the title"
                        required
                        className="w-full p-2 border border-gray-300 text-[14px] rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                    />
                </div>

                {/* Author input */}
                <div className="mb-4">
                    <label htmlFor="author" className="block text-sm font-medium text-gray-600 mb-2">Author</label>
                    <input
                        type="text"
                        id="author"
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                        placeholder="Enter your name"
                        required
                        className="w-full p-2 border border-gray-300 text-[14px] rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                    />
                </div>

                {/* Rich Text Editor for Content */}
                <label htmlFor="content" className="block text-sm font-medium text-gray-600 mb-2">Content</label>
                {/* <ReactQuill
                    value={content}
                    onChange={setContent}
                    placeholder="Write your blog content here"
                    className="h-40 mb-4 border border-gray-300 rounded-md"
                /> */}
                <div className="border rounded mb-4 overflow-hidden">
                    <ReactQuill
                        value={content}
                        onChange={setContent}
                        className="h-56"
                    />
                </div>

                {/* Tags Input */}
                <div className="mb-4">
                    <label htmlFor="tags" className="block text-sm font-medium text-gray-600 mb-2">Tags (comma-separated)</label>
                    <input
                        type="text"
                        id="tags"
                        value={tags}
                        onChange={handleTagsChange}
                        placeholder="Enter tags, separated by commas"
                        className="w-full p-2 border border-gray-300 text-[14px] rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                    />
                </div>

                <div className='flex gap-4'>
                    {/* Image File Upload */}
                    <div className="mb-4">
                        <label htmlFor="image" className="block text-sm font-medium text-gray-600 mb-2">Upload Image (Optional)</label>
                        <input
                            type="file"
                            id="image"
                            accept="image/*"
                            onChange={(e) => setImage(e.target.files[0])}
                            className="w-full p-2 border border-gray-300 text-[14px] rounded-md"
                        />
                    </div>

                    {/* Optional Image URL input */}
                    <div className="mb-4">
                        <label htmlFor="imageURL" className="block text-sm font-medium text-gray-600 mb-2">Image URL (Optional)</label>
                        <input
                            type="url"
                            id="imageURL"
                            value={imageURL}
                            onChange={(e) => setImageURL(e.target.value)}
                            placeholder="Enter image URL if not uploading a file"
                            className="w-full p-2 border border-gray-300 text-[14px] rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                        />
                    </div>
                </div>

                {/* Submit button */}
                <div className="mb-4">
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full py-3 bg-orange-600 text-white text-sm font-semibold rounded-md focus:outline-none hover:bg-orange-700 disabled:bg-gray-400"
                    >
                        {loading ? 'Submitting...' : 'Publish Post'}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default BlogPostEditor;
