import React from 'react';
import ClientNavbar from '../navbar/ClientNavbar';
import ClientFooter from '../pages/client-side/ClientFooter';

function ClientLayout({ children }) {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Fixed navbar at the top */}
      <div className='fixed top-0 left-0 right-0 z-50'>
        <ClientNavbar />
      </div>

      {/* Content area below the navbar with padding to avoid overlap */}
      <div className="flex-grow pt-16"> {/* Adjust padding-top as needed */}
        {children}
      </div>

      {/* Footer at the bottom */}
      <ClientFooter />
    </div>
  );
}

export default ClientLayout;
