import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { secondaryDb } from '../../firebase/firebase';

const VerifyUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const verifyUser = async () => {
      setLoading(true);
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');
  
      if (!token) {
        setMessage('Verification failed: Missing verification token.');
        setLoading(false);
        return;
      }
  
      try {
        // Query Firestore for the user with this verificationToken
        const usersRef = collection(secondaryDb, 'users');
        const q = query(usersRef, where('verificationToken', '==', token));
        const querySnapshot = await getDocs(q);
  
        if (querySnapshot.empty) {
          setMessage('Verification failed: Invalid or expired token.');
          setLoading(false);
          return;
        }
  
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
        const currentTime = Date.now();
  
        // Check if token is expired
        if (userData.tokenExpiry && currentTime > userData.tokenExpiry) {
          setMessage('Verification link has expired. Please request a new verification email.');
          setLoading(false);
          return;
        }
  
        // Update user document to set active and clear verification fields
        await updateDoc(userDoc.ref, {
          active: true,
          verificationToken: null,
          tokenExpiry: null,
        });
  
        setMessage('Your account has been successfully verified!');
  
        // Countdown to redirect
        const interval = setInterval(() => {
          setCountdown((prev) => {
            if (prev === 1) {
              clearInterval(interval);
              navigate('/update-user-information');
            }
            return prev - 1;
          });
        }, 1000);
      } catch (error) {
        console.error('Error verifying user:', error);
        setMessage('Verification failed: Unable to update account status.');
      } finally {
        setLoading(false);
      }
    };
  
    verifyUser();
  }, [location, navigate]);
  

  // useEffect(() => {
  //   const verifyUser = async () => {
  //     setLoading(true);
  //     const searchParams = new URLSearchParams(location.search);
  //     const token = searchParams.get('token');

  //     if (!token) {
  //       setMessage('Verification failed: Missing verification token.');
  //       setLoading(false);
  //       return;
  //     }

  //     try {
  //       // Query for the user document with the specified verificationToken
  //       const usersRef = collection(secondaryDb, 'users');
  //       const q = query(usersRef, where('verificationToken', '==', token));
  //       const querySnapshot = await getDocs(q);

  //       if (querySnapshot.empty) {
  //         setMessage('Verification failed: Invalid or expired token.');
  //         setLoading(false);
  //         return;
  //       }

  //       const userDoc = querySnapshot.docs[0];
  //       const userData = userDoc.data();
  //       const currentTime = Date.now();

  //       // Check if token is expired
  //       if (userData.tokenExpiry && currentTime > userData.tokenExpiry) {
  //         setMessage('Verification link has expired. Please request a new verification email.');
  //         setLoading(false);
  //         return;
  //       }

  //       // Update user document to set active and clear verification fields
  //       await updateDoc(userDoc.ref, {
  //         active: true,
  //         verificationToken: null,
  //         tokenExpiry: null,
  //       });

  //       setMessage('Your account has been successfully verified!');

  //       // Countdown to redirect
  //       const interval = setInterval(() => {
  //         setCountdown((prev) => {
  //           if (prev === 1) {
  //             clearInterval(interval);
  //             navigate('/update-user-information');
  //           }
  //           return prev - 1;
  //         });
  //       }, 1000);
  //     } catch (error) {
  //       console.error('Error verifying user:', error);
  //       setMessage('Verification failed: Unable to update account status.');
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   verifyUser();
  // }, [location, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded shadow-md w-11/12 sm:w-1/2 md:w-1/3 lg:w-1/4 text-center">
        {loading ? (
          <p className="text-gray-700">Verifying your account...</p>
        ) : (
          <>
            <p className={`text-sm ${message.includes('successfully') ? 'text-green-500' : 'text-red-500'}`}>
              {message}
            </p>
            {message.includes('successfully') && (
              <p className="text-gray-500 mt-2">
                Redirecting to update your information in {countdown} seconds...
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyUser;
