import logo from "../../images/brands/logo.png";
import { Link } from "react-router-dom";

const ClientFooter = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="flex flex-col w-full bg-black text-white dark:bg-gray-900 text-center pb-2">
            <div className="flex flex-col md:flex-row items-center justify-center p-8 mx-4 gap-6">
                {/* Logo and Contact Section */}
                <div className="w-full md:w-[30%] flex flex-col items-center justify-center gap-3">
                    <a href="https://appaestates.com/" className="text-white hover:text-gray-400">
                        <img src={logo} alt="Appa-Estate Logo" className="h-8" />
                    </a>
                    <div className="flex md:flex-col gap-4 md:gap-2">
                        <p className="text-xs">Call us now</p>
                        <p className="text-xs">0700 000 000</p>
                    </div>
                </div>

                {/* Links Section */}
                    <div className="flex w-full justify-center md:justify-between text-sm sm:text-base gap-4">
                        <Link to='/about-us' className="text-[#F06C2C] hover:text-white text-xs md:text-base">
                            About us
                        </Link>
                        <span className="hidden md:inline text-gray-200"> | </span>
                        <Link to='/blog-posts' className="text-[#F06C2C] hover:text-white text-xs md:text-base">
                            Our Blog
                        </Link>
                        <span className="hidden md:inline text-gray-200"> | </span>
                        <Link to='/services' className="text-[#F06C2C] hover:text-white text-xs md:text-base">
                            Our Services
                        </Link>
                        <span className="hidden md:inline text-gray-200"> | </span>
                        <Link to='/careers' className="text-[#F06C2C] hover:text-white text-xs md:text-base">
                            Career
                        </Link>
                        <span className="hidden md:inline text-gray-200"> | </span>
                        <Link to='/contact-us' className="text-[#F06C2C] hover:text-white text-xs md:text-base">
                            Contact us
                        </Link>
                    </div>
            </div>

            <hr className="mb-4" />

            {/* Footer Bottom */}
            <div className="flex flex-col md:flex-row w-full items-center justify-center">
                <div className="flex items-center">
                &copy; <span className="text-xs ml-1">{currentYear}</span>
                <p className="text-xs ml-2">Appa-Estate. All Rights Reserved.</p>
                </div>
                <div className='flex gap-2 items-center'>
                    <a href="https://appaestates.com/terms-of-service" className=" text-xs ml-2 text-[#F06C2C] hover:text-white">Terms of Service</a>
                    <span className="hidden md:inline"> | </span>
                    <a href="https://appaestates.com/privacy-policy" className="text-xs ml-2 text-[#F06C2C] hover:text-white">Privacy Policy</a>

                </div>
            </div>
        </footer>
    );
};

export default ClientFooter;
