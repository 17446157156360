import React, { useContext } from 'react';
import { AuthContext } from './components/AppContext/AppContext';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import AppContext from './components/AppContext/AppContext';
import AdminLayout from './components/layouts/AdminLayout';
import ClientLayout from './components/layouts/ClientLayout';
import AuthLayout from './components/layouts/AuthLayout';
import Login from './components/pages/admin-side/Login';
import AdminSignup from './components/pages/admin-side/AdminSignup';
import Dashboard from './components/pages/admin-side/Dashboard';
import BlogPosts from './components/pages/admin-side/BlogPosts';
import Home from './components/pages/client-side/Home';
import ManagerUsers from './components/pages/admin-side/ManagerUsers';
import Properties from './components/pages/admin-side/Properties';
import Message from './components/pages/admin-side/Message';
import PropertySection from './components/pages/client-side/PropertySection';
import Service from './components/pages/client-side/Service';
import AboutUs from './components/pages/client-side/AboutUs';
import Careers from './components/pages/client-side/Careers';
import ContactUs from './components/pages/client-side/ContactUs';
import BlogPostEditor from './components/pages/admin-side/BlogPostEditor';
import Blog from './components/pages/client-side/Blog';
import Property from './components/modals/Property';
import FrontProperty from './components/modals/FrontProperty';
import AnauthorizedPage from './components/modals/AnauthorizedPage';
import Notification from './components/pages/admin-side/Notification';
import StaffRequests from './components/pages/admin-side/StaffRequests';
import RolesManagement from './components/pages/admin-side/RolesManagement';
import RequireRole from './components/modals/RequireRole';
import VerifyUser from './components/modals/VerifyUser';
import UpdateUserData from './components/modals/UpdateUserData';
import ProtectedRoute from './components/modals/ProtectedRoute';

// function AppLayout() {
//   const location = useLocation();
//   const { user } = useContext(AuthContext);
//   console.log("Current path:", location.pathname);

//   const isDashboardPage = [
//     '/dashboard', '/listing', "/unauthorized", '/notifications',
//     '/roles', '/messages', '/manage-users', '/edit-blog-post', '/update-user-information', '/blog-post',
//     '/employee-service-request'
//   ].includes(location.pathname)
//     || location.pathname.startsWith('/listings/')
//     || location.pathname.startsWith('/messages/')
//     || location.pathname.startsWith('/listing/');

//   const isClientPage = [
//     '/', '/properties', '/listing', '/services', '/contact-us',
//     '/properties/:listingId/:slug', '/careers', '/properties/all',
//     '/properties/sale', '/properties/rent', '/properties/commercial',
//     '/blog-posts', '/about-us'
//   ].includes(location.pathname)
//     || location.pathname.startsWith('/services/')
//     || location.pathname.startsWith('/properties/')
//     || location.pathname.startsWith('/listing/');

//   return (
//     <div>
//       {isDashboardPage ? (
//         <ProtectedRoute>
//           <AdminLayout>
//             <Routes>
//               <Route path="/dashboard" element={<Dashboard />} />
//               <Route path="/listing" element={<Properties />} />
//               <Route path="/listing/:tab" element={<Properties />} />
//               <Route path="/unauthorized" element={<AnauthorizedPage />} />
//               <Route path="/messages" element={<Message />} />
//               <Route path="/messages/:tab?" element={<Message />} />
//               <Route path='/update-user-information' element={<UpdateUserData />} />
//               <Route path='/blog-post' element={<BlogPosts />} />
//               <Route path="/manage-users" element={
//                 <RequireRole allowedRoles={['super admin', 'admin']}>
//                   <ManagerUsers />
//                 </RequireRole>
//               } />
//               <Route path='/edit-blog-post' element={
//                 <RequireRole allowedRoles={['super admin', 'admin', 'content creator']}>
//                   <BlogPostEditor />
//                 </RequireRole>} />
//               <Route path='/listing/:tab/:id/:title' element={<Property />} />
//               <Route path='/notifications' element={<Notification />} />
//               <Route path='/employee-service-request' element={<StaffRequests />} />
//               <Route path='/roles' element={
//                 <RequireRole allowedRoles={['super admin']}>
//                   <RolesManagement />
//                 </RequireRole>
//               } />
//             </Routes>
//           </AdminLayout>
//         </ProtectedRoute>
//       ) : isClientPage ? (
//         <ClientLayout>
//           <Routes>
//             <Route path="/" element={<Home />} />
//             <Route path="/properties" element={<PropertySection />} />
//             <Route path="/properties/:tabName" element={<PropertySection />} />
//             <Route path='/blog-posts' element={<Blog />} />
//             <Route path='/properties/:listingId/:slug' element={<FrontProperty />} />
//             <Route path='/services' element={<Service />} />
//             <Route path='/services/:tabName' element={<Service />} />
//             <Route path='/contact-us' element={<ContactUs />} />
//             <Route path='/about-us' element={<AboutUs />} />
//             <Route path='/careers' element={<Careers />} />
//           </Routes>
//         </ClientLayout>
//       ) : (
//         <Routes>
//           <Route path="/admin" element={<Login />} />
//           <Route path="/admin-register" element={<AdminSignup />} />
//           <Route path="/verify-email" element={<VerifyUser />} />
//         </Routes>
//       )}
//     </div>
//   );
// }

function AppLayout() {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  console.log("Current path:", location.pathname);

  const isDashboardPage = [
    '/dashboard', '/listing', "/unauthorized", '/notifications',
    '/roles', '/messages', '/manage-users', '/edit-blog-post', '/update-user-information', '/blog-post',
    '/employee-service-request'
  ].includes(location.pathname)
    || location.pathname.startsWith('/listings/')
    || location.pathname.startsWith('/messages/')
    || location.pathname.startsWith('/listing/');

  const isClientPage = [
    '/', '/properties', '/listing', '/services', '/contact-us',
    '/properties/:listingId/:slug', '/careers', '/properties/all',
    '/properties/sale', '/properties/rent', '/properties/commercial',
    '/blog-posts', '/about-us'
  ].includes(location.pathname)
    || location.pathname.startsWith('/services/')
    || location.pathname.startsWith('/properties/')
    || location.pathname.startsWith('/listing/');

  const isAuthPage = [
    '/admin', '/admin-register', '/verify-email'
  ].includes(location.pathname);

  return (
    <div>
      {isDashboardPage ? (
        <ProtectedRoute>
          <AdminLayout>
            <Routes>
              {/* Dashboard and Admin routes */}
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/listing" element={<Properties />} />
              <Route path="/listing/:tab" element={<Properties />} />
              <Route path="/unauthorized" element={<AnauthorizedPage />} />
              <Route path="/messages" element={<Message />} />
              <Route path="/messages/:tab?" element={<Message />} />
              <Route path='/update-user-information' element={<UpdateUserData />} />
              <Route path='/blog-post' element={<BlogPosts />} />
              <Route path="/manage-users" element={
                <RequireRole allowedRoles={['super admin', 'admin']}>
                  <ManagerUsers />
                </RequireRole>
              } />
              <Route path='/edit-blog-post' element={
                <RequireRole allowedRoles={['super admin', 'admin', 'content creator']}>
                  <BlogPostEditor />
                </RequireRole>} />
              <Route path='/listing/:tab/:id/:title' element={<Property />} />
              <Route path='/notifications' element={<Notification />} />
              <Route path='/employee-service-request' element={<StaffRequests />} />
              <Route path='/roles' element={
                <RequireRole allowedRoles={['super admin']}>
                  <RolesManagement />
                </RequireRole>
              } />
            </Routes>
          </AdminLayout>
        </ProtectedRoute>
      ) : isClientPage ? (
        <ClientLayout>
          <Routes>
            {/* Client and public routes */}
            <Route path="/" element={<Home />} />
            <Route path="/properties" element={<PropertySection />} />
            <Route path="/properties/:tabName" element={<PropertySection />} />
            <Route path='/blog-posts' element={<Blog />} />
            <Route path='/properties/:listingId/:slug' element={<FrontProperty />} />
            <Route path='/services' element={<Service />} />
            <Route path='/services/:tabName' element={<Service />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/careers' element={<Careers />} />
          </Routes>
        </ClientLayout>
      ) : isAuthPage ? (
        <AuthLayout>
          <Routes>
            {/* Auth-specific routes */}
            <Route path="/admin" element={<Login />} />
            <Route path="/admin-register" element={<AdminSignup />} />
            <Route path="/verify-email" element={<VerifyUser />} />
          </Routes>
        </AuthLayout>
      ) : (
        <Routes>
          {/* Fallback for any other unmatched routes */}
          <Route path="*" element={<Home />} />
        </Routes>
      )}
    </div>
  );
}


function App() {
  return (
    <Router>
      <AppContext>
        <AppLayout />
      </AppContext>
    </Router>
  );
}

export default App;
