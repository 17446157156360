import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../../firebase/firebase';
import { collection, getDocs, orderBy, doc, updateDoc, deleteDoc, query } from 'firebase/firestore';
import { MdCheckCircle, MdDelete } from 'react-icons/md';

const Message = () => {
  const navigate = useNavigate();
  const { tab } = useParams(); // Get the tab from URL parameters
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(tab || 'all_messages'); // Default to 'all_messages' if tab is not defined

  // Refs to handle active tab styles
  const tabRefs = useRef({});
  const tabContainerRef = useRef(null);
  const [lineStyle, setLineStyle] = useState({});

  useEffect(() => {
    // Fetch messages from Firebase
    const fetchMessages = async () => {
      try {
        const messagesRef = collection(db, 'messages');
        const q = query(messagesRef, orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        const messagesList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMessages(messagesList);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchMessages();
  }, []);

  useEffect(() => {
    // Update URL when active tab changes
    if (tab !== activeTab) {
      navigate(`/messages/${activeTab}`);
    }
  }, [activeTab, tab, navigate]);

  useEffect(() => {
    // Calculate and set the line's position based on the active tab button
    if (tabRefs.current[activeTab] && tabContainerRef.current) {
      const activeTabRect = tabRefs.current[activeTab].getBoundingClientRect();
      const containerRect = tabContainerRef.current.getBoundingClientRect();

      setLineStyle({
        left: activeTabRect.left - containerRect.left,
        width: activeTabRect.width,
      });
    }
  }, [activeTab, tabRefs]);

  // Message filtering logic based on the active tab
  const filteredMessages = messages.filter(msg => {
    switch (activeTab) {
      case 'read_messages':
        return msg.isRead;
      case 'new_messages':
        return !msg.isRead;
      case 'all_messages':
      default:
        return true;
    }
  });

  // Handle marking a message as read
  const markAsRead = async (id) => {
    try {
      const messageRef = doc(db, 'messages', id);
      await updateDoc(messageRef, { isRead: true });
      setMessages(prevMessages =>
        prevMessages.map(msg => msg.id === id ? { ...msg, isRead: true } : msg)
      );
    } catch (err) {
      console.error('Error updating document: ', err);
    }
  };

  // Handle deleting a message
  const deleteMessage = async (id) => {
    try {
      const messageRef = doc(db, 'messages', id);
      await deleteDoc(messageRef);
      setMessages(prevMessages => prevMessages.filter(msg => msg.id !== id));
    } catch (err) {
      console.error('Error deleting document: ', err);
    }
  };

  if (loading) return <div className='flex flex-col items-center justify-center h-[80%]'>
    <div className='bg-white p-6 rounded-md'>
      Loading...
    </div>
  </div>;
  if (error) return <div>Error: {error.message}</div>;

  // Count of messages based on their read status
  const allMessagesCount = messages.length;
  const newMessagesCount = messages.filter(msg => !msg.isRead).length;
  const readMessagesCount = messages.filter(msg => msg.isRead).length;

  return (
    <div className='container mx-auto'>
      <div className='flex flex-col w-full bg-white p-4 gap-3'>
        <p>Messages</p>
        <hr />
        <p className='text-xs'>Customer inquiries/requests are displayed here</p>
      </div>

      <div className="tabs flex flex-col mt-3 bg-white p-4 rounded-md">
        {/* Tabs Navigation */}
        <div className='relative w-full'>
          <div
            ref={tabContainerRef} // Reference for the tab container
            className='flex justify-start space-x-4 overflow-x-auto pb-2 border-b border-gray-300'
          >
            {[
              { key: 'all_messages', label: `All Messages (${allMessagesCount})` },
              { key: 'read_messages', label: `Read Messages (${readMessagesCount})` },
              { key: 'new_messages', label: 'New Messages' }
            ].map(({ key, label }) => (
              <div>
                <button
                  key={key}
                  ref={(el) => (tabRefs.current[key] = el)} // Assign each tab button's ref
                  onClick={() => setActiveTab(key)}
                  className={`px-4 py-2 transition-colors duration-200 ease-in-out text-[14px] ${activeTab === key ? 'text-orange-500 font-semibold' : 'text-gray-600'
                    }`}
                >
                  {label}
                  {key === 'new_messages' && newMessagesCount > 0 && (
                    <span className="absolute top-1 right-[56%] transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                      {newMessagesCount}
                    </span>
                  )}
                </button>
              </div>
            ))}
          </div>

          {/* Horizontal Line Below Active Tab */}
          <div
            className='absolute h-[2px] bg-orange-500 transition-all duration-300 ease-in-out'
            style={{
              ...lineStyle,
              bottom: '-2px',
              position: 'absolute',
            }}
          ></div>
        </div>

        {/* Messages Content */}
        <div className='mt-4 ml-4'>
          {filteredMessages.length === 0 ? (
            <p>No messages found.</p>
          ) : (
            <ul className='space-y-2 p-3'>
              {filteredMessages.map((msg, index) => (
                <div key={index} className='flex flex-col md:flex-row justify-between border border-gray-50 shadow-sm p-3 rounded-md'>
                  <div className='flex flex-col w-full md:w-[20%]'>
                    <p className='text-sm'>{msg.name}</p>
                    <p className='text-xs text-gray-400'>{msg.phone}</p>
                    <p className='text-xs text-gray-400'>{msg.email}</p>
                    <p className='text-sm mt-2'>Property ID:</p>
                    <p className='text-xs text-gray-400'>{msg.listingId}</p>
                  </div>
                  <div>
                    <p className='text-sm'>{msg.contactMode}</p>
                    <p className='text-xs text-gray-400'>Mode of Contact:</p>
                  </div>
                  <div className='flex flex-col w-full md:w-[50%] gap-2 mt-4 md:mt-0'>
                    <p className='text-sm bg-red-50 p-2 rounded-md font-normal'>{msg.message}</p>
                    <p className='text-xs text-gray-400'>Message</p>
                  </div>
                  <div className='flex flex-col items-center md:items-end'>
                    <button onClick={() => markAsRead(msg.id)} className={`bg-green-600 p-2 rounded-md ${msg.isRead ? 'opacity-50 cursor-not-allowed' : ''}`}>
                      <MdCheckCircle className='text-white' />
                    </button>
                    <button onClick={() => deleteMessage(msg.id)} className='mt-2 bg-red-600 p-2 rounded-md'>
                      <MdDelete className='text-white' />
                    </button>
                  </div>
                </div>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;