import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PropertyCard from '../../modals/PropertyCard';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import bata from "../../../components/images/brands/partnerbrands/bata-logo-vector.png";
import simbisa from "../../../components/images/brands/partnerbrands/Simbisa-Brands-logo.jpg"
import optica from "../../../components/images/brands/partnerbrands/Optica-logo.png";
import familybank from "../../../components/images/brands/partnerbrands/family-bank-logo.png";
// import transchem from "../../../components/images/brands/partnerbrands/transchem-pharmaceuticals-logo.png"
import pdm from "../../../components/images/brands/partnerbrands/pdm-blue-logo.png";
import goodlife from "../../../components/images/brands/partnerbrands/goodlife.jpg";
import bestlady from "../../../components/images/brands/partnerbrands/Bestlady-logo.jpg";
import firstassurance from "../../../components/images/brands/partnerbrands/first-assurance-logo.png"


const Service = () => {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [randomListings, setRandomListings] = useState([]);
  const [noProperties, setNoProperties] = useState(false);

  const navigate = useNavigate();
  const { tabName } = useParams();

  const tabs = {
    'property-management': 'Property Management',
    consultancy: 'Consultancy',
    'market-research-survey': 'Market Research & Survey'
  };

  const [activeTab, setActiveTab] = useState(tabName || 'property-management');
  const [lineStyle, setLineStyle] = useState({});
  const tabRefs = useRef({});
  const tabContainerRef = useRef(null);

  // Fetch listings from Firebase when component mounts
  useEffect(() => {
    const fetchListings = async () => {
      try {
        setLoading(true);
        const querySnapshot = await getDocs(collection(db, 'listings'));
        const listingsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setListings(listingsArray);
        setNoProperties(listingsArray.length === 0);
      } catch (error) {
        console.error('Error fetching listings:', error);
        setNoProperties(true);
      } finally {
        setLoading(false);
      }
    };

    fetchListings();
  }, []);

  // Update URL when active tab changes
  useEffect(() => {
    if (tabName !== activeTab) {
      navigate(`/services/${activeTab}`);
    }
  }, [activeTab, tabName, navigate]);

  // Calculate line style for the active tab
  useEffect(() => {
    if (tabRefs.current[activeTab] && tabContainerRef.current) {
      const activeTabRect = tabRefs.current[activeTab].getBoundingClientRect();
      const containerRect = tabContainerRef.current.getBoundingClientRect();
      setLineStyle({
        left: activeTabRect.left - containerRect.left,
        width: activeTabRect.width,
      });
    }
  }, [activeTab, tabRefs]);

  // Delay to randomize the managed properties
  useEffect(() => {
    if (!loading && listings.length > 0) {
      const timer = setTimeout(() => {
        setRandomListings(getRandomListings(listings));
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [listings, loading]);

  // Function to get random listings that are managed properties
  function getRandomListings(listings) {
    const filteredListings = listings.filter(listing => listing.managedProperty === true);

    // Randomize the filtered listings
    for (let i = filteredListings.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [filteredListings[i], filteredListings[j]] = [filteredListings[j], filteredListings[i]];
    }

    return filteredListings.slice(0, 6);
  }

  return (
    <div className='mt-8 flex flex-col items-center justify-center'>
      {/* Tab Navigation */}
      <div className='relative w-full max-w-3xl'>
        <div ref={tabContainerRef} className='flex justify-center space-x-4 sm:space-x-8 pb-2 md:pb-4 border-b border-gray-300'>
          {Object.entries(tabs).map(([key, value]) => (
            <button
              key={key}
              ref={(el) => (tabRefs.current[key] = el)}
              onClick={() => setActiveTab(key)}
              className={`px-4 py-2 transition-colors duration-200 text-sm md:text-base ease-in-out ${activeTab === key ? 'text-orange-500 font-semibold' : 'text-gray-600'
                }`}
            >
              {value}
            </button>
          ))}
        </div>

        {/* Horizontal Line Below Active Tab */}
        <div className='absolute h-[2px] bg-orange-500 transition-all duration-300 ease-in-out'
          style={{
            ...lineStyle,
            bottom: '-2px',
            position: 'absolute',
          }}
        ></div>
      </div>

      {/* Tab Content */}
      <div className='relative w-full sm:w-[85%] mt-8 px-4'>
        {activeTab === 'property-management' && (
          <div className='p-4 bg-white'>
            {/* <h2 className='text-xl font-bold pb-12'>Property Management</h2> */}

            <div className="flex flex-col gap-4 flex-1 sm:flex-row w-full max-auto">
              <div className="flex flex-col w-full md:w-[50%]">
                <img
                  src='https://media.istockphoto.com/id/1629900540/photo/rows-of-new-houses-in-ocala-florida.webp?a=1&b=1&s=612x612&w=0&k=20&c=8SQgSa4Vng2Dd81JaOWRkXUEZk64i3Jr1WZsRQVsj2A='
                  alt=''
                  className='rounded-md'
                />
                {/* <h2 className='text-gray-600 font-semibold italic text-sm sm:text-lg w-36'>Agency Team</h2> */}
              </div>
              <div className="flex flex-col w-full md:w-[50%] items-center justify-center">
                <p className='text-sm sm:text-base text-gray-500'>
                  At Appa Estates we deal with selling and letting of residential, industrial, commercial and agricultural properties.
                  It also deals with selling and letting of specialized properties such as petrol stations, Hotels, Hospitals etc.
                </p>
              </div>
            </div>

            {/* Show "Currently Managed Client Properties" immediately */}
            <div className="pt-6">
              <h2 className='text-md font-semibold text-gray-600'>Currently Managed Client Properties</h2>

              {/* Show loading spinner or effect after the heading */}
              {loading && <div className='text-center py-2'>Loading Properties...</div>}

              {/* No properties available */}
              {!loading && noProperties && (
                <div className='text-center py-2 text-gray-600'>
                  Managed Properties List Will be Updated Soon.
                </div>
              )}

              {/* Randomized Listings */}
              {!loading && randomListings.length > 0 && (
                <div className="pt-2">
                  {randomListings.map(listing => (
                    <Link to="" key={listing.id} className='p-4'>
                      <PropertyCard listing={listing} />
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {activeTab === 'consultancy' && (
          <div className='p-6'>
            <h2 className='text-xl font-bold pb-4'>Consultancy Services</h2>
            <div className="flex flex-col gap-4 flex-1 sm:flex-row w-full max-auto p-3">
              <div className="">
                <h2 className='text-gray-600 font-semibold text-sm md:text-base w-48'>Valuation Team</h2>
              </div>
              <div className="">
                <p className='text-sm md:text-base text-gray-500'>
                  This Department handles valuation of all types of fixed and movable properties including land buildings, plants, machinery, furniture, office equipment and stock.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 flex-1 sm:flex-row w-full max-auto p-3">
              <div className="">
                <h2 className='text-gray-600 font-semibold text-sm md:text-base w-48'>Real Estate Consultancy Team</h2>
              </div>
              <div className="">
                <p className='text-sm md:text-base text-gray-500'>
                  We also provide consultancy services in real estate. This includes feasibility/viability studies and development.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 flex-1 sm:flex-row w-full max-auto p-3">
              <div className="">
                <h2 className='text-gray-600 font-semibold text-sm md:text-base w-48'>Our Clients</h2>
              </div>
              <div className="">
                <p className='text-sm md:text-base text-gray-500'>
                  The Company currently services a wide range of clientele from a wide pool of industries including insurance, Capital Markets, Mutual Funds & Asset Management, Manufacturing and Retail. Private Sectors and Individual businesses.
                </p>
              </div>
            </div>
            <div className="">
              <h2 className='text-gray-600 text-md font-semibold pt-4 pb-3 text-center sm:text-left'>Our Notable Clients</h2>
              <div className="flex flex-wrap items-center justify-center sm:justify-start gap-4 sm:gap-6">
                <img src={bata} alt="bata-brand-logo" className='h-[40px] sm:h-[50px] w-auto sm:w-[100px]' />
                <img src={simbisa} alt="simbisa-brand-logo" className='h-[40px] sm:h-[50px] w-auto sm:w-[100px]' />
                <img src={familybank} alt="family-bank-logo" className='h-[40px] sm:h-[50px] w-auto sm:w-[100px]' />
                <img src={optica} alt="optica-logo" className='h-[40px] sm:h-[50px] w-auto sm:w-[100px]' />
                <img src={firstassurance} alt="first-assurance-logo" className='h-[40px] sm:h-[50px] w-auto sm:w-[100px]' />
                <img src={bestlady} alt="bestlady-logo" className='h-[40px] sm:h-[50px] w-auto sm:w-[100px]' />
                <img src={pdm} alt="pdm-logo" className='h-[40px] sm:h-[50px] w-auto sm:w-[100px]' />
                {/* <img src={transchem} alt="transchem-logo" className='h-[40px] sm:h-[50px] w-auto sm:w-[100px]' /> */}
                <img src={goodlife} alt="goodlife-logo" className='h-[40px] sm:h-[56px] w-auto sm:w-[100px]' />
              </div>
            </div>
          </div>
        )}

        {activeTab === 'market-research-survey' && (
          <div className='p-4 '>
            <h2 className='text-base font-bold'>Market Research & Survey</h2>
            <p className='mt-2 pb-4 text-sm md:text-base'>
              At Appa Estates, we understand that navigating the real estate market can be a complex process for clients. Whether you're looking to buy, sell, or invest in property, making informed decisions is essential.
              That's where market research and survey services come into play, offering a critical foundation for understanding the ever-changing dynamics of the real estate landscape.
            </p>
            <div className="flex flex-col gap-4 flex-1 sm:flex-row w-full max-auto p-3">
              <div className="">
                <h2 className='text-gray-600 font-semibold text-sm md:text-base w-48'>Why Market Research Matters in Real Estate</h2>
              </div>
              <div className="text-md text-gray-500 ">
                <p className='text-sm md:text-base text-gray-500'>
                  Market research is the key to unlocking valuable insights about the housing market, buyer behavior, and investment opportunities. It provides data-driven evidence that helps our clients make confident decisions.
                  At Appa Estates, we go beyond surface-level trends to dig deep into the factors that influence real estate value, including:
                </p>
                <ul className='py-3 space-y-4 contain-style'>
                  <li>
                    <strong>Demographic Shifts - </strong>Understanding the needs of different population segments, from young professionals to retirees, can influence which properties will be in demand.
                  </li>
                  <li>
                    <strong>Economic Trends - </strong>Tracking changes in interest rates, employment levels, and the overall economy allows us to predict shifts in property values and investment opportunities.
                  </li>
                  <li>
                    <strong>Buyer Preferences - </strong>Through targeted surveys, we gather insights into what buyers are looking for in terms of property size, amenities, location, and price points. This helps sellers position their properties effectively and buyers find the best match for their needs.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col gap-4 flex-1 sm:flex-row w-full max-auto p-3">
              <div className="">
                <h2 className='text-gray-600 font-semibold text-sm md:text-base w-48'>Survey Insights: Giving You the Buyer’s Perspective
                </h2>
              </div>
              <div className="text-md text-gray-500 ">
                <p className='text-sm md:text-base text-gray-500'>
                  Surveys offer a unique opportunity to hear directly from potential buyers, investors, or tenants.
                  At Appa Estates, we design customized surveys to gather precise feedback on areas such as:
                </p>
                <ul className='py-3 space-y-4 contain-style'>
                  <li>
                    <strong>Property Preferences - </strong>What features or amenities matter most to buyers today?
                    We tailor our surveys to uncover what buyers are really searching for—whether it's proximity to schools, energy-efficient homes, or open floor plans.
                  </li>
                  <li>
                    <strong>Investment Goals - </strong>For investors, knowing what yields the best returns is critical.
                    Surveys can help us pinpoint the types of properties that are most attractive to investors and provide insight into how market conditions impact their purchasing decisions.
                  </li>
                  <li>
                    <strong>Neighborhood Appeal - </strong>Location is one of the biggest factors in real estate.
                    Our surveys help determine which neighborhoods are on the rise and what makes certain areas more desirable than others.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col gap-4 flex-1 sm:flex-row w-full max-auto p-3">
              <div className="">
                <h2 className='text-gray-600 font-semibold text-sm md:text-base w-48'>How We Tailor Our Approach to Client Needs
                </h2>
              </div>
              <div className="text-md text-gray-500 ">
                <p className='text-sm md:text-base text-gray-500'>
                  We recognize that every client has unique goals, and our market research and survey services are designed to cater to these individual needs.
                  Whether you’re a first-time homebuyer, a seasoned investor, or a developer, our comprehensive research helps you:
                </p>
                <ul className='py-3 space-y-4 contain-style'>
                  <li>
                    <strong>Identify Opportunities - </strong>We keep a close eye on emerging real estate trends and market conditions,
                    helping you spot investment opportunities before they hit the mainstream.
                  </li>
                  <li>
                    <strong>Minimize Risks - </strong>Real estate markets can be volatile.
                    By understanding market dynamics through reliable data, you can avoid common pitfalls and make sound decisions with lower risk.
                  </li>
                  <li>
                    <strong>Maximize Value - </strong>For sellers, having a clear understanding of what buyers are looking for allows you to position your property more effectively,
                    ensuring you get the best possible return on your investment.
                  </li>
                </ul>
              </div>
            </div>
            <div className="pt-2">
              <h2 className='text-md font-semibold text-center text-sm md:text-base text-gray-500 p-4'>Partner with Appa Estates for Your Market Research Needs</h2>
              <p className='text-sm md:text-base text-gray-500 py-1'>
                At the heart of every successful real estate decision is reliable information.
                Our market research and survey services at Appa Estates are designed to equip you with the knowledge you need to navigate the real estate market confidently.
                Whether you're buying, selling, or investing, let us be your partner in making informed, data-driven decisions that lead to success.
                <p className='text-sm md:text-base text-gray-500 py-1'>
                  Reach out to us today to learn how our market research and survey services can help you achieve your real estate goals.
                  Together, we’ll turn data into opportunities.</p>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Service;
