import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import PropertyList from './PropertyList';

const StatusTabs = () => {
  const navigate = useNavigate();
  const { tabName } = useParams(); // Retrieve the current tab from the URL
  const [listings, setListings] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [selectedTab, setSelectedTab] = useState(tabName || 'all'); // Set initial tab based on URL or default to 'all'
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const ITEMS_PER_PAGE = 7;

  // Fetch listings from Firestore
  const fetchListings = async () => {
    const db = getFirestore();
    const listingsCollection = collection(db, 'listings');
    try {
      const querySnapshot = await getDocs(listingsCollection);
      const fetchedListings = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setListings(fetchedListings);
      setFilteredProperties(fetchedListings);
    } catch (error) {
      console.error('Error fetching listings:', error);
    }
  };

  useEffect(() => {
    fetchListings();
  }, []);

  // Update filter whenever the tab name in the URL changes
  useEffect(() => {
    handleTabChange(tabName || 'all');
  }, [tabName, listings]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setCurrentPage(1); // Reset to first page on tab change

    // Update URL and filter properties based on the selected tab
    switch (tab) {
      case 'for-sale':
        setFilteredProperties(listings.filter(listing => listing.nature === 'sale'));
        navigate('/properties/for-sale');
        break;
      case 'for-rent':
        setFilteredProperties(listings.filter(listing => listing.nature === 'rent'));
        navigate('/properties/for-rent');
        break;
      case 'for-commercial':
        setFilteredProperties(listings.filter(listing => listing.type === 'commercial'));
        navigate('/properties/for-commercial');
        break;
      case 'all-properties':
      default:
        setFilteredProperties(listings);
        navigate('/properties/all-properties');
        break;
    }
  };

  // Handle Search Input
  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term) {
      const filtered = listings.filter((listing) =>
        listing.title.toLowerCase().includes(term) || listing.description.toLowerCase().includes(term)
      );
      setFilteredProperties(filtered);
    } else {
      setFilteredProperties(listings); // If search is cleared, reset to all listings
    }

    // Generate search suggestions based on matching titles
    const searchSuggestions = listings
      .filter((listing) => listing.title.toLowerCase().includes(term))
      .map((listing) => listing.title);
    setSuggestions(searchSuggestions);
  };

  // Handle Pagination
  const indexOfLastListing = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstListing = indexOfLastListing - ITEMS_PER_PAGE;
  const currentListings = filteredProperties.slice(indexOfFirstListing, indexOfLastListing);
  const totalPages = Math.ceil(filteredProperties.length / ITEMS_PER_PAGE);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  // Count of properties in different categories
  const allPropertiesCount = listings.length;
  const forSaleCount = listings.filter(listing => listing.nature === 'sale').length;
  const forRentCount = listings.filter(listing => listing.nature === 'rent').length;
  const commercialCount = listings.filter(listing => listing.type === 'commercial').length;

  return (
    <div className="mt-8 w-full flex flex-col items-center justify-center">
      {/* Tabs */}
      <div className="flex w-full items-center overflow-x-auto gap-2 md:gap-4 md:justify-left flex-wrap">
        <button
          className={`px-1 md:px-2 py-1 rounded-full ${selectedTab === 'all-properties' ? 'bg-orange-500 text-white' : 'border border-orange-500 text-orange-500'}`}
          onClick={() => handleTabChange('all-properties')}
        >
          <span className='text-sm'>All Properties</span> <span className='text-sm'>({allPropertiesCount})</span>
        </button>
        <button
          className={`px-1 md:px-2 py-1 rounded-full ${selectedTab === 'for-sale' ? 'bg-orange-500 text-white' : 'border border-orange-500 text-orange-500'}`}
          onClick={() => handleTabChange('for-sale')}
        >
          <span className='text-sm'>For Sale</span> <span className='text-sm'>({forSaleCount})</span>
        </button>
        <button
          className={`px-1 md:px-2 py-1 rounded-full ${selectedTab === 'for-rent' ? 'bg-orange-500 text-white' : 'border border-orange-500 text-orange-500'}`}
          onClick={() => handleTabChange('for-rent')}
        >
          <span className='text-sm'>For Rent</span> <span className='text-sm'>({forRentCount})</span>
        </button>
        <button
          className={`px-2 py-1 rounded-full ${selectedTab === 'for-commercial' ? 'bg-orange-500 text-white' : 'border border-orange-500 text-orange-500'}`}
          onClick={() => handleTabChange('for-commercial')}
        >
          <span className='text-sm'>Commercial</span> <span className='text-sm'>({commercialCount})</span>
        </button>
        {/* Search Input */}
        <div className="flex justify-center w-full md:w-[40%] mt-4 md:mt-0">
          <input
            type="text"
            className="px-3 py-2 border border-orange-500 rounded-full w-full"
            placeholder="Search properties..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {suggestions.length > 0 && (
            <ul className="absolute mt-2 w-full bg-white shadow-lg rounded-lg max-h-48 overflow-y-auto">
              {suggestions.map((suggestion, index) => (
                <li key={index} className="px-4 py-2 hover:bg-gray-200 cursor-pointer">{suggestion}</li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Display Filtered Properties */}
      <PropertyList listings={currentListings} />

      {/* Pagination Controls */}
      <div className="flex justify-center items-center mt-4">
        <button
          className={`px-4 py-2 text-sm border rounded-l-lg ${currentPage === 1 ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'}`}
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="px-4 py-2 text-sm border-t border-b">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className={`px-4 py-2 text-sm border rounded-r-lg ${currentPage === totalPages ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'}`}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default StatusTabs;
