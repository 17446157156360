// RolesManagement.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, collection, getDoc, getDocs, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { auth } from '../../../firebase/firebase';
import { AuthContext } from '../../AppContext/AppContext';
import RoleModal from '../../modals/RoleModal';
import { FaFileMedical } from "react-icons/fa";
// import { CiEdit, CiTrash } from "react-icons/ci";

const db = getFirestore();

const RolesManagement = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [roles, setRoles] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newRoleName, setNewRoleName] = useState('');
  const [newPermissions, setNewPermissions] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editingRole, setEditingRole] = useState(null);

  useEffect(() => {
    if (user?.role !== 'super admin') {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  useEffect(() => {
    const fetchRoles = async () => {
      const rolesSnapshot = await getDocs(collection(db, 'roles'));
      const rolesData = rolesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setRoles(rolesData);
    };

    fetchRoles();
  }, []);

  const handleCreateRole = async (e) => {
    e.preventDefault();
    if (!newRoleName || !newPermissions) {
      setErrorMessage('Role name and permissions are required.');
      return;
    }

    try {
      await addDoc(collection(db, 'roles'), {
        role_name: newRoleName,
        permissions: newPermissions.split(',').map((perm) => perm.trim()),
      });

      setNewRoleName('');
      setNewPermissions('');
      setErrorMessage('');
      setIsModalVisible(false);
    } catch (error) {
      setErrorMessage('Error creating role.');
    }
  };

  const handleSaveEdit = async (e) => {
    e.preventDefault();
    if (!newRoleName || !newPermissions) {
      setErrorMessage('Role name and permissions are required.');
      return;
    }
  
    try {
      await updateDoc(doc(db, 'roles', editingRole.id), {
        role_name: newRoleName,
        permissions: newPermissions.split(',').map((perm) => perm.trim()),
      });
  
      setEditingRole(null);
      setNewRoleName('');
      setNewPermissions('');
      setErrorMessage('');
      setIsModalVisible(false);
    } catch (error) {
      setErrorMessage('Error updating role.');
    }
  };


  return (
    <div className="p-4 bg-white rounded-md">
      <h1 className="text-lg font-semibold mb-4">Roles Management</h1>
      {user?.role === 'super admin' && (
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => setIsModalVisible(true)}
        >
          <FaFileMedical className="text-orange-500" size={20} />
          <span className="text-xs text-orange-500">Create New Role</span>
        </div>
      )}
      <RoleModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onSubmit={editingRole ? handleSaveEdit : handleCreateRole}
        roleName={newRoleName}
        setRoleName={setNewRoleName}
        permissions={newPermissions}
        setPermissions={setNewPermissions}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default RolesManagement;
