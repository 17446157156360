import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle'; // Import Swiper styles
import macharia from "../../../components/images/brands/MachariaCEO.jpeg";
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { db, storage } from '../../../firebase/firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import SwiperCore from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// Initialize Swiper with required modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

const AboutUs = () => {
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [currentListingIndex, setCurrentListingIndex] = useState(0);
    const [localListings, setLocalListings] = useState([]); // Temporary state for smooth transition

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const imagesRef = collection(db, 'listings');
                const q = query(imagesRef, orderBy('imageUrls', 'desc'), limit(10)); // Fetch 10 listings
                const imagesSnapshot = await getDocs(q);

                const images = await Promise.all(
                    imagesSnapshot.docs.map(async (doc) => {
                        const imagePath = doc.data().imageUrls;

                        if (Array.isArray(imagePath)) {
                            const urls = await Promise.all(
                                imagePath.map(async (item) => {
                                    if (typeof item === 'string') {
                                        const imageRef = ref(storage, item);
                                        return await getDownloadURL(imageRef);
                                    } else if (typeof item === 'object' && item.downloadURL) {
                                        return item.downloadURL;
                                    } else {
                                        throw new Error(`Invalid image path format`);
                                    }
                                })
                            );
                            return { id: doc.id, imageUrls: urls };
                        } else if (typeof imagePath === 'string') {
                            const imageRef = ref(storage, imagePath);
                            const url = await getDownloadURL(imageRef);
                            return { id: doc.id, imageUrls: [url] };
                        } else {
                            throw new Error(`Invalid image path format`);
                        }
                    })
                );

                // Set the images to the main state and to the local state simultaneously for smooth transition
                setListings(images);
                setLocalListings(images);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching images:', error);
                setLoading(false);
                setError(true);
            }
        };

        fetchImages();
    }, []);

    // Function to select a random listing index
    const getRandomListingIndex = () => {
        return Math.floor(Math.random() * listings.length);
    };

    // Event handler for when the Swiper reaches the last slide
    const handleSlideChange = (swiper) => {
        if (swiper.activeIndex === swiper.slides.length - 1) {
            // Generate a random new listing index
            let nextIndex;
            do {
                nextIndex = getRandomListingIndex();
            } while (nextIndex === currentListingIndex); // Ensure a different listing

            setCurrentListingIndex(nextIndex);
            setLocalListings([listings[nextIndex]]); // Temporarily update local state to avoid flicker
            swiper.slideTo(0);
        }
    };

    return (
        <div className='flex flex-col w-full md:items-center md:justify-center pt-6'>
            {/* Intro section */}
            <div className='flex flex-col gap-2 md:flex-row w-full md:w-[80%] md:my-12'>
                <div className='flex flex-1 flex-col'>
                    <p className='text-center md:text-left md:leading-10'>
                        <span className='text-2xl font-bold md:text-4xl'>
                            We build Bridges between
                        </span> <br />
                        <span className='text-lg text-gray-500 md:text-2xl p-8'>
                            Affordable Properties and Clients.
                        </span>
                    </p>
                </div>
                <div className='flex flex-1 flex-col md:justify-end'>
                    <p className='text-center text-base md:text-left md:text-xl md:leading-8'>
                        We're making it possible and easy for people to access, invest, and acquire properties at affordable rates in the market.
                    </p>
                </div>
            </div>

            {/* Error message */}
            {error && (<p className="text-center my-7 text-red-700 text-2xl">Error fetching listing</p>)}

            {/* Swiper Carousel */}
            <div className='w-full flex flex-col md:flex-row mt-8'>
                {!loading && localListings.length > 0 ? (
                    <Swiper
                        navigation={{ hideOnClick: true }} // Hide arrows by default
                        pagination={{ clickable: true }}
                        spaceBetween={0}
                        slidesPerView={1}
                        autoplay={{ delay: 8000, disableOnInteraction: false }}
                        onSlideChange={handleSlideChange}
                        className='w-full'
                        key={currentListingIndex}
                    >
                        {localListings[0].imageUrls.map((url, index) => (
                            <SwiperSlide key={`${localListings[0].id}-${index}`} className='w-full'>
                                <Link to={`/property-item/${localListings[0].id}`} className='block w-full'>
                                    <img
                                        src={url}
                                        alt={`Property ${localListings[0].id}`}
                                        className='object-cover w-full h-[300px] md:h-[500px]' // Consistent image height
                                    />
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <p>No properties found.</p>
                )}
            </div>
            
            {/* Rest of the content remains unchanged */}
            <div className='my-12 w-full md:w-[80%] px-2'>
                <hr className='mb-6 md:mb-12' />
                <div className='flex flex-col md:flex-row gap-3 md:gap-8 justify-center'>
                    <div className='w-full md:w-[30%]'>
                        <h1 className='text-2xl font-semibold'>Founded</h1>
                    </div>
                    <div className='w-full md:w-[70%]'>
                        <div className='flex flex-col'>
                            <p className='pb-3'>Since Sep 2008, Appa Estates has been asssisting individuals and companies across Kenya in:</p>
                            <ul className='list-disc list-inside pl-4'>
                                <li>Management of properties such as commercial, industrial, residential buildings, etc.</li>
                                <li>The valuation of land, buildings, furniture, plant and machinery.</li>
                                <li>Sourcing, selling and letting of properties like buildings and land.</li>
                            </ul>
                        </div>
                        <div className='flex flex-col mt-2 md:mt-8 gap-4'>
                            <p><strong>Vision:</strong> To be the regional leader in Property Consulting, Real Estate Agency, and Property Valuing.</p>
                            <p className='pb-3'><strong>Mission:</strong> To exceed the standards of quality service while respecting clients' preferences and budgets.</p>
                        </div>
                    </div>
                </div>
                <hr className='mb-6 md:mb-12' />
                <div className='flex flex-col md:flex-row gap-3 md:gap-8 justify-center'>
                    <div className='w-full md:w-[30%]'>
                        <h1 className='text-2xl font-semibold'>Together we Excel</h1>
                    </div>
                    <div className='w-full md:w-[70%]'>
                        <div className='text-xl font-semibold'>Our Client List is always growing, with 15% referrals and more than 80% CSAT satisfied customers.</div>
                        <div className='flex flex-col mt-2 md:mt-8 gap-4'>
                            <p>At APPA Estates, our client list reflects our commitment to excellence. With 15% of our business from referrals, it's clear our satisfied customers trust us to deliver exceptional service and value.</p>
                            <p>Our impressive 80% Customer Satisfaction (CSAT) rating reflects our dedication to exceeding expectations. We offer competitive deals that combine quality and affordability, making us the trusted choice in real estate.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='md:my-12 w-full md:w-[80%]'>
                <div className='flex flex-col md:flex-row gap-8 justify-center'>
                    <div className='w-full md:w-[30%] flex flex-col items-center justify-center'>
                        <img
                            src={macharia}
                            alt=''
                            className='w-44 h-44 rounded-full'
                        />
                        <div className='mt-4'>
                            <p className='font-semibold text-base'>Dr. Macharia</p>
                            <p className='text-sm'>Founder & CEO</p>
                        </div>
                    </div>
                    <div className='w-full md:w-[70%]'>
                        <div className='text-3xl flex flex-col items-center justify-center space-y-4 px-4 lg:px-8'>
                            <p className='italic font-light text-center text-gray-700 text-lg'>
                                "At <span className='not-italic font-semibold text-gray-900'>APPA Estates</span>, we redefine real estate by offering unmatched convenience, trust, and results. Whether you're buying or selling, our platform connects you with top-tier properties, backed by expert guidance and a seamless user experience."
                            </p>
                            <p className='font-medium text-center text-gray-900 text-lg'>Trust us to make your real estate dreams a reality.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='my-4 md:my-12 w-full md:w-[80%] px-2'>
                <hr className='mb-6 md:mb-12' />
                <div className='flex flex-col md:flex-row gap-8 justify-center'>
                    <div className='w-full md:w-[30%]'>
                        <h1 className='text-2xl font-semibold'>Join our Team</h1>
                    </div>
                    <div className='w-full md:w-[70%]'>
                        <div className='flex flex-col gap-4'>
                            <p>We believe it takes great people to create a great brand. That's why we hire not only the perfect professionals, but people who embody our company values.</p>
                            <a href="/careers" className="text-[#F06C2C] hover:text-blue-800 underline">See open positions</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col md:my-8 w-full md:w-[80%] bg-[#F06C2C] md:p-6 md:rounded-r-md'>
                <div className='flex flex-col w-full md:w-[70%] md:flex-row items-center'>
                    <div className='flex flex-col md:ml-12 w-full'>
                        <img
                            src='https://media.istockphoto.com/id/1837425194/photo/call-center-workers.webp?a=1&b=1&s=612x612&w=0&k=20&c=SflMLRSj3idqEf5M1k8tqvDgsoN6VmjowT-_21JbKjk='
                            alt='contact-team'
                            className='block md:hidden object-fit w-full mb-6 rounded-b-md'
                        />
                        <p className='block md:hidden text-center text-2xl md:text-4xl text-white'>
                            <span>Have a Question?</span> <br />
                            Our Team is happy to assist you
                        </p>
                        <p className='hidden md:block'>
                            <span className='text-2xl md:text-4xl text-white'>Have a Question?</span> <br />
                            <span className='text-2xl md:text-4xl text-white'>Our Team is happy</span> <br />
                            <span className='text-2xl md:text-4xl text-white'>to assist you</span>
                        </p>
                        <p className='block md:hidden my-4 text-center text-white px-4'>
                            Ask about any property for leasing and purchase, be it land, a complete apartment or any related Real estate, planning, building, property acquisition. Our highly trained reps are standing by, ready to assist.
                        </p>
                        <p className='hidden md:block my-4 leading-8 text-white'>
                            <span>Ask about any property for leasing and purchase, be it land,</span><br />
                            <span>a complete apartment or any related Real estate, planning,</span> <br />
                            <span>building, property acquisition. Our highly trained reps are</span> <br />
                            <span>standing by, ready to assist.</span>
                        </p>
                        <hr className='my-2 md:my-4' />

                        <div className='flex flex-col md:flex-row my-8 items-center gap-8'>
                            <Link to='/contact-us' className='border rounded-md p-3 text-white'>
                                Contact us
                            </Link>

                            <p className='text-white'>
                                Or Call us <span className='underline'>+254 700 000 000</span>
                            </p>
                        </div>
                    </div>

                    <img
                        src='https://media.istockphoto.com/id/1837425194/photo/call-center-workers.webp?a=1&b=1&s=612x612&w=0&k=20&c=SflMLRSj3idqEf5M1k8tqvDgsoN6VmjowT-_21JbKjk='
                        alt='contact-team'
                        className='hidden md:block absolute object-fit h-96 right-0 rounded-md'
                    />
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
