import { useState } from 'react';
import { db, storage } from '../../../firebase/firebase';
import { addDoc, collection } from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from 'firebase/storage';
import Button from '../../button/Button';

export const CreatingListing = ({ onCancel }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [files, setFiles] = useState([]);
    const [videoFile, setVideoFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [videoUploading, setVideoUploading] = useState(false);
    const [imageUploadError, setImageUploadErrors] = useState(false);
    const [videoUploadError, setVideoUploadError] = useState(false);
    const [listingData, setListingData] = useState({
        title: '',
        description: '',
        address: '',
        regularPrice: 0,
        discountedPrice: 0,
        bathrooms: 0,
        bedrooms: 0,
        furnished: false,
        parking: false,
        managedProperty:false,
        type: 'residential',
        nature: 'rent',
        offer: false,
        imageUrls: [],
        videoUrl: [],
        availableFrom: new Date().toISOString().split('T')[0],
        petsAllowed: false,
        squareFootage: 0,
        neighborhood: '',
        propertyAge: 0,
        status: 'Active',
        listedDate: new Date().toISOString().split('T')[0],
    });

    const handleChange = (e) => {
        if(e.target.id === 'sale' || e.target.id === 'rent' || e.target.id === 'lease'){
            setListingData({
                ...listingData,
                nature: e.target.id,
            })
        }
        if(e.target.id === 'land' || e.target.id === 'commercial' || e.target.id === 'residential') {
            setListingData({
               ...listingData,
                type: e.target.id,
            })
        } 
        if(e.target.id === 'parking' || e.target.id === 'furnished' || e.target.id === 'offer' || e.target.id === 'petsAllowed' || e.target.id === 'managedProperty') {
            setListingData({
                ...listingData,
                [e.target.id]: e.target.checked,
            })
        }
        if(e.target.type === 'number' || e.target.type ==='text' || e.target.type ==='textarea' || e.target.id === 'date') {
            setListingData({
               ...listingData,
                [e.target.id]: e.target.value,
            })
        }
        if(e.target.type === 'file') {
            setFiles([...files, e.target.files[0]]);
        }
        if(e.target.id === 'status'){
            setListingData({
               ...listingData,
                status: e.target.value,
            });
        }
        if(e.target.type === 'file' && e.target.accepted === 'video/*'){
            setVideoFile(e.target.files[0]);
        }
    };

    console.log(listingData);

    //image storage for images being uploaded
    const storeImages = (file) => {
        return new Promise((resolve, reject) => {
            // Check if file has a name
            if (!file.name) {
                reject(new Error('File name is undefined'));
                return;
            }
            
            // Create a unique file path with the file name
            const storageRef = ref(storage, `listings/${new Date().getTime()}_${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log(`Upload is ${progress}% done`);
                },
                (error) => {
                    console.error('Firebase Storage Error:', error);
                    reject(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // Log and resolve both the downloadURL and the correct fullPath
                        console.log('File uploaded:', storageRef.fullPath);
                        resolve({ downloadURL, fullPath: storageRef.fullPath }); // Return the correct file path
                    });
                }
            );
        });
    };

    //handle image selection
    const handleImageChange = async () => {
        const imageFiles = Array.from(files);

        if (imageFiles.length > 0 && imageFiles.length + listingData.imageUrls.length <= 10) {
            setUploading(true);
            setImageUploadErrors(false);

            try {
                // Upload images and store URLs and paths
                const uploadPromises = imageFiles.map(file => storeImages(file));
                const uploadedImages = await Promise.all(uploadPromises);

                setListingData({
                    ...listingData,
                    imageUrls: [...listingData.imageUrls, ...uploadedImages], // Add URLs and full paths
                });
                setFiles([]); // Clear selected files after upload
                setUploading(false);
            } catch (error) {
                console.error('Upload error:', error);
                setImageUploadErrors('An error occurred while uploading the images.');
                setUploading(false);
            }
        } else {
            setImageUploadErrors('Maximum 10 images can be uploaded.');
            setUploading(false);
        }
    };

    //handle Delete Upload Images
    const handleDeleteImage = async (index) => {
        try {
            // Get the image fullPath from the imageUrls array
            const imageToDelete = listingData.imageUrls[index];
            console.log('Deleting image:', imageToDelete.fullPath); // Log fullPath

            // Create a reference to the image using the full path
            const imageRef = ref(storage, imageToDelete.fullPath);

            // Delete the image from Firebase Storage
            await deleteObject(imageRef);

            // Remove the image from the UI state
            setListingData({
                ...listingData,
                imageUrls: listingData.imageUrls.filter((_, i) => i !== index),
            });

            console.log(`Image ${imageToDelete.fullPath} deleted successfully.`);
        } catch (error) {
            console.error("Error deleting image:", error);
            setImageUploadErrors("An error occurred while deleting the image.");
        }
    };

    //handle video storage
   const storeVideo = (file) => {
        return new Promise((resolve, reject) => {
            if (!file || !file.name) {
                reject(new Error('File is not selected or file name is undefined'));
                return;
            }

            // Log file type and size
            console.log('File type:', file.type);
            console.log('File size:', file.size);

            const storageRef = ref(storage, `listings/videos/${new Date().getTime()}_${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log(`Video upload is ${progress}% done`);
                },
                (error) => {
                    console.error('Firebase Storage Error:', error);
                    reject(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('Video uploaded successfully. File path:', storageRef.fullPath);
                        resolve({ downloadURL, fullPath: storageRef.fullPath });
                    });
                }
            );
        });
    };

    //handle video upload
    console.log("Selected video upload:", videoFile);
    const handleVideoChange = async () => {
        if (videoFile) {
            setVideoUploading(true);
            setVideoUploadError(false);

            try {
                const uploadedVideo = await storeVideo(videoFile);

                setListingData((prevState) => ({
                    ...prevState,
                    videoUrl: [uploadedVideo],
                }));
                setVideoFile(null);
                setVideoUploading(false);
            } catch (error) {
                console.error('Video upload error:', error);
                setVideoUploadError('An error occurred while uploading the video.');
                setVideoUploading(false);
            }
        }
        if (!videoFile) {
            console.error('No video file selected.');
            return;
        }
    };

    //handle video deletion
    const handleDeleteVideo = async () => {
        try {
            const videoToDelete = listingData.videoUrl[0];

            console.log('Deleting video:', videoToDelete.fullPath);

            const videoRef = ref(storage, videoToDelete.fullPath);
            await deleteObject(videoRef);

            setListingData((prevState) => ({
                ...prevState,
                videoUrl: [],
            }));

            console.log(`Video ${videoToDelete.fullPath} deleted successfully.`);
        } catch (error) {
            console.error("Error deleting video:", error);
            setVideoUploadError("An error occurred while deleting the video.");
        }
    };

    //handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (listingData.imageUrls.length < 1) {
                setError('You must provide at least one image');
                return;
            }

            if (+listingData.regularPrice < +listingData.discountedPrice && listingData.discountedPrice) {
                setError('Discounted Price must be lower than regular price');
                return;
            }

            console.log('listingData:', listingData); // Add this line to check the values of the fields

            setLoading(true);
            setError(false);

            await addDoc(collection(db, 'listings'), {
                ...listingData,
                offer: !!listingData.offer,
                videoUrl: listingData.videoUrl,
            });

            alert('Listing created successfully!');

             // Reset form state and clear image files
            setListingData({
                title: '',
                description: '',
                address: '',
                bedrooms: 0,
                bathrooms: 0,
                regularPrice: 0,
                discountedPrice: 0,
                parking: false,
                furnished: false,
                offer: false,
                type: 'residential',
                nature: 'rent',
                squareFootage: '',
                propertyAge: '',
                neighborhood: '',
                imageUrls: [],
                videoUrl: [],
                managedProperty: false,
            });
            setFiles([]);
            setLoading(false);
            setCurrentStep(1);
        } catch (err) {
            console.error('Error adding document:', err.message);
        }
    };

    const nextStep = (e) => {
        e.preventDefault();
        if (currentStep < 3) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = (e) => {
        e.preventDefault();
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const progress = (currentStep / 3) * 100;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center
         bg-black bg-opacity-50 overflow-auto pt-16 pb-10">
            <main className="relative w-full max-w-screen-md p-4 bg-white rounded-lg shadow-lg z-50 overflow-y-auto max-h-full flex flex-col justify-between">
                <h2 className="text-md font-semibold mb-4 text-center sm:text-lg">Create Property Listing</h2>
                <form onSubmit={handleSubmit} className="flex flex-col gap-6">
                    {/* Progress Bar */}
                    <div className="text-center text-xs font-normal text-gray-700 sm:text-sm">Step {currentStep} of 3</div>
                    <div className="relative">
                        <div className="w-full bg-gray-200 rounded-full h-2">
                            <div className="bg-[#F06C2C] h-2 rounded-full" style={{ width: `${progress}%` }}></div>
                        </div>
                    </div>

                    {/* Step 1: Basic Details */}
                    {currentStep === 1 && (
                        <div className="flex flex-col gap-4">
                            <label className="text-sm sm:text-base">Property Title</label>
                            <input
                                type="text"
                                name="title"
                                value={listingData.title}
                                onChange={handleChange}
                                placeholder="Title"
                                required
                                id='title'
                                maxLength= "100" minLength="20"
                                className="border p-3 text-xs rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 sm:text-sm"
                            />
                            <label className="text-sm sm:text-base">Property Description</label>
                            <textarea
                                name="description"
                                value={listingData.description}
                                onChange={handleChange}
                                id='description'
                                placeholder="Description"
                                required
                                className="border text-xs resize-none p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 sm:text-sm"
                            />
                            <label className="text-sm sm:text-base">Property Address</label>
                            <input
                                type="text"
                                name="address"
                                value={listingData.address}
                                onChange={handleChange}
                                placeholder="Address"
                                required
                                id='address'
                                className="border p-3 text-xs sm:text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                            />
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                            <div className="flex flex-col gap-2">
                                    <label className="text-xs sm:text-sm">Bedroom</label>
                                    <input
                                        type="number"
                                        id='bedrooms'
                                        min="0"
                                        max="50"
                                        value={listingData.bedrooms}
                                        onChange={handleChange}
                                        className="p-3 rounded-lg border text-xs sm:text-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
                                    />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="text-xs sm:text-sm">Bathroom</label>
                                    <input
                                        type="number"
                                        id='bathrooms'
                                        min="0"
                                        max="50"
                                        value={listingData.bathrooms}
                                        onChange={handleChange}
                                        className="p-3 rounded-lg border text-xs sm:text-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
                                    />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="text-xs sm:text-sm">Regular Price</label>
                                    <input
                                        type="number"
                                        name="regularPrice"
                                        id="regularPrice"
                                        min="0"
                                        max="10000000000"
                                        value={listingData.regularPrice}
                                        onChange={handleChange}
                                        className="p-3 rounded-lg border text-xs sm:text-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Step 2: Advanced Details */}
                    {currentStep === 2 && (
                        <div className="flex flex-col gap-6">
                            <div className="flex flex-wrap gap-4">
                                <div className="flex items-center gap-2">
                                    <input
                                        type="checkbox"
                                        name="parking"
                                        id='parking'
                                        checked={listingData.parking}
                                        onChange={handleChange}
                                        className="w-5 h-5"
                                    />
                                    <span className="text-xs sm:text-sm">Parking Spot</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <input
                                        type="checkbox"
                                        name="furnished"
                                        id='furnished'
                                        checked={listingData.furnished}
                                        onChange={handleChange}
                                        className="w-5 h-5"
                                    />
                                    <span className="text-xs sm:text-sm">Furnished</span>
                                </div>
                              <div className="flex items-center gap-2">
                                    <input
                                        type="checkbox"
                                        name="petsAllowed"
                                        id="petsAllowed"
                                        checked={listingData.petsAllowed}
                                        onChange={handleChange}
                                        className="w-5 h-5"
                                    />
                                    <span className="text-sm">Pets Allowed</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <input
                                        type="checkbox"
                                        id='managedProperty'
                                        checked={listingData.managedProperty}
                                        onChange={handleChange}
                                        className="w-5 h-5"
                                    />
                                    <span className="text-xs sm:text-sm">Managed Property</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <input
                                        type="checkbox"
                                        name="offer"
                                        id='offer'
                                        checked={listingData.offer}
                                        onChange={handleChange}
                                        className="w-5 h-5"
                                    />
                                    <span className="text-xs sm:text-sm">Special Offer</span>
                                </div>
                            </div>
                             {listingData.offer && (
                                    <div className="flex flex-col gap-2">
                                        <label className="text-xs sm:text-sm">Discounted Price (KES / Month)</label>
                                        <input
                                            type="number"
                                            name="regularPrice"
                                            id='discountedPrice'
                                            min="0"
                                            max="90000000000"
                                            value={listingData.discountedPrice}
                                            onChange={handleChange}
                                            className="p-3 rounded-lg w-1/3 border text-xs sm:text-sm focus: outline-none focus:ring-2 focus:ring-orange-500"
                                        />
                                    </div>
                            )}
                            <label className="block text-gray-700 text-sm font-semibold">Property Type</label>
                            <div className="flex flex-wrap gap-4">
                                <div className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name="type"
                                        id="land"
                                        value="land"
                                        onChange={handleChange}
                                        checked={listingData.land}
                                        className="w-4 h-4"
                                    />
                                    <span className="text-xs sm:text-sm">Land</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name="type"
                                        id="commercial"
                                        value="commercial"
                                        checked={listingData.commercial}
                                        onChange={handleChange}
                                        className="w-4 h-4"
                                    />
                                    <span className="text-xs sm:text-sm">Commercial/Business Building</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name="type"
                                        value="residential"
                                        id='residential'
                                        checked={listingData.type === 'residential'}
                                        onChange={handleChange}
                                        className="w-4 h-4"
                                    />
                                    <span className="text-xs sm:text-sm">Residential Building</span>
                                </div>
                            </div>

                            <label className="block text-gray-700 text-sm font-semibold">Property Nature</label>
                            <div className="flex flex-wrap gap-4">
                                <div className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name="nature"
                                        id="sale"
                                        value="sale"
                                        checked={listingData.sale}
                                        onChange={handleChange}
                                        className="w-5 h-5"
                                    />
                                    <span className="text-sm">Sale</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name="nature"
                                        id="rent"
                                        value="rent"
                                        checked={listingData.nature === 'rent'}
                                        onChange={handleChange}
                                        className="w-5 h-5"
                                    />
                                    <span className="text-sm">Rent</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name="nature"
                                        id="lease"
                                        value="lease"
                                        onChange={handleChange}
                                        checked={listingData.lease}
                                        className="w-5 h-5"
                                    />
                                    <span className="text-sm">Lease</span>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4">
                                    <label className="text-xs sm:text-sm">Available From</label>
                                    <input
                                        type="date"
                                        id="availableFrom"
                                        value={listingData.availableFrom}
                                        onChange={(e) => setListingData((prevState) => ({
                                            ...prevState,
                                            availableFrom: e.target.value
                                        }))}
                                        className="p-3 rounded-lg border text-xs sm:text-sm focus:outline-none focus:ring-2 focus:ring-orange-500 w-1/3"
                                    />
                            </div>

                            <label className="block text-gray-700 text-sm font-semibold">Additional Details</label>
                            <div className="flex flex-wrap gap-4">
                                <div className="flex flex-col gap-2">
                                    <label className="text-sm">Square Footage</label>
                                    <input
                                        type="number"
                                        id="squareFootage"
                                        value={listingData.squareFootage}
                                        onChange={handleChange}
                                        className="p-3 rounded-lg border text-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
                                        placeholder="Square Footage"
                                    />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="text-sm">Property Age</label>
                                    <input
                                        type="number"
                                        id="propertyAge"
                                        value={listingData.propertyAge}
                                        onChange={handleChange}
                                        className="p-3 rounded-lg border text-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
                                        placeholder="Property Age (Years)"
                                    />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="text-sm">Neighborhood</label>
                                    <input
                                        type="text"
                                        id="neighborhood"
                                        value={listingData.neighborhood}
                                        onChange={handleChange}
                                        className="p-3 rounded-lg border text-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
                                        placeholder="Neighborhood"
                                    />
                                </div>
                                 <div className="flex flex-col gap-2">
                                    <label className="text-sm">Listed On</label>
                                    <input
                                        type="date"
                                        id="listedDate"
                                        value={listingData.availableFrom}
                                        onChange={handleChange}
                                        className="p-3 rounded-lg border text-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
                                        placeholder="Neighborhood"
                                    />
                                </div>
                                <div className="relative flex flex-col gap-2">
                                    <label className="text-sm">Status</label>
                                    <select 
                                        name="status" 
                                        id="status"
                                        value={listingData.status}
                                        onChange={handleChange}
                                        className="w-full p-3 text-sm border rounded-lg border-gray-300
                                         bg-white text-gray-900 focus:border-orange-500 
                                         focus:ring-2 focus:ring-orange-500 transition duration-200">
                                        <option value="Active">Active</option>
                                        <option value="Sold">Sold</option>
                                        <option value="Rented">Rented</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Leased">Leased</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Step 3: Image Upload & video upload */}
                    {currentStep === 3 && (
                        <div className="flex flex-col gap-6">
                        {/* Image Upload Section */}
                        <div className="flex flex-col gap-4 sm:py-2 sm:px-12">
                            <label className="block text-gray-700 font-semibold text-sm mb-2">Upload Images</label>
                            <div className="flex flex-col items-center w-full gap-4 sm:p-8">
                                <label className="flex flex-col items-center justify-center w-full h-32 bg-gray-50 rounded-lg border-2 border-dashed border-gray-300 cursor-pointer hover:bg-gray-100 transition-colors duration-200">
                                    <svg className="w-10 h-10 text-gray-400 group-hover:text-gray-600" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm11 9a1 1 0 00-1.707-.707L10 13.586l-2.293-2.293A1 1 0 006 11v4a1 1 0 001 1h6a1 1 0 001-1v-4zm-4-4a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" clipRule="evenodd" />
                                    </svg>
                                    <p className="mt-2 text-sm text-gray-600 group-hover:text-gray-800">Click to upload or drag and drop</p>
                                    <input
                                        type="file"
                                        multiple
                                        accept="image/*"
                                        onChange={(e) => setFiles(e.target.files)}
                                        className="hidden"
                                    />
                                </label>
                                <button
                                    type="button"
                                    onClick={handleImageChange}
                                    className="bg-orange-900 w-full mt-2 rounded font-semibold p-3 text-white hover:shadow-lg hover:opacity-95 focus:outline-none sm:w-1/2"
                                    disabled={uploading}
                                >
                                    {uploading ? "Uploading..." : "Upload"}
                                </button>
                            </div>
                            <p className="text-red-800 mt-2 text-sm">{imageUploadError && imageUploadError}</p>
                            {listingData.imageUrls.length > 0 &&
                                listingData.imageUrls.map((image, index) => (
                                    <div className="flex justify-between p-3 items-center border border-orange-300 rounded-lg" key={image.fullPath || index}>
                                        <img src={image.downloadURL} alt="listing images" className="w-20 h-20 object-contain rounded-lg" />
                                        <button
                                            className="p-2 text-red-800 bg-slate-300 rounded-lg hover:opacity-80"
                                            type="button"
                                            onClick={() => handleDeleteImage(index)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                            ))}
                        </div>

                        {/* Video Upload Section */}
                        <div className="flex flex-col gap-4 sm:py-2 sm:px-12">
                            <label className="block text-gray-700 font-semibold text-sm mb-2">Upload Video</label>
                            <div className="flex flex-col items-center w-full gap-4 sm:p-8">
                                <label className="flex flex-col items-center justify-center w-full h-32 bg-gray-50 rounded-lg border-2 border-dashed border-gray-300 cursor-pointer hover:bg-gray-100 transition-colors duration-200">
                                    <svg className="w-10 h-10 text-gray-400 group-hover:text-gray-600" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm11 9a1 1 0 00-1.707-.707L10 13.586l-2.293-2.293A1 1 0 006 11v4a1 1 0 001 1h6a1 1 0 001-1v-4zm-4-4a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" clipRule="evenodd" />
                                    </svg>
                                    <p className="mt-2 text-sm text-gray-600 group-hover:text-gray-800">Click to upload or drag and drop</p>
                                    <input
                                        type="file"
                                        accept="video/*"
                                        onChange={(e) => setVideoFile(e.target.files[0])}
                                        className="hidden"
                                    />
                                </label>
                                <button
                                    type="button"
                                    onClick={handleVideoChange}
                                    className="bg-orange-900 w-full mt-2 rounded font-semibold p-3 text-white hover:shadow-lg hover:opacity-95 focus:outline-none sm:w-1/2"
                                    disabled={videoUploading || !videoFile}
                                >
                                    {videoUploading ? "Uploading..." : "Upload"}
                                </button>
                            </div>
                            <p className="text-red-800 mt-2 text-sm">{videoUploadError && videoUploadError}</p>
                            {listingData.videoUrl.length > 0 &&
                                listingData.videoUrl.map((video, index) => (
                                    <div className="flex justify-between p-3 items-center border border-orange-300 rounded-lg" key={video.fullPath || index}>
                                        <video controls className="w-20 h-20 object-contain rounded-lg">
                                            <source src={video.downloadURL} type="video/*" />
                                            Your browser does not support the video tag.
                                        </video>
                                        <button
                                            className="p-2 text-red-800 bg-slate-300 rounded-lg hover:opacity-80"
                                            type="button"
                                            onClick={() => handleDeleteVideo(video.fullPath)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                            ))}
                        </div>
                    </div>
                    )}

                    {/* Navigation Buttons */}
                    <div className="flex justify-between gap-4">
                        {currentStep > 1 && (
                            <button
                                type="button"
                                onClick={prevStep}
                                className="bg-gray-500 text-white px-4 py-2 rounded-full text-xs hover:bg-gray-600 transition">
                                Previous
                            </button>
                        )}
                        {currentStep === 3 && (
                            <button
                                type="button"
                                onClick={onCancel}
                                className="bg-red-500 text-white px-4 py-2 text-xs rounded-full hover:bg-red-600 transition">
                                Cancel
                            </button>
                        )}
                        {currentStep < 3 ? (
                            <button
                                type="button"
                                onClick={nextStep}
                                className="bg-[#F06C2C] text-white px-4 py-2 text-xs rounded-full hover:bg-green-600 transition">
                                Next
                            </button>
                        ) : (
                            <Button type="submit" disabled={loading || uploading} text= {loading ? 'Creating...' : 'Create Listing'} />
                        )}
                        {error && <p className="text-red-800 text-sm p-3">{error}</p>}
                    </div>
                </form>
            </main>
        </div>
    );
};

