import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import ConfirmationModal from '../modals/ConfirmationModal';

const UserList = forwardRef((props, ref) => {
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        refreshUsers() {
            fetchUsers();
        }
    }));

    const fetchUsers = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "users"));
            const userList = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setUsers(userList);
        } catch (error) {
            console.error("Error fetching users: ", error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleDeleteClick = (userId) => {
        setSelectedUserId(userId);
        setIsConfirmModalVisible(true);
    };

    const handleConfirmDelete = async () => {
        if (selectedUserId) {
            try {
                await deleteDoc(doc(db, "users", selectedUserId));
                setUsers(users.filter(user => user.id !== selectedUserId));
            } catch (error) {
                console.error("Error deleting user: ", error);
            }
            setIsConfirmModalVisible(false);
            setSelectedUserId(null);
        }
    };

    const handleCancelDelete = () => {
        setIsConfirmModalVisible(false);
        setSelectedUserId(null);
    };

    return (
        <div className="w-full bg-white rounded-lg mt-4">
            <div className="py-4 px-2 sm:px-4">
                <div className="mx-2 sm:mx-4 mt-2">
                    {users.length === 0 ? (
                        <p className="text-gray-400 text-center text-sm sm:text-base">No users found.</p>
                    ) : (
                        <div className="overflow-x-auto">
                            <table className="w-full table-auto border-collapse">
                                <thead>
                                    <tr className="border-b">
                                        <th className="py-2 px-2 sm:px-4 text-left text-xs sm:text-sm md:text-base">Staff</th>
                                        <th className="py-2 px-2 sm:px-4 text-left text-xs sm:text-sm md:text-base">Email</th>
                                        <th className="py-2 px-2 sm:px-4 text-left text-xs sm:text-sm md:text-base">Role</th>
                                        <th className="py-2 px-2 sm:px-4 text-left text-xs sm:text-sm md:text-base">Status</th>
                                        <th className="py-2 px-2 sm:px-4 text-left text-xs sm:text-sm md:text-base">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(user => (
                                        <tr key={user.id} className="border-b border-gray-100">
                                            <td className="py-2 px-4 sm:px-4 text-xs sm:text-sm md:text-sm">{`${user.firstName} ${user.lastName}`}</td>
                                            <td className="py-2 px-2 sm:px-4 text-xs sm:text-sm md:text-sm">{user.email}</td>
                                            <td className="py-2 px-2 sm:px-4 text-xs sm:text-sm md:text-sm">{user.role}</td>
                                            <td className="py-2 px-2 sm:px-4 text-xs sm:text-sm md:text-sm">{user.status}</td>
                                            <td className="py-2 px-2 sm:px-4 text-xs sm:text-sm md:text-sm">
                                                <button
                                                    onClick={() => handleDeleteClick(user.id)}
                                                    className="text-red-500 hover:text-red-700"
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>

            {/* Render the confirmation modal */}
            <ConfirmationModal
                isVisible={isConfirmModalVisible}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        </div>
    );
});

export default UserList;
