import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../button/Button';
import { LuUserPlus, LuRefreshCw } from "react-icons/lu";
import CreateUserModal from '../../modals/CreateUserModal';
import UserList from '../../modals/UserList'; 
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { auth, db } from '../../../firebase/firebase';

const ManagerUsers = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userRole, setUserRole] = useState('');
  const [roles, setRoles] = useState([]);
  const userListRef = useRef(null);
  const navigate = useNavigate();

  // Fetch current user's role
  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDoc = await db.collection('users').doc(user.uid).get();
          if (userDoc.exists) {
            setUserRole(userDoc.data().role);
          }
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    fetchUserRole();
  }, []);

  // Fetch roles from Firestore
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesSnapshot = await getDocs(collection(db, 'roles'));
        const rolesData = rolesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRoles(rolesData);
      } catch (error) {
        console.error("Error fetching roles: ", error);
      }
    };

    fetchRoles();
  }, []);

  // Redirect if the user is not a super admin
  useEffect(() => {
    if (userRole && userRole !== 'super_admin') {
      navigate('/dashboard');
    }
  }, [userRole, navigate]);

  // Handle user creation logic
  const handleUserSubmit = async (userData) => {
    try {
      if (!userData.email || !userData.role || !userData.firstName || !userData.lastName) {
        setErrorMessage('Email, role, first name, and last name are required.');
        return;
      }

      const password = 'defaultPassword'; // Set a default password or implement a way to create custom passwords
      const userCredential = await createUserWithEmailAndPassword(auth, userData.email, password);
      const user = userCredential.user;

      // Add user details to Firestore
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        role: userData.role,
        status: 'pending',
        createdAt: new Date()
      });

      setIsModalVisible(false); // Close modal after submission
      setErrorMessage(''); // Clear any previous error messages

      // Refresh the user list
      userListRef.current?.refreshUsers();

    } catch (error) {
      console.error("Error creating user: ", error);
      switch (error.code) {
        case 'auth/email-already-in-use':
          setErrorMessage('The email address is already in use. Please use a different email.');
          break;
        case 'auth/invalid-email':
          setErrorMessage('The email address is not valid.');
          break;
        case 'auth/weak-password':
          setErrorMessage('The password is too weak.');
          break;
        default:
          setErrorMessage('An unknown error occurred.');
          break;
      }
    }
  };

  const handleCreateUser = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="w-full bg-white rounded-lg p-4">
      <div className="mb-4">
        <div className="mx-4 sm:mx-8">
          <h1 className="font-semibold text-lg sm:text-xl">Team Members</h1>
        </div>
        <hr className="mt-3 w-[95%]" />
        <div className="flex flex-col sm:flex-row w-[95%] items-start sm:items-center justify-between mt-4 mx-4 sm:mx-8 bg-white">
          <p className="text-sm sm:text-sm text-gray-400 mb-4 sm:mb-0">
            Invite or Manage your organizational Team members
          </p>
          <div className="flex items-center space-x-4 sm:mr-24">
            <Button 
              text="Create User" 
              icon={LuUserPlus} 
              onClick={handleCreateUser} 
              className="self-center sm:w-auto" 
            />
            <button>
              <LuRefreshCw 
                onClick={() => userListRef.current?.refreshUsers()} 
                className="w-[50%] self-center  hover:cursor-pointer text-orange-500 hidden md:block" 
                size='44' 
              />
            </button>
          </div>
        </div>
      </div>

      {/* Render the CreateUserModal */}
      <CreateUserModal
        isVisible={isModalVisible}
        onClose={handleModalClose}
        onSubmit={handleUserSubmit}
        roles={roles} // Pass roles to the modal
      />

      {/* Display error message */}
      {errorMessage && (
        <div className="w-full bg-red-100 text-red-600 p-4 mt-4 rounded text-sm sm:text-base">
          {errorMessage}
        </div>
      )}

      {/* Render the UserList component */}
      <UserList ref={userListRef} />
    </div>
  );
};

export default ManagerUsers;
