import React from 'react';

const Button = ({ text, icon: Icon, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center justify-between w-full px-4 py-2 bg-[#F06C2C] text-white font-normal text-xs rounded-full shadow hover:bg-[#e05e20] transition duration-300 ${className}`}
    >
      {Icon && <Icon className="mr-2 text-xl bg-[#F06C2C]" />}
      {text}
    </button>
  );
};

export default Button;
