import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from "../../components/images/brands/logo.png";

const ClientNavbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation(); // Get current location

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Function to check if a link is active for dynamic paths
  const isActive = (path) => {
    // Check if the current path starts with the given path (handles dynamic sub-paths)
    return location.pathname.startsWith(path);
  };

  return (
    <nav className="fixed top-0 left-0 w-full z-50 bg-white shadow-sm border-b border-gray-200">
      <div className="container mx-auto flex justify-between items-center p-2">
        {/* Logo */}
        <div className="flex flex-col items-center p-0">
          <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={logo} className="h-[36px] md:h-[50px]" alt="Appa-Estate Logo" />
          </a>
          <h3 className="hidden md:block text-[8px] italic text-orange-500">Service Rooted In Integrity</h3>
        </div>

        {/* Hamburger Icon for Mobile */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-2xl text-gray-700 focus:outline-none">
            {menuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:flex space-x-6 text-md font-semibold">
          <Link
            to="/properties"
            className={`${
              isActive('/properties') ? 'text-orange-500' : 'text-gray-700'
            } hover:text-orange-500`}
          >
            Properties
          </Link>
          <Link
            to="/services"
            className={`${
              isActive('/services') ? 'text-orange-500' : 'text-gray-700'
            } hover:text-orange-500`}
          >
            Services
          </Link>
          <Link
            to="/blog-posts"
            className={`${
              isActive('/blog-posts') ? 'text-orange-500' : 'text-gray-700'
            } hover:text-orange-500`}
          >
            Blog
          </Link>
          <Link
            to="/contact-us"
            className={`${
              isActive('/contact-us') ? 'text-orange-500' : 'text-gray-700'
            } hover:text-orange-500`}
          >
            Contact Us
          </Link>
        </div>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <>
          {/* Close Button */}
          <button
            onClick={toggleMenu}
            className="fixed top-4 right-4 text-3xl text-gray-700 z-50 md:hidden"
          >
            <FaTimes />
          </button>

          {/* Mobile Menu (Right-aligned, not full width) */}
          <div className="fixed top-0 right-0 w-60 rounded-l-md h-auto bg-gray-800 z-40 flex flex-col items-start p-4 space-y-6 text-white md:hidden">
            <Link
              to="/properties"
              className={`${
                isActive('/properties') ? 'text-orange-400' : 'text-white'
              } text-lg`}
              onClick={toggleMenu}
            >
              Properties
            </Link>
            <Link
              to="/services"
              className={`${
                isActive('/services') ? 'text-orange-400' : 'text-white'
              } text-lg`}
              onClick={toggleMenu}
            >
              Services
            </Link>
            <Link
              to="/blog-posts"
              className={`${
                isActive('/blog-posts') ? 'text-orange-400' : 'text-white'
              } text-lg`}
              onClick={toggleMenu}
            >
              Blog
            </Link>
            <Link
              to="/contact-us"
              className={`${
                isActive('/contact-us') ? 'text-orange-400' : 'text-white'
              } text-lg`}
              onClick={toggleMenu}
            >
              Contact Us
            </Link>
          </div>
        </>
      )}
    </nav>
  );
};

export default ClientNavbar;
