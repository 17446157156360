import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { BsSendCheck } from "react-icons/bs";

const MessageUs = () => {
    const { listingId } = useParams();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [contactMode, setContactMode] = useState('Phone');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const messagesRef = collection(db, 'messages');
            await addDoc(messagesRef, {
                listingId,
                name,
                phone,
                email,
                contactMode,
                message,
                createdAt: new Date(),
                isRead: false,
            });
            setSuccess('Message sent successfully!');
            setName('');
            setPhone('');
            setEmail('');
            setContactMode('Phone');
            setMessage('');
        } catch (err) {
            setError('Failed to send message. Please try again.');
            console.error('Error sending message:', err);
        }
    };

    return (
        <div className="flex flex-col w-full md:w-[70%] p-4 md:p-6 rounded-lg shadow-sm border border-[#F06C2C] shadow-[#F06C2C]">
            <h2 className="text-sm font-semibold mb-4">Contact Sales Team</h2>
            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">{success}</p>}
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    type="text"
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="border rounded-md p-1 w-full text-xs p-3"
                />
                <input
                    type="text"
                    placeholder="Your Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                    className="border rounded-md p-1 w-full text-xs p-3"
                />
                <input
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="border rounded-md p-1 w-full text-xs p-3"
                />
                <select
                    value={contactMode}
                    onChange={(e) => setContactMode(e.target.value)}
                    className="border rounded-md p-1 w-full text-xs p-3"
                >
                    <option value="Phone" className='text-xs p-3'>Phone</option>
                    <option value="Email" className='text-xs p-3'>Email</option>
                </select>
                <textarea
                    placeholder="Your Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    className="border rounded-md p-1 w-full text-xs"
                />
                <button type="submit" className="p-3 text-white text-xs bg-[#DD0606] rounded-full flex items-center">
                    Send Message
                    <span>
                        <BsSendCheck className='ml-2 text-lg' />
                    </span>
                </button>
            </form>
        </div>
    );
};

export default MessageUs;
