import React from 'react';

const RoleModal = ({ isVisible, onClose, onSubmit, roleName, setRoleName, permissions, setPermissions, errorMessage }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white w-11/12 md:w-1/2 lg:w-1/3 rounded-lg p-6">
        <h2 className="text-lg font-medium mb-4">Create a New Role</h2>
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium">Role Name:</label>
            <input
              type="text"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              className="border border-gray-300 rounded w-full p-2 text-sm"
              placeholder="Enter role name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium">Permissions:</label>
            <input
              type="text"
              value={permissions}
              onChange={(e) => setPermissions(e.target.value)}
              className="border border-gray-300 rounded w-full p-2 text-sm"
              placeholder="Comma-separated permissions (e.g., create,edit,delete)"
            />
          </div>

          {/* Display error message */}
          {errorMessage && (
            <div className="w-full bg-red-100 text-red-600 p-4 mb-4 rounded text-sm">
              {errorMessage}
            </div>
          )}

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border rounded text-gray-500 border-gray-300 hover:bg-gray-100 text-sm"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 text-sm"
            >
              Create Role
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RoleModal;
