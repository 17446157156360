import React from 'react';
import Button from '../button/Button';

const ConfirmationModal = ({ isVisible, onConfirm, onCancel }) => {
    if (!isVisible) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div
                className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
                onClick={onCancel} // Close modal if the user clicks outside the modal
            ></div>
            <div className="relative bg-white p-8 rounded-lg shadow-lg w-[90%] max-w-[400px] md:w-[400px]">
                <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
                <p className="mb-4">Are you sure you want to delete this user?</p>
                <div className="flex justify-end space-x-2">
                    <Button text="No" onClick={onCancel} />
                    <Button text="Yes" onClick={onConfirm} />
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
