import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LuHome, LuLayoutList, LuMessagesSquare, LuUsers2, LuUserCog, LuPenSquare } from "react-icons/lu";

const Sidebar = () => {
    const location = useLocation();

    // Define active and hover styles for better visual cues.
    const getLinkClass = (path) => {
        return location.pathname.startsWith(path) 
            ? 'bg-orange-100 text-orange-500'  // Active menu item styles
            : 'text-gray-700 hover:bg-orange-50 hover:text-orange-600';  // Hover styles for non-active items
    };

    return (
        <div className="bg-white text-gray-700 font-semi-bold sm:h-full fixed sm:relative bottom-0 sm:bottom-auto w-full sm:w-auto z-10 sm:z-0">
            <div className="flex flex-col sm:block p-4">
                {/* Sidebar links for larger screens */}
                <p className='text-sm mb-2 mt-4 font-semibold hidden md:block'>Categories</p>
                <hr className='mb-4 hidden md:block'/>
                <Link 
                    to="/dashboard" 
                    className={`hidden sm:flex items-center w-full gap-3 px-3 py-2 my-2 rounded-md transition-all duration-200 ease-in-out ${getLinkClass('/dashboard')}`}
                >
                    <LuHome className='text-sm' />
                    <span className='text-sm'>Dashboard</span>
                </Link>

                <Link 
                    to="/listing" 
                    className={`hidden sm:flex items-center w-full gap-3 px-3 py-2 my-2 rounded-md transition-all duration-200 ease-in-out ${getLinkClass('/listing')}`}
                >
                    <LuLayoutList className='text-sm'/>
                    <span className='text-sm'>Properties</span>
                </Link>

                <Link 
                    to="/messages" 
                    className={`hidden sm:flex items-center w-full gap-3 px-3 py-2 my-2 rounded-md transition-all duration-200 ease-in-out ${getLinkClass('/messages')}`}
                >
                    <LuMessagesSquare className='text-sm'/>
                    <span className='text-sm'>Messages</span>
                </Link>

                <p className='text-sm mb-2 mt-4 font-semibold hidden md:block'>Systems Setting</p>
                <hr className='mb-4 hidden md:block'/>
                
                <Link 
                    to="/manage-users" 
                    className={`hidden sm:flex items-center w-full gap-3 px-3 py-2 my-2 rounded-md transition-all duration-200 ease-in-out ${getLinkClass('/manage-users')}`}
                >
                    <LuUserCog className='text-sm'/>
                    <span className='text-sm'>Team</span>
                </Link>
                <Link 
                    to="/roles" 
                    className={`hidden sm:flex items-center w-full gap-3 px-3 py-2 my-2 rounded-md transition-all duration-200 ease-in-out ${getLinkClass('/roles')}`}
                >
                    <LuPenSquare className='text-sm'/>
                    <span className='text-sm'>User Roles</span>
                </Link>
            </div>

            {/* Bottom sidebar for smaller screens */}
            <div className="sm:hidden fixed bottom-0 left-0 right-0 flex justify-around bg-white w-full p-0 m-0">
                <Link 
                    to="/dashboard" 
                    className={`flex justify-center items-center w-full py-2 ${getLinkClass('/dashboard')}`}
                >
                    <LuHome className="text-2xl" />
                </Link>

                <Link 
                    to="/listing" 
                    className={`flex justify-center items-center w-full py-2 ${getLinkClass('/listing')}`}
                >
                    <LuLayoutList className="text-2xl" />
                </Link>

                <Link 
                    to="/messages" 
                    className={`flex justify-center items-center w-full py-2 ${getLinkClass('/messages')}`}
                >
                    <LuMessagesSquare className="text-2xl" />
                </Link>

                <Link 
                    to="/manage-users" 
                    className={`flex justify-center items-center w-full py-2 ${getLinkClass('/manage-users')}`}
                >
                    <LuUserCog className="text-2xl" />
                </Link>

                <Link 
                    to="/roles" 
                    className={`flex justify-center items-center w-full py-2  ${getLinkClass('/roles')}`}
                >
                    <LuPenSquare className='text-2xl'/>
                </Link>
            </div>
        </div>
    );
}

export default Sidebar;
