import React, { useState, useEffect } from 'react';
import PropertyCard from './PropertyCard';

const PropertyList = ({ listings }) => {
    const [isLoading, setIsLoading] = useState(true); // State to track loading
    const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
    const listingsPerPage = 10; // Items per page

    useEffect(() => {
        // Simulate loading process
        const timer = setTimeout(() => setIsLoading(false), 1000); // Replace with actual data fetching
        return () => clearTimeout(timer);
    }, [listings]);

    // Pagination logic
    const indexOfLastListing = currentPage * listingsPerPage;
    const indexOfFirstListing = indexOfLastListing - listingsPerPage;
    const currentListings = listings.slice(indexOfFirstListing, indexOfLastListing);
    const totalPages = Math.ceil(listings.length / listingsPerPage);

    return (
        <div className="w-full mt-8">
            <h2 className="text-xl font-semibold text-center mb-6">Property Listings</h2>

            {/* Display loading spinner while data is being fetched */}
            {isLoading ? (
                <div className="flex justify-center items-center h-32">
                    <div className="w-8 h-8 border-4 border-orange-500 border-dashed rounded-full animate-spin"></div>
                </div>
            ) : (
                <div>
                    <div className="grid gap-3 md:mx-36">
                        {/* Show listings or "No property found" */}
                        {currentListings.length > 0 ? (
                            currentListings.map((listing, index) => (
                                <div key={index}>
                                    <PropertyCard listing={listing} />
                                </div>
                            ))
                        ) : (
                            <p className="text-center">No property found</p>
                        )}
                    </div>

                    {/* Pagination controls */}
                    {totalPages > 1 && (
                        <div className="flex justify-center mt-6">
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                    key={index}
                                    onClick={() => setCurrentPage(index + 1)}
                                    className={`mx-1 px-3 py-1 rounded text-sm ${
                                        currentPage === index + 1
                                            ? 'bg-orange-500 text-white'
                                            : 'bg-gray-200 hover:bg-orange-200'
                                    }`}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PropertyList;
