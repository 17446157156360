import { useState, useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';  // Firebase configuration
import { fetchUserCountry } from '../../../utils/fetchUserCountry';  // Fetch user's location
import 'flag-icons/css/flag-icons.min.css';  // For country flags
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { countries } from '../../../utils/countries';
import { BsFacebook, BsTwitterX, BsInstagram, BsYoutube, BsTiktok   } from "react-icons/bs";

const LOCAL_STORAGE_KEY = "contactFormData";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    secondName: '',
    email: '',
    phone: '',
    issue: '',
    countryCode: '+254',
    read: false,
  });

  const [selectedCountry, setSelectedCountry] = useState(null);

  // Fetch user's location and pre-select the country
  useEffect(() => {
    const detectUserCountry = async () => {
      const userCountryCode = await fetchUserCountry();
      if (userCountryCode) {
        const matchedCountry = countries.find(country => country.code === userCountryCode);
        if (matchedCountry) {
          setSelectedCountry(matchedCountry);
          setFormData((prevFormData) => ({
            ...prevFormData,
            countryCode: matchedCountry.value,
          }));
        }
      }
    };

    // Retrieve saved form data from localStorage
    const savedFormData = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }

    detectUserCountry();
  }, []);

  // Save form data to localStorage whenever the form data changes
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(formData));
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'contacts'), {...formData, read: false});  // Save form data to Firestore
      alert('Message sent successfully!');
      
      // Clear localStorage after successful submission
      localStorage.removeItem(LOCAL_STORAGE_KEY);

      setFormData({
        firstName: '',
        secondName: '',
        email: '',
        phone: '',
        issue: '',
        countryCode: '+254',
        read: false,
      });
    } catch (error) {
      console.error('Error saving contact form data: ', error);
      alert('Failed to send message. Please try again.');
    }
  };

  return (
    <div
      className='flex flex-col items-center justify-center h-auto bg-cover bg-center px-4 py-8 lg:py-16'
      style={{
        backgroundImage: "url('https://images.unsplash.com/photo-1431576901776-e539bd916ba2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8b3JhbmdlJTIwYmFja2dyb3VuZCUyMGRlc2lnbnxlbnwwfHwwfHx8MA%3D%3D')"
      }}
    >
      <div className='flex flex-col lg:flex-row bg-white rounded-md shadow-lg max-w-4xl w-full'>
        <div className='w-full lg:w-[50%]'>
          <img
            src='https://media.istockphoto.com/id/959923868/photo/freedom-in-moving-handsome-young-man-jumping-against-orange-background.webp?a=1&b=1&s=612x612&w=0&k=20&c=aQg29xyFqg-3sgaX1fQPvvH_Uk21QoAC6Gi8SVJw6Z0='
            alt=''
            className='object-cover h-80 lg:h-full w-full rounded-t-md lg:rounded-l-md lg:rounded-t-none'
          />
        </div>
        <div className='w-full lg:w-[70%] p-6 lg:p-8'>
          <p className='text-xl font-semibold mb-2 text-center lg:text-left'>Get In Touch with Sales Team</p>
          <p className='text-sm text-gray-500 mb-6 text-center lg:text-left'>24/7 we will respond to your Question</p>

          {/* Contact Form */}
          <form className='flex flex-col gap-4 w-full md:w-[90%] sm:flex-wrap' onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
              <input
                className='border p-2 rounded-md w-full focus:border-orange-400 focus:outline-none text-sm'
                name='firstName'
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder='First Name'
                required
              />
              <input
                className='border p-2 rounded-md w-full  focus:border-orange-400 focus:outline-none text-sm'
                name='secondName'
                value={formData.secondName}
                onChange={handleInputChange}
                placeholder='Second Name'
                required
              />
                <input
                className='border w-full p-2 rounded-md  focus:border-orange-400 focus:outline-none text-sm'
                name='email'
                value={formData.email}
                onChange={handleInputChange}
                placeholder='yourname@mail.com'
                required
              />
            </div>
            <div className='flex flex-col sm:flex-wrap gap-4'>
              <PhoneInput
                required
                country={selectedCountry ? selectedCountry.code.toLowerCase() : 'us'}
                value={formData.phone}
                onChange={handlePhoneChange}
                enableAreaCodes
                inputClass='p-2 max-auto border border-gray-300 rounded-md outline-none text-sm'
                buttonClass='bg-gray-100 border border-gray-300 rounded-l-md flex items-center justify-center'
              />
            </div>
            <textarea
              className='border p-2 rounded-md w-full text-sm focus:border-orange-400 focus:outline-none resize-none'
              name='issue'
              value={formData.issue}
              onChange={handleInputChange}
              placeholder='Describe your issue'
              required
              rows='4'
            />

            <button className='p-3 rounded-md bg-[#F06C2C] text-white w-full'>
              Send
            </button>
          </form>

          {/* Social Links */}
          <div className='mt-12 flex items-center gap-4 flex-wrap'>
            <p className='text-sm pt-2'>Follow Us on Our Social Pages:</p>
              <div className='flex gap-3 mt-2 items-center'>
                <a href='https://www.facebook.com/' target='_blank' rel='noopener noreferrer'>
                    <BsFacebook className='w-5 h-5 text-[#F06C2C]' />
                </a>
                <a href='https://www.instagram.com/' target='_blank' rel='noopener noreferrer'>
                    <BsInstagram  className='w-5 h-5 text-[#F06C2C]' />
                </a>
                <a href='https://www.youtube.com/' target='_blank' rel='noopener noreferrer'>
                    <BsYoutube  className='w-6 h-6 text-[#F06C2C]' />
                </a>
                <a href='https://www.youtube.com/' target='_blank' rel='noopener noreferrer'>
                    <BsTwitterX className='w-5 h-5 text-[#F06C2C]' />
                </a>
                <a href='https://www.tiktok.com/' target='_blank' rel='noopener noreferrer'>
                    <BsTiktok className='w-6 h-6 text-[#F06C2C]' />
                </a>
                {/* More Social links can be added, before live production the actual links should be populated*/}
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
