import React from 'react';
import Sidebar from '../admin-navbar/Sidebar';
import DashboardNavbar from '../navbar/DashboardNavbar';
import AdminFooter from '../pages/admin-side/AdminFooter';

function AdminLayout({ children }) {
  return (
    <div className="flex flex-col h-screen">
      <DashboardNavbar className="w-full bg-gray-800 text-white" />
      <div className="flex flex-grow overflow-hidden">
        <Sidebar className="w-80 bg-gray-800 text-white" />
        <main className="flex-grow p-4 bg-gray-50 overflow-auto rounded-lg sm:mr-12 custom-scrollbar">
          {children}
        </main>
      </div>
      <AdminFooter className="w-full bg-gray-800 text-white" />
    </div>
  );
}

export default AdminLayout;
