import React, { useState, useEffect } from 'react';
import { updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { secondaryDb, secondaryAuth } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { BiShow, BiHide } from "react-icons/bi";

const UpdateUserData = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login if the user is not authenticated
    if (!secondaryAuth.currentUser) {
      navigate('/admin'); // Redirect to the login page if the user is not authenticated
    }
  }, [navigate]);

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const toggleConfirmPasswordVisibility = () => setConfirmPasswordVisible(!confirmPasswordVisible);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    // Ensure the user is authenticated
    if (!secondaryAuth.currentUser) {
      setMessage('User is not authenticated. Please log in again.');
      setLoading(false);
      navigate('/admin'); // Redirect to login if user is not authenticated
      return;
    }

    // Check if the passwords match
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      const user = secondaryAuth.currentUser;

      // Re-authenticate the user before updating the password
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);

      // Update password
      if (password) {
        await updatePassword(user, password);
      }

      // Update Firestore: Set updatedPassword to true
      const userRef = doc(secondaryDb, 'users', user.uid);
      await updateDoc(userRef, {
        updatedPassword: true  // Mark the password as updated
      });

      // Success message and redirect
      setMessage('Information updated successfully! Redirecting to dashboard...');
      setLoading(false);

      setTimeout(() => {
        navigate('/dashboard');
      }, 2000); // Allow user to see success message before redirecting
    } catch (error) {
      console.error('Error updating information:', error.message);
      setMessage(`Failed to update information: ${error.message}`);
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center h-[90%]">
      {redirecting ? (
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-orange-500 border-solid"></div>
          <p className="text-orange-500 mt-4">Redirecting...</p>
        </div>
      ) : (
        <div className="bg-white p-8 rounded-md border w-[50%]">
          <div className='flex flex-col mb-4'>
            <h2 className="text-lg font-semibold ">Update Your Information</h2>
            <p className='text-xs text-gray-400'>It is important you update your password for security purposes</p>
          </div>
          {message && <p className={`text-sm ${message.includes('successfully') ? 'text-green-500' : 'text-red-500'}`}>{message}</p>}
          <form onSubmit={handleUpdate}>
            <div className="mb-4">
              <label className="block text-sm font-medium">Current Password</label>
              <input
                type="password"
                className="border border-gray-300 rounded w-full p-3 text-sm"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Enter current password"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">New Password</label>
              <div className="relative">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="border border-gray-300 rounded w-full p-3 text-sm"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter new password"
                />
                <div onClick={togglePasswordVisibility} className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                  {passwordVisible ? <BiHide /> : <BiShow />}
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Confirm Password</label>
              <div className="relative">
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  className="border border-gray-300 rounded w-full p-3 text-sm"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm password"
                />
                <div onClick={toggleConfirmPasswordVisibility} className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                  {confirmPasswordVisible ? <BiHide size={18} /> : <BiShow size={18} />}
                </div>
              </div>
            </div>
            <button
              type="submit"
              className={`w-full bg-orange-500 text-white text-sm px-4 py-2 rounded w-[40%] hover:bg-orange-600 transition ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={loading}
            >
              {loading ? 'Updating...' : 'Update Information'}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default UpdateUserData;
