import video from "../videos/video_test.mp4";
import { useNavigate } from "react-router-dom"

const HeroSection = () => {
    const navigate = useNavigate();


    const handleContactUs = () => {
        navigate('/contact-us')
    };

    const handleFindProperty = () => {
        navigate('/properties')
    }
    return (
        <section className="w-full h-screen relative overflow-hidden">
            {/* Background Video */}
            <video
                className="absolute inset-0 w-full h-full object-cover"
                src={video}
                autoPlay
                loop
                muted
                playsInline // For better autoplay support on mobile
            />

            
            {/* Content */}
            <div className="relative z-10 container flex flex-col mx-auto h-full items-center justify-center">
                {/* Call to Action Section */}
                <div className="flex flex-col items-center justify-center gap-6" >
                    <p className="text-white font-bold text-3xl md:text-5xl text-center leading-normal">Get the Most Affordable Properties under Sale or Rent!</p>
                    <p className="text-white text-center text-xl md:text-3xl">Discover New Listing Everyday - Accommodate Affordability</p>
                    <div className="flex flex-col w-full items-center justify-center md:flex-row gap-4 md:gap-12 m-4 md:m-12">
                        <div className="py-3 px-16 md:p-4 text-white rounded-full bg-[#F06C2C] hover:cursor-pointer"
                            onClick={handleFindProperty}>
                            Find a Property
                        </div>
                        <div className="py-3 px-16 md:p-4 text-white font-semibold rounded-full border border-white hover:cursor-pointer"
                            onClick={handleContactUs}>
                            Contact Us
                        </div>
                    </div>

                </div>
            </div>

            {/* Optional dark overlay for text contrast */}
            <div className="absolute inset-0 bg-black opacity-30"></div>
        </section>
    );
};

export default HeroSection;
