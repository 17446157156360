// utils/countries.js
export const countries = [
	{
		label: "Afghanistan",
		value: "+93",
		code: "AF",
		flag: "🇦🇫",
	},
	{
		label: "Albania",
		value: "+355",
		code: "AL",
		flag: "🇦🇱",
	},
	{
		label: "Algeria",
		value: "+213",
		code: "DZ",
		flag: "🇩🇿",
	},
	{
		label: "Andorra",
		value: "+376",
		code: "AD",
		flag: "🇦🇩",
	},
	{
		label: "Angola",
		value: "+244",
		code: "AO",
		flag: "🇦🇴",
	},
	{
		label: "Argentina",
		value: "+54",
		code: "AR",
		flag: "🇦🇷",
	},
	{
		label: "Armenia",
		value: "+374",
		code: "AM",
		flag: "🇦🇲",
	},
	{
		label: "Australia",
		value: "+61",
		code: "AU",
		flag: "🇦🇺",
	},
	{
		label: "Austria",
		value: "+43",
		code: "AT",
		flag: "🇦🇹",
	},
	{
		label: "Azerbaijan",
		value: "+994",
		code: "AZ",
		flag: "🇦🇿",
	},
	{
		label: "Bahamas",
		value: "+1-242",
		code: "BS",
		flag: "🇧🇸",
	},
	{
		label: "Bahrain",
		value: "+973",
		code: "BH",
		flag: "🇧🇭",
	},
	{
		label: "Bangladesh",
		value: "+880",
		code: "BD",
		flag: "🇧🇩",
	},
	{
		label: "Barbados",
		value: "+1-246",
		code: "BB",
		flag: "🇧🇧",
	},
	{
		label: "Belarus",
		value: "+375",
		code: "BY",
		flag: "🇧🇾",
	},
	{
		label: "Belgium",
		value: "+32",
		code: "BE",
		flag: "🇧🇪",
	},
	{
		label: "Belize",
		value: "+501",
		code: "BZ",
		flag: "🇧🇿",
	},
	{
		label: "Benin",
		value: "+229",
		code: "BJ",
		flag: "🇧🇯",
	},
	{
		label: "Bhutan",
		value: "+975",
		code: "BT",
		flag: "🇧🇹",
	},
	{
		label: "Bolivia",
		value: "+591",
		code: "BO",
		flag: "🇧🇴",
	},
	{
		label: "Bosnia and Herzegovina",
		value: "+387",
		code: "BA",
		flag: "🇧🇦",
	},
	{
		label: "Botswana",
		value: "+267",
		code: "BW",
		flag: "🇧🇼",
	},
	{
		label: "Brazil",
		value: "+55",
		code: "BR",
		flag: "🇧🇷",
	},
	{
		label: "Brunei",
		value: "+673",
		code: "BN",
		flag: "🇧🇳",
	},
	{
		label: "Bulgaria",
		value: "+359",
		code: "BG",
		flag: "🇧🇬",
	},
	{
		label: "Burkina Faso",
		value: "+226",
		code: "BF",
		flag: "🇧🇫",
	},
	{
		label: "Burundi",
		value: "+257",
		code: "BI",
		flag: "🇧🇮",
	},
	{
		label: "Cambodia",
		value: "+855",
		code: "KH",
		flag: "🇰🇭",
	},
	{
		label: "Cameroon",
		value: "+237",
		code: "CM",
		flag: "🇨🇲",
	},
	{
		label: "Canada",
		value: "+1",
		code: "CA",
		flag: "🇨🇦",
	},
	{
		label: "Cape Verde",
		value: "+238",
		code: "CV",
		flag: "🇨🇻",
	},
	{
		label: "Central African Republic",
		value: "+236",
		code: "CF",
		flag: "🇨🇫",
	},
	{
		label: "Chad",
		value: "+235",
		code: "TD",
		flag: "🇹🇩",
	},
	{
		label: "Chile",
		value: "+56",
		code: "CL",
		flag: "🇨🇱",
	},
	{
		label: "China",
		value: "+86",
		code: "CN",
		flag: "🇨🇳",
	},
	{
		label: "Colombia",
		value: "+57",
		code: "CO",
		flag: "🇨🇴",
	},
	{
		label: "Comoros",
		value: "+269",
		code: "KM",
		flag: "🇰🇲",
	},
	{
		label: "Congo (Congo-Brazzaville)",
		value: "+242",
		code: "CG",
		flag: "🇨🇬",
	},
	{
		label: "Congo (DRC)",
		value: "+243",
		code: "CD",
		flag: "🇨🇩",
	},
	{
		label: "Costa Rica",
		value: "+506",
		code: "CR",
		flag: "🇨🇷",
	},
	{
		label: "Croatia",
		value: "+385",
		code: "HR",
		flag: "🇭🇷",
	},
	{
		label: "Cuba",
		value: "+53",
		code: "CU",
		flag: "🇨🇺",
	},
	{
		label: "Cyprus",
		value: "+357",
		code: "CY",
		flag: "🇨🇾",
	},
	{
		label: "Czech Republic",
		value: "+420",
		code: "CZ",
		flag: "🇨🇿",
	},
	{
		label: "Denmark",
		value: "+45",
		code: "DK",
		flag: "🇩🇰",
	},
	{
		label: "Djibouti",
		value: "+253",
		code: "DJ",
		flag: "🇩🇯",
	},
	{
		label: "Dominica",
		value: "+1-767",
		code: "DM",
		flag: "🇩🇲",
	},
	{
		label: "Dominican Republic",
		value: "+1-809",
		code: "DO",
		flag: "🇩🇴",
	},
	{
		label: "Ecuador",
		value: "+593",
		code: "EC",
		flag: "🇪🇨",
	},
	{
		label: "Egypt",
		value: "+20",
		code: "EG",
		flag: "🇪🇬",
	},
	{
		label: "El Salvador",
		value: "+503",
		code: "SV",
		flag: "🇸🇻",
	},
	{
		label: "Equatorial Guinea",
		value: "+240",
		code: "GQ",
		flag: "🇬🇶",
	},
	{
		label: "Eritrea",
		value: "+291",
		code: "ER",
		flag: "🇪🇷",
	},
	{
		label: "Estonia",
		value: "+372",
		code: "EE",
		flag: "🇪🇪",
	},
	{
		label: "Eswatini",
		value: "+268",
		code: "SZ",
		flag: "🇸🇿",
	},
	{
		label: "Ethiopia",
		value: "+251",
		code: "ET",
		flag: "🇪🇹",
	},
	{
		label: "Fiji",
		value: "+679",
		code: "FJ",
		flag: "🇫🇯",
	},
	{
		label: "Finland",
		value: "+358",
		code: "FI",
		flag: "🇫🇮",
	},
	{
		label: "France",
		value: "+33",
		code: "FR",
		flag: "🇫🇷",
	},
	{
		label: "Gabon",
		value: "+241",
		code: "GA",
		flag: "🇬🇦",
	},
	{
		label: "Gambia",
		value: "+220",
		code: "GM",
		flag: "🇬🇲",
	},
	{
		label: "Georgia",
		value: "+995",
		code: "GE",
		flag: "🇬🇪",
	},
	{
		label: "Germany",
		value: "+49",
		code: "DE",
		flag: "🇩🇪",
	},
	{
		label: "Ghana",
		value: "+233",
		code: "GH",
		flag: "🇬🇭",
	},
	{
		label: "Greece",
		value: "+30",
		code: "GR",
		flag: "🇬🇷",
	},
	{
		label: "Grenada",
		value: "+1-473",
		code: "GD",
		flag: "🇬🇩",
	},
	{
		label: "Guatemala",
		value: "+502",
		code: "GT",
		flag: "🇬🇹",
	},
	{
		label: "Guinea",
		value: "+224",
		code: "GN",
		flag: "🇬🇳",
	},
	{
		label: "Guinea-Bissau",
		value: "+245",
		code: "GW",
		flag: "🇬🇼",
	},
	{
		label: "Guyana",
		value: "+592",
		code: "GY",
		flag: "🇬🇾",
	},
	{
		label: "Haiti",
		value: "+509",
		code: "HT",
		flag: "🇭🇹",
	},
	{
		label: "Honduras",
		value: "+504",
		code: "HN",
		flag: "🇭🇳",
	},
	{
		label: "Hungary",
		value: "+36",
		code: "HU",
		flag: "🇭🇺",
	},
	{
		label: "Iceland",
		value: "+354",
		code: "IS",
		flag: "🇮🇸",
	},
	{
		label: "India",
		value: "+91",
		code: "IN",
		flag: "🇮🇳",
	},
	{
		label: "Indonesia",
		value: "+62",
		code: "ID",
		flag: "🇮🇩",
	},
	{
		label: "Iran",
		value: "+98",
		code: "IR",
		flag: "🇮🇷",
	},
	{
		label: "Iraq",
		value: "+964",
		code: "IQ",
		flag: "🇮🇶",
	},
	{
		label: "Ireland",
		value: "+353",
		code: "IE",
		flag: "🇮🇪",
	},
	{
		label: "Israel",
		value: "+972",
		code: "IL",
		flag: "🇮🇱",
	},
	{
		label: "Italy",
		value: "+39",
		code: "IT",
		flag: "🇮🇹",
	},
	{
		label: "Jamaica",
		value: "+1-876",
		code: "JM",
		flag: "🇯🇲",
	},
	{
		label: "Japan",
		value: "+81",
		code: "JP",
		flag: "🇯🇵",
	},
	{
		label: "Jordan",
		value: "+962",
		code: "JO",
		flag: "🇯🇴",
	},
	{
		label: "Kazakhstan",
		value: "+7",
		code: "KZ",
		flag: "🇰🇿",
	},
	{
		label: "Kenya",
		value: "+254",
		code: "KE",
		flag: "🇰🇪",
	},
	{
		label: "Kiribati",
		value: "+686",
		code: "KI",
		flag: "🇰🇮",
	},
	{
		label: "Kuwait",
		value: "+965",
		code: "KW",
		flag: "🇰🇼",
	},
	{
		label: "Kyrgyzstan",
		value: "+996",
		code: "KG",
		flag: "🇰🇬",
	},
	{
		label: "Laos",
		value: "+856",
		code: "LA",
		flag: "🇱🇦",
	},
	{
		label: "Latvia",
		value: "+371",
		code: "LV",
		flag: "🇱🇻",
	},
	{
		label: "Lebanon",
		value: "+961",
		code: "LB",
		flag: "🇱🇧",
	},
	{
		label: "Lesotho",
		value: "+266",
		code: "LS",
		flag: "🇱🇸",
	},
	{
		label: "Liberia",
		value: "+231",
		code: "LR",
		flag: "🇱🇷",
	},
	{
		label: "Libya",
		value: "+218",
		code: "LY",
		flag: "🇱🇾",
	},
	{
		label: "Liechtenstein",
		value: "+423",
		code: "LI",
		flag: "🇱🇮",
	},
	{
		label: "Lithuania",
		value: "+370",
		code: "LT",
		flag: "🇱🇹",
	},
	{
		label: "Luxembourg",
		value: "+352",
		code: "LU",
		flag: "🇱🇺",
	},
	{
		label: "Madagascar",
		value: "+261",
		code: "MG",
		flag: "🇲🇬",
	},
	{
		label: "Malawi",
		value: "+265",
		code: "MW",
		flag: "🇲🇼",
	},
	{
		label: "Malaysia",
		value: "+60",
		code: "MY",
		flag: "🇲🇾",
	},
	{
		label: "Maldives",
		value: "+960",
		code: "MV",
		flag: "🇲🇻",
	},
	{
		label: "Mali",
		value: "+223",
		code: "ML",
		flag: "🇲🇱",
	},
	{
		label: "Malta",
		value: "+356",
		code: "MT",
		flag: "🇲🇹",
	},
	{
		label: "Marshall Islands",
		value: "+692",
		code: "MH",
		flag: "🇲🇭",
	},
	{
		label: "Mauritania",
		value: "+222",
		code: "MR",
		flag: "🇲🇷",
	},
	{
		label: "Mauritius",
		value: "+230",
		code: "MU",
		flag: "🇲🇺",
	},
	{
		label: "Mexico",
		value: "+52",
		code: "MX",
		flag: "🇲🇽",
	},
	{
		label: "Micronesia",
		value: "+691",
		code: "FM",
		flag: "🇫🇲",
	},
	{
		label: "Moldova",
		value: "+373",
		code: "MD",
		flag: "🇲🇩",
	},
	{
		label: "Monaco",
		value: "+377",
		code: "MC",
		flag: "🇲🇨",
	},
	{
		label: "Mongolia",
		value: "+976",
		code: "MN",
		flag: "🇲🇳",
	},
	{
		label: "Montenegro",
		value: "+382",
		code: "ME",
		flag: "🇲🇪",
	},
	{
		label: "Morocco",
		value: "+212",
		code: "MA",
		flag: "🇲🇦",
	},
	{
		label: "Mozambique",
		value: "+258",
		code: "MZ",
		flag: "🇲🇿",
	},
	{
		label: "Myanmar (Burma)",
		value: "+95",
		code: "MM",
		flag: "🇲🇲",
	},
	{
		label: "Namibia",
		value: "+264",
		code: "NA",
		flag: "🇳🇦",
	},
	{
		label: "Nauru",
		value: "+674",
		code: "NR",
		flag: "🇳🇷",
	},
	{
		label: "Nepal",
		value: "+977",
		code: "NP",
		flag: "🇳🇵",
	},
	{
		label: "Netherlands",
		value: "+31",
		code: "NL",
		flag: "🇳🇱",
	},
	{
		label: "New Zealand",
		value: "+64",
		code: "NZ",
		flag: "🇳🇿",
	},
	{
		label: "Nicaragua",
		value: "+505",
		code: "NI",
		flag: "🇳🇮",
	},
	{
		label: "Niger",
		value: "+227",
		code: "NE",
		flag: "🇳🇪",
	},
	{
		label: "Nigeria",
		value: "+234",
		code: "NG",
		flag: "🇳🇬",
	},
	{
		label: "North Korea",
		value: "+850",
		code: "KP",
		flag: "🇰🇵",
	},
	{
		label: "North Macedonia",
		value: "+389",
		code: "MK",
		flag: "🇲🇰",
	},
	{
		label: "Norway",
		value: "+47",
		code: "NO",
		flag: "🇳🇴",
	},
	{
		label: "Oman",
		value: "+968",
		code: "OM",
		flag: "🇴🇲",
	},
	{
		label: "Pakistan",
		value: "+92",
		code: "PK",
		flag: "🇵🇰",
	},
	{
		label: "Palau",
		value: "+680",
		code: "PW",
		flag: "🇵🇼",
	},
	{
		label: "Panama",
		value: "+507",
		code: "PA",
		flag: "🇵🇦",
	},
	{
		label: "Papua New Guinea",
		value: "+675",
		code: "PG",
		flag: "🇵🇬",
	},
	{
		label: "Paraguay",
		value: "+595",
		code: "PY",
		flag: "🇵🇾",
	},
	{
		label: "Peru",
		value: "+51",
		code: "PE",
		flag: "🇵🇪",
	},
	{
		label: "Philippines",
		value: "+63",
		code: "PH",
		flag: "🇵🇭",
	},
	{
		label: "Poland",
		value: "+48",
		code: "PL",
		flag: "🇵🇱",
	},
	{
		label: "Portugal",
		value: "+351",
		code: "PT",
		flag: "🇵🇹",
	},
	{
		label: "Qatar",
		value: "+974",
		code: "QA",
		flag: "🇶🇦",
	},
	{
		label: "Romania",
		value: "+40",
		code: "RO",
		flag: "🇷🇴",
	},
	{
		label: "Russia",
		value: "+7",
		code: "RU",
		flag: "🇷🇺",
	},
	{
		label: "Rwanda",
		value: "+250",
		code: "RW",
		flag: "🇷🇼",
	},
	{
		label: "Saint Kitts and Nevis",
		value: "+1-869",
		code: "KN",
		flag: "🇰🇳",
	},
	{
		label: "Saint Lucia",
		value: "+1-758",
		code: "LC",
		flag: "🇱🇨",
	},
	{
		label: "Saint Vincent and the Grenadines",
		value: "+1-784",
		code: "VC",
		flag: "🇻🇨",
	},
	{
		label: "Samoa",
		value: "+685",
		code: "WS",
		flag: "🇼🇸",
	},
	{
		label: "San Marino",
		value: "+378",
		code: "SM",
		flag: "🇸🇲",
	},
	{
		label: "Sao Tome and Principe",
		value: "+239",
		code: "ST",
		flag: "🇸🇹",
	},
	{
		label: "Saudi Arabia",
		value: "+966",
		code: "SA",
		flag: "🇸🇦",
	},
	{
		label: "Senegal",
		value: "+221",
		code: "SN",
		flag: "🇸🇳",
	},
	{
		label: "Serbia",
		value: "+381",
		code: "RS",
		flag: "🇷🇸",
	},
	{
		label: "Seychelles",
		value: "+248",
		code: "SC",
		flag: "🇸🇨",
	},
	{
		label: "Sierra Leone",
		value: "+232",
		code: "SL",
		flag: "🇸🇱",
	},
	{
		label: "Singapore",
		value: "+65",
		code: "SG",
		flag: "🇸🇬",
	},
	{
		label: "Slovakia",
		value: "+421",
		code: "SK",
		flag: "🇸🇰",
	},
	{
		label: "Slovenia",
		value: "+386",
		code: "SI",
		flag: "🇸🇮",
	},
	{
		label: "Solomon Islands",
		value: "+677",
		code: "SB",
		flag: "🇸🇧",
	},
	{
		label: "Somalia",
		value: "+252",
		code: "SO",
		flag: "🇸🇴",
	},
	{
		label: "South Africa",
		value: "+27",
		code: "ZA",
		flag: "🇿🇦",
	},
	{
		label: "South Korea",
		value: "+82",
		code: "KR",
		flag: "🇰🇷",
	},
	{
		label: "South Sudan",
		value: "+211",
		code: "SS",
		flag: "🇸🇸",
	},
	{
		label: "Spain",
		value: "+34",
		code: "ES",
		flag: "🇪🇸",
	},
	{
		label: "Sri Lanka",
		value: "+94",
		code: "LK",
		flag: "🇱🇰",
	},
	{
		label: "Sudan",
		value: "+249",
		code: "SD",
		flag: "🇸🇩",
	},
	{
		label: "Suriname",
		value: "+597",
		code: "SR",
		flag: "🇸🇷",
	},
	{
		label: "Sweden",
		value: "+46",
		code: "SE",
		flag: "🇸🇪",
	},
	{
		label: "Switzerland",
		value: "+41",
		code: "CH",
		flag: "🇨🇭",
	},
	{
		label: "Syria",
		value: "+963",
		code: "SY",
		flag: "🇸🇾",
	},
	{
		label: "Taiwan",
		value: "+886",
		code: "TW",
		flag: "🇹🇼",
	},
	{
		label: "Tajikistan",
		value: "+992",
		code: "TJ",
		flag: "🇹🇯",
	},
	{
		label: "Tanzania",
		value: "+255",
		code: "TZ",
		flag: "🇹🇿",
	},
	{
		label: "Thailand",
		value: "+66",
		code: "TH",
		flag: "🇹🇭",
	},
	{
		label: "Timor-Leste",
		value: "+670",
		code: "TL",
		flag: "🇹🇱",
	},
	{
		label: "Togo",
		value: "+228",
		code: "TG",
		flag: "🇹🇬",
	},
	{
		label: "Tonga",
		value: "+676",
		code: "TO",
		flag: "🇹🇴",
	},
	{
		label: "Trinidad and Tobago",
		value: "+1-868",
		code: "TT",
		flag: "🇹🇹",
	},
	{
		label: "Tunisia",
		value: "+216",
		code: "TN",
		flag: "🇹🇳",
	},
	{
		label: "Turkey",
		value: "+90",
		code: "TR",
		flag: "🇹🇷",
	},
	{
		label: "Turkmenistan",
		value: "+993",
		code: "TM",
		flag: "🇹🇲",
	},
	{
		label: "Tuvalu",
		value: "+688",
		code: "TV",
		flag: "🇹🇻",
	},
	{
		label: "Uganda",
		value: "+256",
		code: "UG",
		flag: "🇺🇬",
	},
	{
		label: "Ukraine",
		value: "+380",
		code: "UA",
		flag: "🇺🇦",
	},
	{
		label: "United Arab Emirates",
		value: "+971",
		code: "AE",
		flag: "🇦🇪",
	},
	{
		label: "United Kingdom",
		value: "+44",
		code: "GB",
		flag: "🇬🇧",
	},
	{
		label: "United States",
		value: "+1",
		code: "US",
		flag: "🇺🇸",
	},
	{
		label: "Uruguay",
		value: "+598",
		code: "UY",
		flag: "🇺🇾",
	},
	{
		label: "Uzbekistan",
		value: "+998",
		code: "UZ",
		flag: "🇺🇿",
	},
	{
		label: "Vanuatu",
		value: "+678",
		code: "VU",
		flag: "🇻🇺",
	},
	{
		label: "Vatican City",
		value: "+39",
		code: "VA",
		flag: "🇻🇦",
	},
	{
		label: "Venezuela",
		value: "+58",
		code: "VE",
		flag: "🇻🇪",
	},
	{
		label: "Vietnam",
		value: "+84",
		code: "VN",
		flag: "🇻🇳",
	},
	{
		label: "Yemen",
		value: "+967",
		code: "YE",
		flag: "🇾🇪",
	},
	{
		label: "Zambia",
		value: "+260",
		code: "ZM",
		flag: "🇿🇲",
	},
	{
		label: "Zimbabwe",
		value: "+263",
		code: "ZW",
		flag: "🇿🇼",
	},
];
