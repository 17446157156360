import React, { useContext, useEffect, useState } from "react";
import logo from "../../components/images/brands/logo.png";
import defaultProfileImage from "../../components/images/brands/default_profile.png";
import { AuthContext } from '../AppContext/AppContext';
import { useNavigate } from 'react-router-dom';
import { db, secondaryDb } from '../../firebase/firebase';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { BiCommentDetail } from "react-icons/bi";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai"; // Import icons for menu toggle

const Tooltip = ({ text, children }) => {
    const [visible, setVisible] = useState(false);
    return (
        <div className="relative inline-block">
            <div
                onMouseEnter={() => setVisible(true)}
                onMouseLeave={() => setVisible(false)}
                className="cursor-pointer"
            >
                {children}
            </div>
            {visible && (
                <div className="absolute z-10 p-1 text-xs text-white bg-black rounded-md">
                    {text}
                </div>
            )}
        </div>
    );
};

const DashboardNavbar = () => {
    const { user, secondaryUser, signOutUser, setUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [newRequestsCount, setNewRequestsCount] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(false);
    const [serviceRequests, setServiceRequests] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Menu open state

    const handleSignOut = (e) => {
        e.preventDefault();
        signOutUser();
        navigate('/admin');
    };

    const handleClick = () => {
        navigate('/notifications');
    };

    const handleServiceClick = () => {
        navigate('/employee-service-request');
    };

    const activeUser = user || secondaryUser;
    const activeDb = activeUser ? (activeUser.role === 'admin' ? db : secondaryDb) : db;

    useEffect(() => {
        const fetchNewRequestsCount = async () => {
            if (!activeUser) return;

            const q = query(collection(activeDb, 'contacts'), where("read", "==", false));
            const querySnapshot = await getDocs(q);
            setNewRequestsCount(querySnapshot.size);
        };

        fetchNewRequestsCount();
    }, [activeUser, activeDb]);

    useEffect(() => {
        const fetchServiceRequests = async () => {
            if (!activeUser) return;
            const q = query(collection(activeDb, 'blogposts'), where("active", "==", false));
            const querySnapshot = await getDocs(q);
            setServiceRequests(querySnapshot.size);
        };

        fetchServiceRequests();
    }, [activeUser, activeDb, updateFlag]);

    const handleImageUpload = async (file, useSecondaryDb = false) => {
        try {
            const activeDbRef = useSecondaryDb ? secondaryDb : db;
            const storage = getStorage();
            const storageRef = ref(storage, `profile_images/${activeUser.uid}`);
            await uploadBytes(storageRef, file);
            const imageUrl = await getDownloadURL(storageRef);
            const userRef = doc(activeDbRef, "users", activeUser.uid);
            await updateDoc(userRef, { profileImage: imageUrl });
            if (setUser) {
                setUser((prevUser) => ({ ...prevUser, profileImage: imageUrl }));
                setUpdateFlag((prev) => !prev);
            }
        } catch (error) {
            console.error("Error during image upload or Firestore update:", error);
        }
    };

    const userProfileImage = activeUser?.profileImage || defaultProfileImage;
    const userName = activeUser?.name || 'Guest';
    const userRole = activeUser?.role || 'User';

    return (
        <nav className="flex flex-wrap items-center justify-between bg-white dark:bg-gray-900 py-4 px-4 md:px-8 lg:px-16">
            <div className="flex items-center w-full md:w-auto">
                <a href="https://appaestates.com/" className="flex items-center space-x-3">
                    <img src={logo} className="h-8 md:h-10" alt="Appa-Estate Logo" />
                </a>
                {/* Hamburger menu button for small screens */}
                <button
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    className="md:hidden ml-auto text-gray-700 dark:text-white"
                >
                    {isMenuOpen ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
                </button>
            </div>

            {/* Menu items (hidden on small screens by default) */}
            <div className={`w-full md:flex md:w-auto ${isMenuOpen ? 'block' : 'hidden'}`}>
                <div className="flex flex-col md:flex-row md:items-center md:space-x-8 mt-4 md:mt-0 space-y-4 md:space-y-0">
                    <div className="relative flex items-center">
                        <BiCommentDetail
                            size={26} onClick={handleServiceClick}
                            className="hover:cursor-pointer p-1 hover:bg-orange-100 rounded-full"
                        />
                        {serviceRequests > 0 && (
                            <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white text-xs font-normal rounded-full w-5 h-5 flex items-center justify-center">
                                {serviceRequests > 99 ? '99+' : serviceRequests}
                            </span>
                        )}
                    </div>
                    <div className="relative flex items-center hover:bg-orange-100 rounded-full">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="black"
                            className="w-6 h-6 cursor-pointer"
                            onClick={handleClick}
                            aria-label="Notifications"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
                            />
                        </svg>
                        {newRequestsCount > 0 && (
                            <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white text-xs font-normal rounded-full w-5 h-5 flex items-center justify-center">
                                {newRequestsCount > 99 ? '99+' : newRequestsCount}
                            </span>
                        )}
                    </div>
                    <div className="flex items-center space-x-2">
                        <Tooltip text="Click the image to update your profile picture">
                            <label className="cursor-pointer">
                                <img
                                    key={updateFlag}
                                    src={userProfileImage}
                                    alt="User Profile"
                                    className="w-8 h-8 md:w-10 md:h-10 rounded-full hover:ring-2 hover:ring-blue-500 transition"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageUpload(e.target.files[0])}
                                    className="hidden"
                                />
                            </label>
                        </Tooltip>
                        <div className="flex flex-col items-start">
                            <p className="text-gray-500 text-sm md:text-base">{userName}</p>
                            <p className="text-gray-300 text-xs md:text-sm">{userRole}</p>
                        </div>
                    </div>
                    <button onClick={handleSignOut} className="flex items-center space-x-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="black"
                            className="w-6 h-6"
                            aria-label="Sign Out"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-7.5A2.25 2.25 0 0 0 3.75 5.25v13.5A2.25 2.25 0 0 0 6 21h7.5a2.25 2.25 0 0 0 2.25-2.25V15M9 12h12m0 0l-3-3m3 3l-3 3"
                            />
                        </svg>
                        <span className="text-sm md:text-[14px] font-semibold">Sign Out</span>
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default DashboardNavbar;
