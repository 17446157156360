const UnauthorizedPage = () => {
    return (
        <div className="relative flex flex-col items-center justify-center bg-white h-[99%]">
            {/* Background Image */}
            <img 
                src="https://img.freepik.com/premium-vector/cartoon-little-kid-saying-sorry_29937-11531.jpg?w=740" 
                alt="Access Denied" 
                className="absolute inset-0 object-cover w-full h-full opacity-10"
            />
            <div className="relative z-10 flex flex-col items-center">
                <h2 className="text-xl font-semibold text-red-600 mb-4">Access Denied</h2>
                <p className="text-sm text-gray-800 mb-2">
                    We regret to inform you that you do not have the necessary permissions to access this page.
                </p>
                <p className="text-sm text-gray-600">
                    Please contact your administrator for assistance. Thank you for your understanding.
                </p>
            </div>
        </div>
    );
}

export default UnauthorizedPage;
