import React, {useContext} from 'react';
import { AuthContext } from '../../AppContext/AppContext';

const Dashboard = () => {
    const { user, secondaryUser } = useContext(AuthContext);


    return (
        <div className='rounded-md px-4 py-3 bg-white'>
            <div className='flex flex-col gap-1'>
                <p className="text-sm">Hello <span className="font-semibold">{user.name}</span> !</p>
                <p className='text-[12px] text-gray-600'>These are your highlights for the day</p>
            </div>
            <hr className='mt-4' />
            <div className='flex flex-col md:flex-row md:w-full w-full sm:w-[75%] lg:w-[90%] mt-12 items-center justify-center md:gap-16'>
                <div className='flex flex-col p-4 border border-gray-100 rounded-md gap-1 w-full sm:w-[80%] md:w-[30%] lg:w-[25%]'>
                    <p className='font-semibold text-gray-500'>Total Listings</p>
                    <p className='text-[12px] text-gray-400 italic'>These include all properties, including commercial, for rent, land and leasing</p>
                    <p className='font-semibold text-gray-500'>200</p>
                </div>
                <div className='flex flex-col p-4 border border-gray-100 rounded-md w-full sm:w-[80%] md:w-[30%] lg:w-[25%]'>
                    <p className='font-semibold text-gray-500'>Total Listings</p>
                    <p className='text-[12px] text-gray-400'>These include all properties, including commercial, for rent, land and leasing</p>
                    <p className='font-semibold text-gray-500'>200</p>
                </div>
                <div className='flex flex-col p-4 border border-gray-100 rounded-md w-full sm:w-[80%] md:w-[30%] lg:w-[25%]'>
                    <p className='font-semibold text-gray-500'>Total Listings</p>
                    <p className='text-[12px] text-gray-400'>These include all properties, including commercial, for rent, land and leasing</p>
                    <p className='font-semibold text-gray-500'>200</p>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
