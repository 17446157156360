import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { db } from '../../firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

export default function Custom_React_Player() {
  const [property, setProperty] = useState(null);
  const { listingId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const frontPropertyRef = doc(db, 'listings', listingId);
        const frontPropertySnap = await getDoc(frontPropertyRef);

        if (frontPropertySnap.exists()) {
          const propertyData = frontPropertySnap.data();
          
          // Assuming `videoUrl` is a Firebase Storage reference
          const videoUrl = propertyData.videoUrl;

          setProperty({ ...propertyData, videoUrl });
        } else {
          setError('Property not found');
        }
      } catch (err) {
        setError('Error fetching property. Please try again.');
        console.error('Error fetching property:', err);
      } finally {
        setLoading(false);
      }
    };

    if (listingId) {
      fetchProperty();
    } else {
      setError('Listing ID is missing.');
    }
  }, [listingId]);

  // Loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Error state
  if (error) {
    return <div>{error}</div>;
  }

  // Check if property exists and if the videoUrl is valid
  if (!property || !property.videoUrl) {
    return <div>No video available for this property.</div>;
  }

  // Log the video URL for debugging
  console.log('Video URL:', property.videoUrl);

  return (
    <div className="w-full p-4">
      <h2 className="flex items-center justify-center font-semibold text-lg w-full">
        Click on the Video for a Virtual View of the Property
      </h2>
      <div>
        <ReactPlayer
          url={property.videoUrl}
          width="100%"
          height="360"
          controls={true}
          muted={false}
          onError={(e) => console.error('Error loading video:', e)}
          onReady={() => console.log('Player is ready')}
          onStart={() => console.log('Video started playing')}
        />
      </div>
    </div>
  );
}
