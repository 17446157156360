import React, { useState, useContext } from 'react';
import { BiX } from "react-icons/bi";
import { AuthContext } from '../AppContext/AppContext';

const CreateUserModal = ({ isVisible, onClose, roles }) => {
  const { createUserWithEmail } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  if (!isVisible) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
  
    // Check that all required fields are filled
    if (!email || !firstName || !lastName || !role) {
      setError("All fields are required.");
      setLoading(false);
      return;
    }
  
    // Call createUserWithEmail and handle result
    const result = await createUserWithEmail(email, `${firstName} ${lastName}`, role);
    setLoading(false);
  
    if (result.success) {
      setSuccess(result.message);
      // Reset form fields
      setEmail('');
      setFirstName('');
      setLastName('');
      setRole('');
    } else {
      setError(result.message || "An error occurred while creating the user.");
    }
  };  


  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="relative bg-white rounded-lg shadow-lg p-6 w-11/12 sm:w-3/4 md:w-2/3 lg:w-1/3">
        <BiX
          size={24}
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer"
        />
        <h2 className="text-lg font-semibold mb-4">Create User</h2>
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium">Email</label>
            <input
              type="email"
              className="border border-gray-300 rounded w-full p-2 text-sm"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium">First Name</label>
              <input
                type="text"
                className="border border-gray-300 rounded w-full p-2 text-sm"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Last Name</label>
              <input
                type="text"
                className="border border-gray-300 rounded w-full p-2 text-sm"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium">Role</label>
            <select
              className="border border-gray-300 rounded w-full p-2 text-sm"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              required
            >
              <option value="">Select Role</option>
              {roles.map((r) => (
                <option key={r.id} value={r.role_name}>{r.role_name}</option>
              ))}
            </select>
          </div>
          <button
            type="submit"
            className={`w-full bg-orange-500 text-white text-sm px-4 py-2 rounded hover:bg-orange-600 transition ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading}
          >
            {loading ? 'Creating...' : 'Create User'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateUserModal;
