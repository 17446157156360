import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LuMapPin } from "react-icons/lu";
import { FaShower, FaBed } from "react-icons/fa";


const PropertyCard = ({ listing }) => {
    const navigate = useNavigate();

    const generateSlug = (title) => {
        return title.toLowerCase().replace(/\s+/g, '-');
    };


    const handleViewProperty = (listingId, title, tabName) => {
        const slug = generateSlug(title); 
        navigate(`/properties/${listingId}/${slug}`);
    };
      
    return (
        <div className='md:flex bg-white md:rounded-lg overflow-hidden items-center border border-red-50 hover:cursor-pointer hover:bg-red-50 shadow-sm' 
        onClick={() => handleViewProperty(listing.id, listing.title, listing.tabName)}>
            {/* Image Section */}
            <div className='relative flex w-full md:w-[30%] h-auto'>
                {listing.imageUrls && listing.imageUrls.length > 0 && (
                    <>
                        <img
                            src={listing.imageUrls[0].downloadURL}
                            alt={listing.title}
                            className='object-cover w-full md:w-full h-48 md:h-38 rounded-sm'
                        />
                        {/* Show the watermark only if there are multiple images */}
                        {listing.imageUrls.length > 1 && (
                            <div className='absolute top-2 right-2 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded-full'>
                                {listing.imageUrls.length} images
                            </div>
                        )}
                    </>
                )}
            </div>


            {/* Content Section */}
            <div className='flex flex-col w-full md:w-[70%] p-4'>
                {/* Property Title */}
                <h3 className='font-semibold text-sm md:text-sm text-gray-800'>{listing.title}</h3>

                {/* Price */}
                <div className='flex items-center md:my-2'>
                    <span className='text-gray-800 font-semibold mr-2 text-sm'>KShs.</span>
                        {listing.nature === 'rent' ? (
                            listing.regularPrice - listing.discountedPrice > 0 ? (
                                <p className='text-gray-800 font-semibold text-sm'>
                                    {Intl.NumberFormat('en-US').format(listing.regularPrice - listing.discountedPrice)} Per Month
                                </p>
                            ) : (
                                <p className='text-gray-800 font-semibold text-sm'>
                                    {Intl.NumberFormat('en-US').format(listing.regularPrice)}
                                </p>
                            )
                        ) : (
                            listing.discountedPrice > 0 ? (
                                <p className='text-gray-800 font-semibold text-sm'>
                                    {Intl.NumberFormat('en-US').format(listing.regularPrice)}
                                    <span className='text-[#DD0606] ml-1'>
                                        {((listing.regularPrice - listing.discountedPrice) / listing.regularPrice * 100).toFixed(0)}% OFF
                                    </span>
                                </p>
                            ) : (
                                <p className='text-gray-800 font-semibold text-sm'>
                                    {Intl.NumberFormat('en-US').format(listing.regularPrice)}
                                </p>
                            )
                        )}
                </div>

                {/* Description */}
                <p className='text-[16px] text-gray-600 line-clamp-3'>
                    {listing.description}
                </p>

                {/* Property Details */}
                <div className='flex flex-wrap gap-4 text-xs md:text-sm mt-2'>
                    <span className='flex items-center gap-1 text-[#DD0606] font-semibold'>
                       For {listing.nature && listing.nature.charAt(0).toUpperCase() + listing.nature.slice(1)}
                    </span>
                    {listing.squareFootage > 0 && (
                        <span className='flex items-center gap-1 text-[#DD0606] font-semibold'>
                            SqFt. {Intl.NumberFormat('en-us').format(listing.squareFootage)}
                        </span>
                    )}

                    <span className='flex items-center gap-1 text-[#DD0606] font-semibold'>
                        {listing.type && listing.type.charAt(0).toUpperCase() + listing.type.slice(1)}
                    </span>
                    <span className='flex items-center gap-1 text-[#DD0606] font-semibold'>
                        <LuMapPin className='text-[#DD0606]' />
                        {listing.address}
                    </span>

                    {listing.bedrooms > 0 && (
                        <span className='flex items-center gap-1 text-[#DD0606] font-semibold'>
                            {listing.bedrooms}
                            <FaBed className='text-[#DD0606]' />
                        </span>
                    )}

                    {listing.bathrooms > 0 && (
                        <span className='flex items-center gap-1 text-[#DD0606] font-semibold'>
                            {listing.bathrooms}
                            <FaShower className='text-[#DD0606]' />
                        </span>
                    )}

                </div>
            </div>
        </div>
    );
};

export default PropertyCard;
