import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import SearchBar from '../../search/SearchBar';
import Loading from '../../modals/Loading';
import StatusTabs from '../../modals/StatusTabs'
import { useParams } from 'react-router-dom';

const PropertySection = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(false);
    const { tab } = useParams();

    // const handleSearch = async () => {
    //     setLoading(true);
    //     const db = getFirestore();
    //     const listingsCollection = collection(db, 'propertyListings'); // Firestore collection name
    //     const q = query(listingsCollection, where('location', '==', searchTerm)); // Filter by location (or add more filters as needed)

    //     try {
    //         const querySnapshot = await getDocs(q);
    //         const fetchedListings = querySnapshot.docs.map(doc => doc.data());
    //         setListings(fetchedListings);
    //     } catch (error) {
    //         console.error("Error fetching listings:", error);
    //     }
    //     setLoading(false);
    // };

    useEffect(() => {
        // Fetch listings when the component mounts
        const fetchListings = async () => {
            setLoading(true);
            const db = getFirestore();
            const listingsCollection = collection(db, 'listings'); // Firestore collection name

            try {
                const querySnapshot = await getDocs(listingsCollection);
                const fetchedListings = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setListings(fetchedListings);
            } catch (error) {
                console.error("Error fetching listings:", error);
            }
            setLoading(false);
        };
        fetchListings();
    }, []);

    return (
            <div className='min-h-screen w-full p-6 flex flex-col items-center sm:flex-raw'>
                {/* Search Bar */}
                {/* <div className='md:mt-16 w-full flex items-center justify-center'>
                    <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} handleSearch={handleSearch} />
                </div> */}

                <StatusTabs initialTab={tab} />

                {/* Loading Indicator */}
                {/* {listings && loading && <Loading />} */}
                
            </div>
    );
};

export default PropertySection;
