import React, { useEffect, useState, useContext } from 'react';
import { db } from '../../../firebase/firebase';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { AuthContext } from '../../AppContext/AppContext';

const StaffRequests = () => {
    const [inactivePosts, setInactivePosts] = useState([]);
    const [contentMap, setContentMap] = useState({});
    const [previewPost, setPreviewPost] = useState(null);
    const { user } = useContext(AuthContext);

    const fetchInactivePosts = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'blogposts'));
            const inactivePostsData = querySnapshot.docs
                .map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                .filter((post) => post.active === false)
                .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

            setInactivePosts(inactivePostsData);

            inactivePostsData.forEach(async (post) => {
                if (post.contentUrl) {
                    try {
                        const response = await fetch(post.contentUrl);
                        if (!response.ok) throw new Error(`Error fetching content: ${response.statusText}`);
                        const data = await response.text();

                        setContentMap((prevMap) => ({
                            ...prevMap,
                            [post.id]: data,
                        }));
                    } catch (error) {
                        console.error(`Error fetching content for post ${post.id}:`, error);
                    }
                }
            });
        } catch (error) {
            console.error("Error fetching inactive blog posts: ", error);
        }
    };

    useEffect(() => {
        fetchInactivePosts();
    }, []);

    const handleApprovePost = async (postId) => {
        try {
            const postRef = doc(db, 'blogposts', postId);
            await updateDoc(postRef, { active: true });
            setInactivePosts((prevPosts) =>
                prevPosts.filter((post) => post.id !== postId)
            );
        } catch (error) {
            console.error("Error approving blog post: ", error);
        }
    };

    const togglePreview = (post) => {
        setPreviewPost(post);
    };

    const truncateContent = (content, limit = 150) => {
        const plainTextContent = content.replace(/<[^>]+>/g, '');
        return plainTextContent.length > limit
            ? plainTextContent.substring(0, limit) + '...'
            : plainTextContent;
    };

    return (
        <div className="flex flex-col p-6 bg-white rounded h-[99%]">
            {inactivePosts.length > 0 ? (
                <div className="flex flex-col space-y-2 mb-4">
                    <h2>Blog Posts for Review</h2>
                    <p className="text-[13px] text-gray-400">Here are the list of service requests that need your attention and approval</p>
                </div>
            ) : (
                <div className="flex flex-col overflow-y-hidden h-[99%] items-center justify-center">
                    <h1 className="text-center text-gray-600 text-lg mb-2">
                        No service requests at the moment.
                    </h1>
                    <p className="text-center text-gray-400">All Service requests from Staff memmbers will appear, waiting for <br />
                        your approval before they are rendered or executed
                    </p>
                </div>
            )}

            <div className="grid gap-4">
                {inactivePosts.map((post) => (
                    <div key={post.id} className="flex justify-between py-4 px-6 border rounded-lg">
                        <div className='flex flex-col'>
                            <h3 className="font-semibold text-[15px]">{post.title}</h3>
                            <span className='flex space-x-2 items-center'>
                                <p className="text-gray-600 text-[12px]">
                                    {new Date(post.createdAt.seconds * 1000).toDateString()}
                                </p>
                                <span className='text-gray-200'>|</span>
                                <p className="text-gray-600 text-[12px]">by {post.author}</p>
                            </span>
                            <div>
                                <p className="text-[14px]">{truncateContent(contentMap[post.id] || post.content)}</p>
                            </div>
                        </div>

                        {user?.role === 'super admin' && (
                            <div className="flex flex-col gap-2 mt-2">
                                <button
                                    onClick={() => togglePreview(post)}
                                    className="border text-orange-500 text-[13px] border-orange-300 px-4 py-1 rounded-full"
                                >
                                    Preview
                                </button>
                                <button
                                    onClick={() => handleApprovePost(post.id)}
                                    className="border text-orange-500 text-[13px] border-orange-300 px-4 py-1 rounded-full"
                                >
                                    Approve
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {previewPost && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 p-4 h-screen overflow-y-auto">
                    <div className="bg-white p-6 rounded shadow-md w-full max-w-5xl mx-auto overflow-y-auto h-auto max-h-[80vh]">
                        {previewPost.imageURL && (
                            <div>
                                <img src={previewPost.imageURL} alt={`Blog post ${previewPost.title}`} className='rounded-t-md' />
                            </div>
                        )}
                        {previewPost.tags && Array.isArray(previewPost.tags) && (
                            <div className='my-2'>
                                {previewPost.tags.map((tag, index) => (
                                    <span key={index} className='text-xs bg-gray-200 px-3 py-1 rounded-full mr-2'>{tag}</span>
                                ))}
                            </div>
                        )}
                        <h3 className="text-xl font-bold">{previewPost.title}</h3>
                        <span className='flex space-x-2 items-center'>
                            <p className="text-gray-600 text-[12px]">
                                {new Date(previewPost.createdAt.seconds * 1000).toDateString()}
                            </p>
                            <span className='text-gray-200'>|</span>
                            <p className="text-gray-600 text-[12px]">by {previewPost.author}</p>
                        </span>
                        <div
                            className="mt-4 text-gray-700"
                            dangerouslySetInnerHTML={{
                                __html: contentMap[previewPost.id] || previewPost.content,
                            }}
                        />
                        <button
                            onClick={() => setPreviewPost(null)}
                            className="mt-4 border border-orange-300 text-gray-600 text-[14px] py-2 px-6 rounded-full"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StaffRequests;
