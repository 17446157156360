import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc, where, query } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { LuEye, LuDelete } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';

const PropertyListing = ({ layoutStyle, activeTab }) => {
    const generateSlug = (title) => {
        return title.toLowerCase().replace(/\s+/g, '-');
    };

    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [listingsPerPage] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchListings = async () => {
            setLoading(true); // Set loading state to true
            try {
                const listingsCollection = collection(db, 'listings');
                let q;

                switch (activeTab) {
                    case 'sale':
                        q = query(listingsCollection, where("nature", "==", 'sale'));
                        break;
                    case 'rent':
                        q = query(listingsCollection, where("nature", "==", 'rent'));
                        break;
                    case 'commercial':
                        q = query(listingsCollection, where("type", "==", 'commercial'));
                        break;
                    case 'land':
                        q = query(listingsCollection, where("type", "==", 'land'));
                        break;
                    case 'managed':
                        q = query(listingsCollection, where("managedProperty", "==", true));
                        break;
                    default:
                        return;
                }

                const listingsSnapshot = await getDocs(q);
                const listingsList = listingsSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                listingsList.sort((a, b) => b.createdAt - a.createdAt);

                setListings(listingsList);
            } catch (error) {
                console.error('Error fetching listings: ', error);
            } finally {
                setLoading(false); // Set loading state to false
            }
        };

        fetchListings();
    }, [activeTab]);

    const handleView = (id, title) => {
        const slug = generateSlug(title);
        navigate(`/listing/${activeTab}/${id}/${slug}`);
    };

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(db, 'listings', id));
            setListings(listings.filter(listing => listing.id !== id));
        } catch (error) {
            console.error('Error deleting listing: ', error);
        }
    };

    const indexOfLastListing = currentPage * listingsPerPage;
    const indexOfFirstListing = indexOfLastListing - listingsPerPage;
    const currentListings = listings.slice(indexOfFirstListing, indexOfLastListing);
    const totalPages = Math.ceil(listings.length / listingsPerPage);

    return (
        <div className={`w-full bg-white rounded-lg mt-4 py-3 ${layoutStyle}`}>
            {loading ? (
                <div className="flex justify-center items-center py-6">
                    <div className="w-8 h-8 border-4 border-orange-500 border-dotted rounded-full animate-spin"></div>
                </div>
            ) : currentListings.length > 0 ? (
                <table className="w-full border-collapse table-auto">
                    <thead>
                        <tr className="bg-orange-100">
                            <th className="border px-4 py-2 text-sm">Image</th>
                            <th className="border px-4 py-2 text-sm">Title</th>
                            <th className="border px-4 py-2 text-sm">Description</th>
                            <th className="border px-4 py-2 text-sm">Price</th>
                            <th className="border px-4 py-2 text-sm">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentListings.map(listing => (
                            <tr key={listing.id} className="hover:bg-gray-100">
                                <td className="border px-4 py-2">
                                    {listing.imageUrls && listing.imageUrls.length > 0 && (
                                        <img
                                            src={listing.imageUrls[0].downloadURL}
                                            alt={listing.title}
                                            className="w-48 object-cover rounded"
                                        />
                                    )}
                                </td>
                                <td className="border px-4 py-2 text-[12px]">{listing.title}</td>
                                <td className="border px-4 py-2 text-[12px]">{listing.description}</td>
                                <td className="border px-4 py-2 text-[12px]">
                                    {listing.nature === 'rent' ? (
                                        listing.regularPrice - listing.discountedPrice > 0 ? (
                                            `Kshs.${Intl.NumberFormat('en-US').format(listing.regularPrice - listing.discountedPrice)} Per Month`
                                        ) : `Kshs.${Intl.NumberFormat('en-US').format(listing.regularPrice)}`
                                    ) : (
                                        listing.discountedPrice > 0 ? (
                                            <>
                                                <p>Kshs.{Intl.NumberFormat('en-US').format(listing.regularPrice)}</p>
                                                <p className="text-[#DD0606]">
                                                    {((listing.regularPrice - listing.discountedPrice) / listing.regularPrice * 100).toFixed(0)}% OFF
                                                </p>
                                            </>
                                        ) : `Kshs.${Intl.NumberFormat('en-US').format(listing.regularPrice)}`
                                    )}
                                </td>
                                <td className="border px-4 py-2 text-center">
                                    <button className="flex items-center gap-1 text-blue-600 text-[12px] hover:text-blue-800" onClick={() => handleView(listing.id, listing.title)}>
                                        <LuEye /> View
                                    </button>
                                    <button className="flex items-center gap-1 text-red-600 text-[12px] hover:text-red-800 mt-2" onClick={() => handleDelete(listing.id)}>
                                        <LuDelete /> Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center py-4">No listings available.</p>
            )}

            {/* Pagination Controls */}
            {listings.length > listingsPerPage && (
                <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => setCurrentPage(index + 1)}
                            className={`mx-1 px-3 py-1 rounded text-sm ${currentPage === index + 1 ? 'bg-orange-500 text-white' : 'bg-gray-200'}`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PropertyListing;
