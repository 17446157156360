export const fetchUserCountry = async () => {
	try {
		const response = await fetch(
			"https://ipapi.co/json/",
		);
		const data = await response.json();
		return data.country_code; // Returns country code, e.g., 'US'
	} catch (error) {
		console.error(
			"Error fetching country info: ",
			error,
		);
		return null;
	}
};
