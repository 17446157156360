import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage } from 'firebase/storage';

/// Your web app's Firebase configuration (same for both apps)
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY, // Same API Key for both
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN, // Same Auth Domain for both
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID, // Same Project ID for both
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET, // Same Storage Bucket for both
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID, // Same Sender ID for both
  appId: process.env.REACT_APP_FIREBASE_APP_ID, // Same App ID for both
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// If you need to create a secondary app under the same project, you can initialize it like this
const secondaryApp = initializeApp(firebaseConfig, "Secondary");

// Get instances for primary app
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Get instances for secondary app
const secondaryAuth = getAuth(secondaryApp);
const secondaryDb = getFirestore(secondaryApp);
const secondaryStorage = getStorage(secondaryApp);

export { auth, db, storage, secondaryAuth, secondaryDb, secondaryStorage, onAuthStateChanged };
