import React, { useState, useContext } from 'react';
import { AuthContext } from '../../AppContext/AppContext';
import { db, auth } from '../../../firebase/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const AdminSignup = () => {
  const { createUserWithEmail } = useContext(AuthContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    try {
      await createUserWithEmail(email, password);

      // Store admin role and additional data in Firestore
      const userDoc = doc(db, 'users', auth.currentUser.uid);
      await setDoc(userDoc, {
        role: 'admin',
        email,
        firstName,
        lastName,
      });

      alert('Admin registered successfully');
    } catch (error) {
      console.error("Admin registration error: ", error);
      alert('Failed to register admin');
    }
  };

  return (
    <div className='flex flex-col h-screen items-center justify-center'>
      <form onSubmit={handleRegister} className=" max-w-md mx-auto bg-white p-8 border rounded-lg space-y-4">
        <h2 className="text-sm font-semibold mb-6 text-center">Register as Admin</h2>

        <div className='flex space-x-4'>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First Name"
            className="w-full text-sm p-2 mb-4 border border-gray-300 rounded"
            required
          />

          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last Name"
            className="w-full text-sm p-2 mb-4 border border-gray-300 rounded"
            required
          />
        </div>

        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="w-full p-2  text-smmb-4 border border-gray-300 rounded"
          required
        />

        <div className="relative mb-4">
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="w-full p-2 text-sm border border-gray-300 rounded"
            required
          />
          <div className="absolute inset-y-0 right-3 flex items-center cursor-pointer" onClick={togglePasswordVisibility}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </div>
        </div>

        <div className="relative mb-4">
          <input
            type={showConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm Password"
            className="w-full text-sm p-2 border border-gray-300 rounded"
            required
          />
          <div className="absolute inset-y-0 right-3 flex items-center cursor-pointer" onClick={toggleConfirmPasswordVisibility}>
            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
          </div>
        </div>

        <button type="submit" className="w-full bg-orange-500 text-white text-sm p-2 rounded hover:bg-blue-600">
          Register as Admin
        </button>
      </form>
    </div>

  );
};

export default AdminSignup;
