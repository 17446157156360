import React from 'react';
import HeroSection from '../../hero-section/HeroSection';
import RecentListing from './RecentListing';
import FrontBlogPosts from "../../modals/FrontBlogPosts";

const Home = () => {
  return (
    <div className='space-y-4'>
      <HeroSection />
      <RecentListing />
      <FrontBlogPosts />
    </div>
  );
};

export default Home;
