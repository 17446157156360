import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../firebase/firebase';
import { BiEditAlt } from "react-icons/bi";

const Property = () => {
    const { id } = useParams();
    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showOverlay, setShowOverlay] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [editedProperty, setEditedProperty] = useState({
        title: '',
        description: '',
        address: '',
        regularPrice: 0,
        discountedPrice: 0,
        bathrooms: 0,
        bedrooms: 0,
        furnished: false,
        parking: false,
        managedProperty:false,
        type: 'residential',
        nature: 'rent',
        offer: false,
        imageUrls: [],
        videoUrl: [],
        availableFrom: new Date().toISOString().split('T')[0],
        petsAllowed: false,
        squareFootage: 0,
        neighborhood: '',
        propertyAge: 0,
        status: 'Active',
        listedDate: new Date().toISOString().split('T')[0],
    });

    useEffect(() => {
        const fetchProperty = async () => {
            try {
                const propertyRef = doc(db, 'listings', id);
                const propertySnap = await getDoc(propertyRef);

                if (propertySnap.exists()) {
                    const data = propertySnap.data();
                    setProperty(data);
                    setEditedProperty({
                        ...data,
                        price: data.discountedPrice || data.regularPrice || 0,
                    });
                } else {
                    setError('Property not found');
                }
            } catch (err) {
                setError('Error fetching property');
                console.error('Error fetching property:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchProperty();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEditedProperty((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleImageChange = (e) => {
        const files = e.target.files;
        if (files) {
            const fileArray = Array.from(files).map((file) => ({
                file,
                uploadProgress: 0,
            }));
            setEditedProperty((prev) => ({
                ...prev,
                imageUrls: fileArray,
            }));
        }
    };
    const handleVideoClick = () => {
        setShowOverlay(false)
    }
    const uploadImages = async (images) => {
        const imageUrls = [];
        for (const img of images) {
            const storageRef = ref(storage, `listings/${id}/${img.file.name}`);
            const uploadTask = await uploadBytes(storageRef, img.file);
            const downloadURL = await getDownloadURL(uploadTask.ref);
            imageUrls.push({ downloadURL });
        }
        return imageUrls;
    };

    const handleSave = async () => {
        const propertyRef = doc(db, 'listings', id);
        try {
            let imageUrls = [];
            if (editedProperty.imageUrls.length > 0) {
                imageUrls = await uploadImages(editedProperty.imageUrls);
            }

            await updateDoc(propertyRef, {
                ...editedProperty,
                discountedPrice: Number(editedProperty.price),
                imageUrls: imageUrls.length > 0 ? [...property.imageUrls, ...imageUrls] : property.imageUrls,
            });
            setIsEditing(false);
            setProperty((prev) => ({
                ...prev,
                ...editedProperty,
                imageUrls: [...prev.imageUrls, ...imageUrls],
            }));
        } catch (error) {
            console.error('Error updating property:', error);
            setError('Error saving changes');
        }
    };

    if (loading) {
        return <p className='flex items-center text-center p-60'>Loading property...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className='flex flex-col w-full bg-white pb-4'>
            <h2 className='text-lg text-center pt-4 pb-4 font-semibold'>Property Listing</h2>
            {property ? (
                <div className='flex flex-col w-full'>
                    <div className='flex flex-col items-center justify-center'>
                        {/* Main Image */}
                        <div className='flex justify-center w-full md:w-[70%]'>
                            {property.imageUrls && property.imageUrls.length > 0 && (
                                <img
                                    src={property.imageUrls[0].downloadURL}
                                    alt={property.title}
                                    className='w-full max-h-96 object-cover rounded-lg mb-4'
                                />
                            )}
                        </div>

                        {/* Thumbnails below the main image */}
                        <div className='flex flex-wrap gap-2 mb-4 w-full md:w-[70%]'>
                            {property.imageUrls && property.imageUrls.length > 1 && (
                                property.imageUrls.slice(1).map((image, index) => (
                                    <img
                                        key={index}
                                        src={image.downloadURL}
                                        alt={`Thumbnail ${index + 1}`}
                                        className='w-36 h-36 object-cover rounded-lg'
                                    />
                                ))
                            )}
                            {/* Render video with overlay if videoUrl exists */}
                            {property.videoUrl && property.videoUrl.length > 0 && (
                                <div className='relative w-full'>
                                    <video
                                         controls
                                         autoPlay
                                         muted
                                         onClick={handleVideoClick}
                                        className='w-full object-cover max-h-96'
                                    >
                                        {/* Accessing downloadURL from the videoUrl array */}
                                        <source src={property.videoUrl[0].downloadURL} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    {/* {Conditionally render the overlay} */}
                                    {showOverlay && (<div className='absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 text-white text-lg font-bold focus:hidden'>
                                         Virtual Tour
                                    </div>)}
                                </div>
                            )}
                         </div>
                    </div>

                    {/* Property Details */}
                    <div className='flex flex-col items-center justify-center flex-1 w-full md:w-[70%] bg-white self-center p-4 rounded-md'>
                        {isEditing ? (
                            <>
                                <input
                                    type='text'
                                    name='title'
                                    value={editedProperty.title}
                                    onChange={handleInputChange}
                                    placeholder='Title'
                                    className='border rounded p-2 mb-2 w-full md:w-[70%]' />
                                <textarea
                                    name='description'
                                    value={editedProperty.description}
                                    onChange={handleInputChange}
                                    placeholder='Description'
                                    className='border rounded p-2 mb-2 w-full md:w-[70%] resize-none h-64' />
                                <div className='flex gap-3 w-full md:w-[70%]'>
                                    <input
                                        type='text'
                                        name='nature'
                                        value={editedProperty.nature}
                                        onChange={handleInputChange}
                                        placeholder='Nature'
                                        className='border rounded p-2 mb-2 w-full md:w-[70%]' />
                                    <input
                                        type='text'
                                        name='type'
                                        value={editedProperty.type}
                                        onChange={handleInputChange}
                                        placeholder='Type'
                                        className='border rounded p-2 mb-2 w-full md:w-[70%]' />
                                </div>
                                <div className='flex gap-3 w-full md:w-[70%]'>
                                    <input
                                        type='number'
                                        name='price'
                                        value={editedProperty.price}
                                        onChange={handleInputChange}
                                        placeholder='Price'
                                        className='border rounded p-2 mb-2 w-full md:w-[70%]' />
                                    <input
                                        type='text'
                                        name='address'
                                        value={editedProperty.address}
                                        onChange={handleInputChange}
                                        placeholder='Address'
                                        className='border rounded p-2 mb-2 w-full md:w-[70%]' />
                                </div>
                                <div className='flex gap-3 w-full md:w-[70%]'>
                                    <input
                                        type='number'
                                        name='bedrooms'
                                        value={editedProperty.bedrooms}
                                        onChange={handleInputChange}
                                        placeholder='Bedrooms'
                                        className='border rounded p-2 mb-2 w-full md:w-[70%]' />
                                    <input
                                        type='number'
                                        name='bathrooms'
                                        value={editedProperty.bathrooms}
                                        onChange={handleInputChange}
                                        placeholder='Bathrooms'
                                        className='border rounded p-2 mb-2 w-full md:w-[70%]' />
                                </div>
                                <div className='flex gap-3 w-full md:w-[70%]'>
                                    <label>
                                        Furnished: <input type='checkbox' name='furnished' checked={editedProperty.furnished} onChange={handleInputChange} />
                                    </label>
                                    <label>
                                        Managed Property:
                                        <input
                                            type='checkbox'
                                            name='managedProperty'
                                            checked={editedProperty.managedProperty}
                                            onChange={handleInputChange}
                                            className='form-checkbox text-orange-500 border-orange-500 focus:ring-orange-500'
                                        />
                                    </label>
                                </div>

                                {/* Image Upload Input */}
                                <input
                                    type='file'
                                    accept='image/*'
                                    multiple
                                    onChange={handleImageChange}
                                    className='border rounded p-2 mb-2 w-full md:w-[70%]'
                                />

                                <div className='flex gap-3 w-full md:w-[70%] mt-4 items-center justify-center'>
                                    <button
                                        onClick={handleSave}
                                        className='bg-orange-500 text-white p-2 rounded w-full'>
                                        Save
                                    </button>
                                    <button
                                        onClick={() => setIsEditing(false)}
                                        className='bg-white text-orange-500 border border-orange-500 p-2 rounded w-full'>
                                        Cancel
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='flex flex-col flex-wrap  gap-4 w-full my-8'>
                                    <div className='flex flex-col w-full gap-2'>
                                        <h1 className='text-sm font-bold'>{property.title}</h1>
                                        <p className='text-sm'>{property.description}</p>
                                    </div>
                                    <div className='flex flex-col sm:flex-row w-full gap-8'>
                                        <div className='flex flex-col gap-2 shadow-md rounded-md w-full p-4'>
                                            <p className='text-sm'><strong>Nature:</strong> {property.nature.charAt(0).toUpperCase() + property.nature.slice(1)}</p>
                                            <p className='text-sm'><strong>Type:</strong> {property.type.charAt(0).toUpperCase() + property.type.slice(1)}</p>
                                            <p className='text-sm'><strong>Address:</strong> {property.address}</p>
                                            <p className='text-sm flex items-center flex-wrap gap-2'><strong>Price:</strong>
                                                <div className='flex items-center md:my-2'>
                                                    <span className='text-gray-800 mr-2 text-sm sm:hidden'>KShs.</span>
                                                        {property.nature === 'rent' ? (
                                                            property.regularPrice - property.discountedPrice > 0 ? (
                                                                <p className='text-gray-800'>
                                                                    {Intl.NumberFormat('en-US').format(property.regularPrice - property.discountedPrice)} Per Month
                                                                </p>
                                                            ) : (
                                                                <p className='text-gray-800'>
                                                                    {Intl.NumberFormat('en-US').format(property.regularPrice)}
                                                                </p>
                                                            )
                                                        ) : (
                                                            property.discountedPrice > 0 ? (
                                                                <p className='text-gray-800 font-semibold text-sm'>
                                                                    {Intl.NumberFormat('en-US').format(property.regularPrice)}
                                                                    <span className='text-[#DD0606] ml-1'>
                                                                        {((property.regularPrice - property.discountedPrice) / property.regularPrice * 100).toFixed(0)}% OFF
                                                                    </span>
                                                                </p>
                                                            ) : (
                                                                <p className='text-gray-800 font-semibold text-sm'>
                                                                    {Intl.NumberFormat('en-US').format(property.regularPrice)}
                                                                </p>
                                                            )
                                                        )}
                                                </div>
                                            </p>
                                        </div>
                                        <div className='flex flex-col gap-2 shadow-md rounded-md w-full p-4'>
                                            <p className='text-sm'><strong>Bedrooms:</strong> {property.bedrooms}</p>
                                            <p className='text-sm'><strong>Bathrooms:</strong> {property.bathrooms}</p>
                                            <p className='text-sm'><strong>Furnished:</strong> {property.furnished ? 'Yes' : 'No'}</p>
                                            <p className='text-sm'><strong>Managed Property:</strong> {property.managedProperty ? 'Yes' : 'No'}</p>
                                        </div>
                                        <div className='flex flex-col gap-2 shadow-md rounded-md w-full p-4'>
                                            <p className='text-sm'><strong>Neighborhood:</strong> {property.neighborhood}</p>
                                            <p className='text-sm'><strong>Offer:</strong> {property.offer ? 'Yes' : 'No'}</p>
                                            <p className='text-sm'><strong>Parking:</strong> {property.parking ? 'Yes' : 'No'}</p>
                                            <p className='text-sm'><strong>Property Age:</strong> {property.propertyAge}</p>
                                            <p className='text-sm'><strong>Square Footage:</strong> {Intl.NumberFormat('en-US').format(property.squareFootage)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-center justify-right gap-3 border rounded-md p-2 hover:cursor-pointer hover:border-orange-300'
                                    onClick={() => setIsEditing(true)}
                                >
                                    <BiEditAlt className='text-orange-500' size={20} />
                                    <span className='text-sm text-orange-500'>Edit Property</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <p>Property not found</p>
            )}
        </div>
    );
};

export default Property;
