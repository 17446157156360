import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams and useNavigate
import Button from '../../button/Button';
import { LuListPlus, LuRefreshCw } from 'react-icons/lu';
import { HiOutlinePlusCircle } from "react-icons/hi";
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import PropertyListing from '../../modals/PropertyListing';
import { CreatingListing } from './CreatingListing';

const Properties = () => {
    const userListRef = useRef(null);
    const { tab, id, title } = useParams();
    const navigate = useNavigate();

    // State for listings and counts
    const [listings, setListings] = useState([]);
    const [saleCount, setSaleCount] = useState(0);
    const [rentCount, setRentCount] = useState(0);
    const [commercialCount, setCommercialCount] = useState(0);
    const [landCount, setLandCount] = useState(0);
    const [propmanagedCount, setPropmanagedCount] = useState(0);
    const [isCreating, setIsCreating] = useState(false);
    const [activeTab, setActiveTab] = useState(tab || 'sale');

    // Fetch listings from Firestore and calculate counts
    useEffect(() => {
        const fetchListings = async () => {
            const db = getFirestore();
            const listingsCollection = collection(db, 'listings');
            const listingsSnapshot = await getDocs(listingsCollection);
            const listingsData = listingsSnapshot.docs.map(doc => doc.data());

            setListings(listingsData);

            // Calculate counts based on the nature and type fields
            const sale = listingsData.filter(listing => listing.nature === 'sale').length;
            const rent = listingsData.filter(listing => listing.nature === 'rent').length;
            const commercial = listingsData.filter(listing => listing.type === 'commercial').length;
            const land = listingsData.filter(listing => listing.type === 'land').length;
            const propmanaged = listingsData.filter(listing => listing.managedProperty).length;

            setSaleCount(sale);
            setRentCount(rent);
            setCommercialCount(commercial);
            setLandCount(land);
            setPropmanagedCount(propmanaged);
        };

        fetchListings();
    }, []);

    const handleCreateNewListing = () => {
        setIsCreating(true);
    };

    const handleCancel = () => {
        setIsCreating(false);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        navigate(`/listing/${tab}`);
    };

    // Filter listings based on active tab
    const filteredListings = listings.filter(listing => {
        if (activeTab === 'sale') return listing.nature === 'sale';
        if (activeTab === 'rent') return listing.nature === 'rent';
        if (activeTab === 'commercial') return listing.type === 'commercial';
        if (activeTab === 'land') return listing.type === 'land';
        if (activeTab === 'managed') return listing.managedProperty;
        return [];
    });

    return (
        <>
            <div className='w-full bg-white rounded-lg'>
                <div className='w-full flex flex-col justify-center'>
                    <div className='flex w-full px-3 items-center justify-between mt-4'>
                        <div className='flex flex-col flex-1'>
                            <h1 className='font-semibold text-sm md:text-md'>Property Listing</h1>
                            <p className="text-[12px] text-gray-400">This sections keeps track of all properties under Appa Estates Company</p>
                        </div>
                        <div className='flex items-center justify-end space-x-4 pr-8'>
                            <div className="flex flex-row items-center w-full">
                                {!isCreating && <Button
                                    onClick={handleCreateNewListing}
                                    text='Create New Listing'
                                    className='hidden md:block flex items-center justify-center gap-2 text-orange-500' 
                                    // icon={LuListPlus} 
                                    />}
                                {!isCreating && <HiOutlinePlusCircle onClick={handleCreateNewListing} className='block md:hidden  text-orange-500' size={24} />}
                            </div>
                            <button onClick={() => userListRef.current?.refreshUsers()} className='text-orange-500'><LuRefreshCw size={20} /></button>
                        </div>
                    </div>
                    <hr className='mt-2 w-full sm:w-[95%]' />

                    {/* Tabs Header */}
                    <div className='flex justify-center w-full gap-4 p-4 mt-4'>
                        <div className='flex w-full justify-start md:justify-center md:w-[60%] overflow-x-auto whitespace-nowrap gap-2 md:p-4'>
                            <button onClick={() => handleTabClick('sale')} className={`text-sm px-2 py-1 rounded-full ${activeTab === 'sale' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}>For Sale ({saleCount})</button>
                            <button onClick={() => handleTabClick('rent')} className={`text-sm px-2 py-1 rounded-full ${activeTab === 'rent' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}>For Rent ({rentCount})</button>
                            <button onClick={() => handleTabClick('commercial')} className={`text-sm px-2 py-1 rounded-full ${activeTab === 'commercial' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}>Commercial ({commercialCount})</button>
                            <button onClick={() => handleTabClick('land')} className={`text-sm px-2 py-1 rounded-full ${activeTab === 'land' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}>Lands ({landCount})</button>
                            <button onClick={() => handleTabClick('managed')} className={`text-sm px-2 py-1 rounded-full ${activeTab === 'managed' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}>M/Properties ({propmanagedCount})</button>
                        </div>
                        <div className='flex justify-center w-full md:w-[40%] gap-4 p-4 hidden md:block'>
                            <input type="text" placeholder='Search a Property' className='border rounded-full text-center text-xs text-orange-500 p-2 w-3/4 outline-orange-500 border-orange-500' />
                        </div>
                    </div>

                    {/* Property Listing Component */}
                    <PropertyListing
                        listings={filteredListings} layoutStyle="p-4"
                        imageStyle="w-full md:w-[80%] h-40"
                        cardStyle="items-center justify-center"
                        contentStyle="p-2"
                        activeTab={activeTab} />
                </div>
            </div>

            {/* Modal for Creating Listing */}
            {isCreating && (
                <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-0 sm:p-4'>
                    <CreatingListing onCancel={handleCancel} />
                </div>
            )}
        </>
    );
};

export default Properties;
