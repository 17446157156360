import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase/firebase';
import { collection, getDocs, query, limit } from 'firebase/firestore';
import { LuMapPin } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

const Blog = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [contentMap, setContentMap] = useState({});
    const [expandedPosts, setExpandedPosts] = useState({});
    const [properties, setProperties] = useState([]);
    const [loadingBlogs, setLoadingBlogs] = useState(true);
    const [loadingProperties, setLoadingProperties] = useState(true);
    const navigate = useNavigate();

    const handleViewProperty = (listingId, slug) => {
        navigate(`/properties/${listingId}/${slug}`);
    };

    const toggleExpandPost = (postId) => {
        setExpandedPosts((prev) => ({
            ...prev,
            [postId]: !prev[postId],
        }));
    };

    useEffect(() => {
        const fetchBlogPosts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'blogposts'));
                const posts = querySnapshot.docs
                    .map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }))
                    .filter((post) => post.active)
                    .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

                setBlogPosts(posts);

                posts.forEach(async (post) => {
                    if (post.contentUrl) {
                        try {
                            const response = await fetch(post.contentUrl);
                            if (!response.ok) throw new Error(`Error fetching content: ${response.statusText}`);
                            const data = await response.text();

                            setContentMap((prevMap) => ({
                                ...prevMap,
                                [post.id]: data,
                            }));
                        } catch (error) {
                            console.error(`Error fetching content for post ${post.id}:`, error);
                        }
                    }
                });
            } catch (error) {
                console.error('Error fetching blog posts: ', error);
            } finally {
                setLoadingBlogs(false);
            }
        };

        fetchBlogPosts();
    }, []);

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const q = query(collection(db, 'listings'), limit(4));
                const querySnapshot = await getDocs(q);
                const properties = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setProperties(properties);
            } catch (error) {
                console.error('Error fetching properties: ', error);
            } finally {
                setLoadingProperties(false);
            }
        };

        fetchProperties();
    }, []);

    const truncateContent = (content, limit = 300) => {
        const plainTextContent = content.replace(/<[^>]+>/g, '');
        return plainTextContent.length > limit
            ? plainTextContent.substring(0, limit) + '...'
            : plainTextContent;
    };

    return (
        <div className="flex flex-col w-full px-6 items-center justify-center mt-12 mb-3">
            <div className="flex flex-col items-center justify-center space-y-2 mb-4">
                <h2 className="text-2xl font-semibold text-center">
                    Inside Real Estate Inspirations: Stories, Trends, & Interviews
                </h2>
                <p className="text-center">
                    Learn more about new trends, investments, features, and technologies in Real Estate
                </p>
            </div>
            <div className="flex flex-col lg:flex-row md:flex-row gap-4 w-full mt-8 justify-center">
                {/* Blog Posts Section */}
                <div className="flex flex-col w-full lg:w-[60%]">
                    {loadingBlogs ? (
                        <div className="flex justify-center items-center h-40">
                            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                        </div>
                    ) : blogPosts.length > 0 ? (
                        <div className="grid space-y-4 w-full">
                            {blogPosts.map((post) => (
                                <div key={post.id} className="bg-white rounded-lg border shadow-sm">
                                    {post.imageURL && (
                                        <img
                                            src={post.imageURL}
                                            alt={`Blog post ${post.title}`}
                                            className="rounded-t-md"
                                        />
                                    )}
                                    <div className="px-4 mt-2">
                                        {post.tags && Array.isArray(post.tags) && (
                                            <div className="flex flex-wrap gap-2">
                                                {post.tags.map((tag, index) => (
                                                    <span
                                                        key={index}
                                                        className="text-xs bg-gray-200 px-3 py-1 rounded-full"
                                                    >
                                                        {tag}
                                                    </span>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <div className="bg-white p-4">
                                        <h3 className="text-xl font-semibold mb-2">{post.title}</h3>
                                        <p className="text-sm text-gray-600 flex items-center space-x-2">
                                            <span>by {post.author}</span>
                                            <span className="text-gray-300 mx-2">|</span>
                                            <span>
                                                {new Date(post.createdAt.seconds * 1000).toDateString()}
                                            </span>
                                        </p>
                                        <div className="text-gray-700 mt-2">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: expandedPosts[post.id]
                                                        ? contentMap[post.id] || post.content
                                                        : truncateContent(
                                                              contentMap[post.id] || post.content,
                                                              300
                                                          ),
                                                }}
                                            />
                                            <button
                                                onClick={() => toggleExpandPost(post.id)}
                                                className="text-blue-500 mt-2"
                                            >
                                                {expandedPosts[post.id] ? 'Show less' : 'Learn more'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No blog posts available</p>
                    )}
                </div>
                {/* Properties Section */}
                <div className="flex flex-col w-full lg:w-[40%]">
                    {loadingProperties ? (
                        <div className="flex justify-center items-center h-40">
                            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                        </div>
                    ) : properties.length > 0 ? (
                        <div className="px-6">
                            <h2 className="text-lg font-semibold mb-4 text-center">
                                Check our current Properties
                            </h2>
                            <div className="grid gap-6 w-full">
                                {properties.map((property) => (
                                    <div
                                        key={property.id}
                                        className="relative border p-4 rounded-lg shadow-lg hover:cursor-pointer"
                                        onClick={() =>
                                            handleViewProperty(property.id, property.slug || 'default-slug')
                                        }
                                    >
                                        <img
                                            src={property.imageUrls[0].downloadURL}
                                            alt={property.title}
                                            className="object-cover w-full h-48 rounded-lg"
                                        />
                                        <div className="absolute top-0 right-0 bg-black bg-opacity-50 text-white text-sm p-2 rounded-bl-lg">
                                            {property.imageUrls.length}{' '}
                                            {property.imageUrls.length > 1 ? 'Images' : 'Image'}
                                        </div>
                                        <h3 className="mt-2 font-semibold">{property.title}</h3>
                                        <p className="text-sm flex items-center gap-2">
                                            Price: Ksh{' '}
                                            {property.nature === 'rent'
                                                ? Intl.NumberFormat('en-US').format(
                                                      property.regularPrice -
                                                          property.discountedPrice || property.regularPrice
                                                  )
                                                : Intl.NumberFormat('en-US').format(property.regularPrice)}
                                        </p>
                                        <p className="text-sm flex items-center font-semibold">
                                            <LuMapPin className="text-[#DD0606]" /> {property.address}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <p>No properties available</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Blog;
