import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { LuMapPin } from 'react-icons/lu';

const PopularProperty = ({ currentPropertyType, currentPropertyId }) => {
    const [popularProperties, setPopularProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleViewProperty = (listingId, slug) => {
        navigate(`/properties/${listingId}/${slug}`);
    };
    

    // /properties/:listingId/:slug

    useEffect(() => {
        const fetchPopularProperties = async () => {
            try {
                const q = query(
                    collection(db, 'listings'),
                    where('type', '==', currentPropertyType), // Fetch properties of the same type as the current property
                    where('__name__', '!=', currentPropertyId), // Exclude the current property
                    limit(3) // Limit the results to 3 properties
                );
                const querySnapshot = await getDocs(q);
    
                // Function to generate slug if missing
                const generateSlug = (title) => title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9\-]/g, '');
    
                // Mapping over the Firestore documents
                const properties = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        slug: data.slug || generateSlug(data.title), // Generate slug if it's missing
                        ...data
                    };
                });
    
                console.log('Fetched properties:', properties); // For debugging purposes
                setPopularProperties(properties);
            } catch (err) {
                setError('Failed to fetch popular properties');
                console.error('Error fetching popular properties:', err);
            } finally {
                setLoading(false);
            }
        };
    
        fetchPopularProperties();
    }, [currentPropertyType, currentPropertyId]);
    
    

    if (loading) {
        return <p>Loading popular properties...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="popular-properties">
            <h2 className="text-lg font-semibold my-4">Related Properties</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 space-y-4">
                {popularProperties.map(property => (
                    <div
                        key={property.id}
                        className="relative border p-4 rounded-lg shadow-lg hover:cursor-pointer"
                        onClick={() => handleViewProperty(property.id, property.slug || 'default-slug')} // Use property.id here
                    >
                        <img
                            src={property.imageUrls[0].downloadURL}
                            alt={property.title}
                            className="object-cover w-full h-48 rounded-lg"
                        />
                        <div className="absolute top-0 right-0 bg-black bg-opacity-50 text-white text-sm p-2 rounded-bl-lg">
                            {property.imageUrls.length} {property.imageUrls.length > 1 ? 'Images' : 'Image'}
                        </div>
                        <h3 className="mt-2 font-semibold">{property.title}</h3>
                        <p className="text-sm flex items-center gap-2">
                            Price: {property.nature === 'rent' ? (
                                property.regularPrice - property.discountedPrice > 0 ? (
                                    <p className='text-gray-800 font-semibold text-sm'>
                                        {Intl.NumberFormat('en-US').format(property.regularPrice - property.discountedPrice)} Per Month
                                    </p>
                                ) : (
                                    <p className='text-gray-800 font-semibold text-sm'>
                                        {Intl.NumberFormat('en-US').format(property.regularPrice)}
                                    </p>
                                )
                                ) : (
                                    property.discountedPrice > 0 ? (
                                        <p className='text-gray-800 font-semibold text-sm'>
                                            {Intl.NumberFormat('en-US').format(property.regularPrice)}
                                            <span className='text-[#DD0606] ml-1'>
                                                {((property.regularPrice - property.discountedPrice) / property.regularPrice * 100).toFixed(0)}% OFF
                                            </span>
                                        </p>
                                    ) : (
                                        <p className='text-gray-800 font-semibold text-sm'>
                                            {Intl.NumberFormat('en-US').format(property.regularPrice)}
                                        </p>
                                    )
                                )}
                        </p>
                        <p className="text-sm flex items-center font-semibold"><LuMapPin className='text-[#DD0606] gap-2'/> {property.address}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PopularProperty;
