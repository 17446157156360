// components/layouts/AuthLayout.js
import React from 'react';

function AuthLayout({ children }) {
  return (
    <div className="auth-layout">
      {/* Add any specific layout styling, header, or footer for auth pages here */}
      {children}
    </div>
  );
}

export default AuthLayout;
