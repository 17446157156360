import React from 'react';

const LoginButton = ({ text, icon: Icon, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={`w-full bg-[#F06C2C] focus:ring-4 focus:outline-none font-medium rounded-lg  text-sm text-white px-5 py-2.5 text-center font-normal text-sm rounded-full shadow hover:bg-[#e05e20] transition duration-300 ${className}`}
    >
        {/* w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 */}
      {text}
    </button>
  );
};

export default LoginButton;
